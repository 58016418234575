import React, { useState } from 'react'
import { connect } from 'react-redux'
import { forgotPasswordAction } from '../../../actions/authActions'
import '../../css/authentication/Signin.css'

const ForgotPassword = ({ ...props }) => {
  const [email, setEmail] = useState()

  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  const handleSubmit = () => {
    props.forgotPasswordAction(email)
  }

  return <>
    <div className="container login">
      <div className="row m-2 m-sm-5 justify-content-center">
        <div className="col-md-6  shadow-lg mb-5 rounded bg-white  p-4 p-sm-5">
          <h3 className="pb-3 text-center blue-colour">Forgot Password</h3>
          <h6 className="pb-3 text-center">Enter your registered email address to receive the reset password link</h6>
          <div className="form-style">
            <form>
              <div className="form-group pb-3">
                <input type="email" placeholder="Email" onChange={(e) => handleChange(e)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" required="required" />
              </div>
              <div className="pb-2">
                <button type="submit" onClick={handleSubmit} className="btn blue-button w-100 font-weight-bold mt-2">Get Reset Password Link</button>
              </div>
            </form>
          </div>
          <div className="shape1"></div>
          <div className="shape2"></div>
          <div className="shape3"></div>
          <div className="shape4"></div>
        </div>
      </div>
    </div>
  </>
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, { forgotPasswordAction })(ForgotPassword)