import React, { useState } from 'react'
import { connect } from 'react-redux'
import '../css/ContactUs/ContactUs.css'
import '../css/common/Banner.css'
import Breadcrums from '../common/Banner'
import { UIRoutes } from '../../utils/FrontendRoutes'
import { addContactInquiry } from '../../actions/contactActions'
import { toast } from 'react-toastify'

const ContactUs = ({ ...props }) => {
  const [data, setData] = useState({
    parentsName: undefined,
    studentName: undefined,
    studentSchool: undefined,
    studentStandard: undefined,
    parentsMobileNo: undefined,
    parentsEmail: undefined,
    address: undefined,
    query: undefined
  })

  const handleChange = (event, calledFor) => {
    const tempData = { ...data }
    switch (calledFor) {
      case 'parentsName':
        tempData.parentsName = event.target.value
        break
      case 'studentName':
        tempData.studentName = event.target.value
        break
      case 'studentSchool':
        tempData.studentSchool = event.target.value
        break
      case 'studentStandard':
        tempData.studentStandard = event.target.value
        break
      case 'parentsMobileNo':
        tempData.parentsMobileNo = event.target.value
        break
      case 'parentsEmail':
        tempData.parentsEmail = event.target.value
        break
      case 'address':
        tempData.address = event.target.value
        break
      case 'query':
        tempData.query = event.target.value
        break
    }
    setData(tempData)
  }

  const handleClick = () => {
    if (Object.values(data).includes(undefined) || Object.values(data).includes(null)) {
      toast.error('Please fill all the details')
    } else {
      props.addContactInquiry(data)
    }
  }

  return <>
    <section className="contact-us">
      <Breadcrums divClassName="contact-banner main-banner"
        breadcrums={JSON.stringify([{ key: "Home ", value: UIRoutes.HOME }, { key: "Contact US", value: UIRoutes.CONTACTUS }])}
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="down-contact">
              <div className="row">
                <div className="col-lg-8" data-aos="fade-down-right">
                  <div className="sidebar-item contact-form">
                    <div className="sidebar-heading">
                      <h2>Send us a message</h2>
                    </div>
                    <div className="content">
                      <p>We usually answer during working hours.</p>
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <fieldset className="b-input_field">
                            <label className="b-input_row-label form-control-label" for="registration-form-email">
                              Parent's Name
                            </label>
                            <input name="name" type="text" className="b-quantity-select" id="parentsname" onChange={(e) => handleChange(e, 'parentsName')}  required="required" />
                          </fieldset>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <fieldset className="b-input_field">
                            <label className="b-input_row-label form-control-label" for="registration-form-email">
                              Student's Name
                            </label>
                            <input name="name" type="text" className="b-quantity-select" id="studentname" onChange={(e) => handleChange(e, 'studentName')}  required="required" />
                          </fieldset>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <fieldset className="b-input_field">
                            <label className="b-input_row-label form-control-label" for="registration-form-email">
                              Student School
                            </label>
                            <input name="name" type="text" className="b-quantity-select" id="studentschool" onChange={(e) => handleChange(e, 'studentSchool')} required="required" />
                          </fieldset>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <fieldset className="b-input_field">
                            <label className="b-input_row-label form-control-label" for="registration-form-email">
                              Student Standard
                            </label>
                            <input name="name" type="text" className="b-quantity-select" id="studentstd" onChange={(e) => handleChange(e, 'studentStandard')}  required="required" />
                          </fieldset>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <fieldset className="b-input_field">
                            <label className="b-input_row-label form-control-label" for="registration-form-email">
                              Parent's Mobile No
                            </label>
                            <input name="name" type="text" className="b-quantity-select" id="phoneno" onChange={(e) => handleChange(e, 'parentsMobileNo')}  required="required" />
                          </fieldset>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <fieldset className="b-input_field">
                            <label className="b-input_row-label form-control-label" for="registration-form-email">
                              Parent's Email Id
                            </label>
                            <input name="email" type="email" className="b-quantity-select" id="email" onChange={(e) => handleChange(e, 'parentsEmail')} required="required" />
                          </fieldset>
                        </div>
                        <div className="col-lg-12">
                          <fieldset className="b-input_field">
                            <label className="b-input_row-label form-control-label" for="registration-form-email">
                              Address
                            </label>
                            <textarea name="message" rows="6" className="b-quantity-select" id="Address" onChange={(e) => handleChange(e, 'address')}  required="required"></textarea>
                          </fieldset>
                        </div>
                        <div className="col-lg-12">
                          <fieldset className="b-input_field">
                            <label className="b-input_row-label form-control-label" for="registration-form-email">
                              Query
                            </label>
                            <textarea name="message" rows="6" className="b-quantity-select" id="Query" onChange={(e) => handleChange(e, 'query')}  required="required"></textarea>
                          </fieldset>
                        </div>
                        <div className="col-lg-12 text-center mx-auto">
                          <fieldset className="b-input_field">
                            {/* <label className="b-input_row-label form-control-label" for="registration-form-email">
                              Parent's Name
                            </label> */}
                            <button type="button" onClick={handleClick} id="form-submit" className="main-button pr-2 pl-2">Submit</button>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="sidebar-item contact-information">
                    <div className="sidebar-heading">
                      <h2>contact information</h2>
                    </div>
                    <div className="content">
                      <ul>

                        <li data-aos="fade-up-left">
                          <h5 className='d-flex align-items-baseline'><i className="fab fa-whatsapp me-3"></i><a href="tel:+91 9499517565" style={{ color: "20232e", }}>+91 9499517565 </a> </h5>
                        </li>
                        <li data-aos="fade-up-left">
                          <h5 className='d-flex align-items-baseline'><i className="fab fa-whatsapp me-3"></i><a href="tel:+91 7016048104" style={{ color: "20232e", }}>+91 7016048104 </a> </h5>
                        </li>
                        <li data-aos="fade-up-right">
                          <h5 className='d-flex align-items-baseline'><i className="fas fa-envelope me-3"></i><a href="mailto:info@careerconnectedu.com" style={{ color: "20232e", }}>info@careerconnectedu.com</a> </h5>
                        </li>
                        <li data-aos="fade-up-right">
                          <h5 className='d-flex align-items-baseline'><i className="fas fa-home me-3"></i><a href="https://g.page/career-connect-valsad?share" style={{ color: "20232e", }}>  Career Connect <br /> 229-232, Citipoint, Opp. S.T. Workshop, Dharampur Road, Abrama, Valsad-396001, Gujarat, India. </a></h5>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid" data-aos="flip-right">
            <div className="col-lg-12">
              <div id="map">
                <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2595.373681720509!2d72.9394871!3d20.5933799!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xae42271586f413bd!2scareer+connect!5e1!3m2!1sen!2sin!4v1550049311437" width="100%" height="450px" frameBorder="0" style={{ border: "0", }} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, { addContactInquiry })(ContactUs)