import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import validator from "validator"
import { TextEditor, getInnerHtml } from "text-editor-react";
import { getCategory } from "../../actions/categoryActions"
import { addProduct } from "../../actions/productActions"
import CustomButton from "../common/CustomButton"
import TextInput from "../common/TextInput"
import '../css/pages/AddProduct.css'

const AddProducts = ({ ...props }) => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [category, setCategory] = useState('')
  const [link, setLink] = useState('')
  const [price, setPrice] = useState(0)
  const [image, setImage] = useState('')
  const id = 'description'

  useEffect(() => {
    props.getCategory()
  }, [])

  useEffect(() => {
    if (props.categoryList.length) {
      setCategory({ value: String(props.categoryList[0]._id), error: '' })
    }
  }, [props.categoryList.length])

  const handleChange = (event, calledFor) => {
    let tempValue = { value: event.target.value, error: '' }
    if (!tempValue.value) {
      tempValue = { value: event.target.value, error: calledFor + ' is required' }
    }
    switch (calledFor) {
      case 'Name':
        if (!tempValue.error && validator.isEmpty(tempValue.value)) {
          tempValue.error = 'Product name is not valid'
        }
        setName(tempValue)
        break
      case 'Description':
        if (!tempValue.error && validator.isEmpty(tempValue.value)) {
          tempValue.error = 'Product description is not valid'
        }
        setDescription(tempValue)
        break
      case 'Category':
        if (!tempValue.error && !validator.isMongoId(tempValue.value)) {
          tempValue.error = 'Product category is not valid'
        }
        setCategory(tempValue)
        break
      case 'Link':
        // if (!tempValue.error) {
        //   tempValue.error = 'Youtube video link is not valid'
        // }
        setLink(tempValue)
        break
      case 'Price':
        if (!tempValue.error && !validator.isNumeric(tempValue.value)) {
          tempValue.error = 'Product price is not valid'
        }
        setPrice(tempValue)
        break
      case 'Image':
        let reader = new FileReader()
        let file = document.querySelector('input[type=file]').files[0]
        reader.readAsDataURL(file)
        setTimeout(
          () => {
            setImage(reader)
          }, 1500)
        break
    }
  }

  const handleSubmit = () => {
    const requestBody = {
      name: name.value,
      description: getInnerHtml(id),
      link: link.value,
      category: category.value,
      price: price.value,
      image: image.result
    }
    props.addProduct(requestBody)
  }

  return <>
    <div className="container">
      <div className="row justify-content-center text-center">
        <div className="col-lg-8 shadow-lg p-3 mb-5 bg-body rounded">
          <h3 className="text-dark main-title-border">Add Product</h3>
          <div className="">
            <TextInput
              divClassName="form-group pb-3"
              type="text"
              placeholder="Product Name"
              onChange={(e) => handleChange(e, 'Name')}
              inputClassName="form-control"
              id="name"
              required="required"
              value={name.value}
              error={name.error}
            />
          </div>
          <div className="">
            <TextEditor id={id} />
            {/* <TextInput
              divClassName="form-group pb-3"
              type="text"
              placeholder="Product Description"
              onChange={(e) => handleChange(e, 'Description')}
              inputClassName="form-control"
              id="description"
              required="required"
              value={description.value}
              error={description.error}
            /> */}
          </div>
          <div className=" pb-3">
            <select className="text-left d-block addproduct-category" placeholder="Select Category" onChange={(e) => handleChange(e, 'Category')}>
              {
                props.categoryList.map(eachCategory => {
                  return <option value={String(eachCategory._id)}>{eachCategory.name}</option>
                })
              }
            </select>
          </div>
          <div className="">
            <TextInput
              divClassName="form-group pb-3"
              type="text"
              placeholder="Enter Embedded Youtube Video Link"
              onChange={(e) => handleChange(e, 'Link')}
              inputClassName="form-control"
              id="link"
              required="required"
              value={link.value}
              error={link.error}
            />
          </div>
          <div className="">
            <TextInput
              divClassName="form-group pb-3"
              type="number"
              placeholder="Product Price"
              onChange={(e) => handleChange(e, 'Price')}
              inputClassName="form-control"
              id="price"
              required="required"
              value={price.value}
              error={price.error}
            />
          </div>
          <div className="">
            <input
              divClassName="form-group pb-3"
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              placeholder="Product Image"
              onChange={(e) => handleChange(e, 'Image')}
              inputClassName="form-control"
              id="image"
              required="required"
            />
          </div>
          <div className=" d-flex justify-content-center mt-4">
            <CustomButton
              divClassName='pb-2 w-25'
              className='btn blue-button w-100 font-weight-bold text-center mx-auto mt-2'
              onClick={handleSubmit}
              text='Save'
              disabled={!name.value || name.error || !category.value || category.error || !link.value || link.error || !price.value || price.error}
            />
          </div>
        </div>
      </div>
    </div>
  </>
}

const mapStateToProps = state => ({
  categoryList: state.category.list
})

export default connect(mapStateToProps, { getCategory, addProduct })(AddProducts)