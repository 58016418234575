import axios from "axios"
import { toast } from "react-toastify"
import { APIRoutes } from "../utils/BackendRoutes"
import { getErrorMessage } from "../utils/GetErrorMessage"
import setLoading from "../utils/LoadingManager"
import { GET_USER_REPORT } from "./types"

export const getUserReports = () => (dispatch) => {
  setLoading(true)
  axios.get(APIRoutes.GET_USER_REPORT)
    .then(res => {
      dispatch({
        type: GET_USER_REPORT,
        payload: res.data.data
      })
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}
