import axios from "axios"
import { toast } from "react-toastify"
import { APIRoutes } from "../utils/BackendRoutes"
import { getErrorMessage } from "../utils/GetErrorMessage"
import setLoading from "../utils/LoadingManager"
import { ADD_CATEGORY, DELETE_CATEGORY, GET_CATEGORY, UPDATE_CATEGORY } from "./types"

export const addCategory = (body) => dispatch => {
  setLoading(true)
  axios.post(APIRoutes.ADD_CATEGORY, body)
    .then(res => {
      dispatch({
        type: ADD_CATEGORY,
        payload: res.data.data
      })
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const updateCategory = (body, id) => dispatch => {
  setLoading(true)
  axios.put(APIRoutes.PUT_CATEGORY + id, body)
    .then(res => {
      dispatch({
        type: UPDATE_CATEGORY,
        payload: { id: id, name: body.name }
      })
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const deleteCategory = (id) => dispatch => {
  setLoading(true)
  axios.delete(APIRoutes.DELETE_CATEGORY + id)
    .then(res => {
      dispatch({
        type: DELETE_CATEGORY,
        payload: id
      })
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const getCategory = (body) => dispatch => {
  setLoading(true)
  axios.get(APIRoutes.GET_CATEGORY, body)
    .then(res => {
      dispatch({
        type: GET_CATEGORY,
        payload: res.data.data
      })
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}