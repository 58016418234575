import React, { useState } from 'react'
import { connect } from 'react-redux'
import '../css/ContactUs/ContactUs.css'
import '../css/common/Banner.css'
import Breadcrums from '../common/Banner'
import { UIRoutes } from '../../utils/FrontendRoutes'
import { toast } from 'react-toastify'
import { addFranchiseInquiry } from '../../actions/contactActions'

const FranchiseFrom = ({ ...props }) => {
  const [data, setData] = useState({
    firstName: undefined,
    lastName: undefined,
    companyName: undefined,
    officeAddress: undefined,
    comment: undefined,
    workCell: undefined,
    personalNumber: undefined,
    email: undefined,
    website: undefined,
    higherEducation: undefined,
    officeSpace: undefined,
    currentBusiness: undefined,
    otherBusiness: undefined,
    staffNumber: undefined,
    facilityDetails: undefined,
    availableCapital: undefined,
    experience: undefined,
    examples: undefined
  })

  const handleChange = (event, calledFor) => {
    const tempData = { ...data }
    switch (calledFor) {
      case 'firstName':
        tempData.firstName = event.target.value
        break
      case 'lastName':
        tempData.lastName = event.target.value
        break
      case 'companyName':
        tempData.companyName = event.target.value
        break
      case 'officeAddress':
        tempData.officeAddress = event.target.value
        break
      case 'comment':
        tempData.comment = event.target.value
        break
      case 'workCell':
        tempData.workCell = event.target.value
        break
      case 'personalNumber':
        tempData.personalNumber = event.target.value
        break
      case 'email':
        tempData.email = event.target.value
        break
      case 'website':
        tempData.website = event.target.value
        break
      case 'higherEducation':
        tempData.higherEducation = event.target.value
        break
      case 'officeSpace':
        tempData.officeSpace = event.target.value
        break
      case 'currentBusiness':
        tempData.currentBusiness = event.target.value
        break
      case 'otherBusiness':
        tempData.otherBusiness = event.target.value
        break
      case 'staffNumber':
        tempData.staffNumber = event.target.value
        break
      case 'facilityDetails':
        tempData.facilityDetails = event.target.value
        break
      case 'availableCapital':
        tempData.availableCapital = event.target.value
        break
      case 'experience':
        tempData.experience = event.target.value
        break
      case 'examples':
        tempData.examples = event.target.value
        break
    }
    setData(tempData)
  }

  const handleClick = () => {
    console.log(data)
    if (Object.values(data).includes(undefined) || Object.values(data).includes(null)) {
      toast.error('Please fill all the details')
    } else {
      props.addFranchiseInquiry(data)
    }
  }

  return <>
    <section className="contact-us">
      <Breadcrums divClassName="contact-banner main-banner"
        breadcrums={JSON.stringify([{ key: "Home ", value: UIRoutes.HOME }, { key: "Franchise From", value: UIRoutes.FRANCHISEFROM }])}
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="down-contact">
              <div className="row justify-content-center">
                <div className="col-lg-10" data-aos="fade-down-right">
                  <div className="sidebar-item contact-form">
                    <div className="sidebar-heading">
                      <h2>Send us a message</h2>
                    </div>
                    <div className="content">
                      <p>We usually answer during 24 hours in working days.</p>
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <fieldset className="b-input_field">
                            <label className="b-input_row-label form-control-label" for="registration-form-email">
                              First Name
                            </label>
                            <input name="name" type="text" id="FirstName" onChange={(e) => handleChange(e, 'firstName')} required="required" />
                          </fieldset>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <fieldset className="b-input_field">
                            <label className="b-input_row-label form-control-label" for="registration-form-email">
                              Last Name                            </label>
                            <input name="name" type="text" id="LastName" onChange={(e) => handleChange(e, 'lastName')} required="required" />
                          </fieldset>
                        </div>
                        <div className="col-md-212 col-sm-12">
                          <fieldset className="b-input_field">
                            <label className="b-input_row-label form-control-label" for="registration-form-email">
                              Company Name
                            </label>
                            <input name="name" type="text" id="CompanyName" onChange={(e) => handleChange(e, 'companyName')} required="required" />
                          </fieldset>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <fieldset className="b-input_field">
                            <label className="b-input_row-label form-control-label" for="registration-form-email">
                              Office / Home Address
                            </label>
                            <textarea name="name" type="text" rows="6" id="OfficeHomeAddress" onChange={(e) => handleChange(e, 'officeAddress')} required="required" ></textarea>
                          </fieldset>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <fieldset className="b-input_field">
                            <label className="b-input_row-label form-control-label" for="registration-form-email">
                              Comment or Message
                            </label>
                            <textarea name="name" type="text" rows="6" id="CommentorMessage" onChange={(e) => handleChange(e, 'comment')} required="required" ></textarea>
                          </fieldset>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <fieldset className="b-input_field">
                            <label className="b-input_row-label form-control-label" for="registration-form-email">
                              Work Cell
                            </label>
                            <input name="number" type="number" id="WorkCell" onChange={(e) => handleChange(e, 'workCell')} required="required" />
                          </fieldset>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <fieldset className="b-input_field">
                            <label className="b-input_row-label form-control-label" for="registration-form-email">
                              Personal Number
                            </label>
                            <input name="text" type="number" id="PersonalNumber" onChange={(e) => handleChange(e, 'personalNumber')} required="required" />
                          </fieldset>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <fieldset className="b-input_field">
                            <label className="b-input_row-label form-control-label" for="registration-form-email">
                              Email
                            </label>
                            <input name="email" type="email " id="email" onChange={(e) => handleChange(e, 'email')} required="required" />
                          </fieldset>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <fieldset className="b-input_field">
                            <label className="b-input_row-label form-control-label" for="registration-form-email">
                              Website
                            </label>
                            <input name="email" type="text" id="Website" onChange={(e) => handleChange(e, 'website')} required="required" />
                          </fieldset>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <fieldset className="b-input_field">
                            <label className="b-input_row-label form-control-label" for="registration-form-email">
                              Higher Education and Qualifications
                            </label>
                            <input name="text" type="text" id="HEAQ" onChange={(e) => handleChange(e, 'higherEducation')} required="required" />
                          </fieldset>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <fieldset className="b-input_field">
                            <label className="b-input_row-label form-control-label" for="registration-form-email">
                              Office Space in Sq/ft
                            </label>
                            <input name="email" type="text" id="Website" onChange={(e) => handleChange(e, 'officeSpace')} required="required" />
                          </fieldset>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <fieldset className="b-input_field">
                            <label className="b-input_row-label form-control-label" for="registration-form-email">
                              Describe your current business set up and activities
                            </label>
                            <textarea name="text" type="text" rows="6" id="CommentorMessage" onChange={(e) => handleChange(e, 'currentBusiness')} required="required" ></textarea>
                          </fieldset>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <fieldset className="b-input_field">
                            <label className="b-input_row-label form-control-label" for="registration-form-email">
                              Do you have any other business interests? Please specify
                            </label>
                            <textarea name="text" type="text" rows="6" id="otherBusiness" onChange={(e) => handleChange(e, 'otherBusiness')} required="required" ></textarea>
                          </fieldset>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <fieldset className="b-input_field">
                            <label className="b-input_row-label form-control-label" for="registration-form-email">
                              No of staff
                            </label>
                            <input name="text" type="text" id="HEAQ" onChange={(e) => handleChange(e, 'staffNumber')} required="required" />
                          </fieldset>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <fieldset className="b-input_field">
                            <label className="b-input_row-label form-control-label" for="registration-form-email">
                              Kindly mention the other infrastructure facility details                            </label>
                            <input name="otherFacility" type="text" id="otherFacility" onChange={(e) => handleChange(e, 'facilityDetails')} required="required" />
                          </fieldset>
                        </div>
                        <div className="col-md-12 col-sm-12">
                          <fieldset className="b-input_field">
                            <label className="b-input_row-label form-control-label" for="registration-form-email">
                              Experience in year
                            </label>
                            <input name="text" type="number" id="exp" onChange={(e) => handleChange(e, 'experience')} required="required" />
                          </fieldset>
                        </div>
                        <div className="col-md-12 col-sm-12">
                          <fieldset className="b-input_field">
                            <label className="b-input_row-label form-control-label" for="registration-form-email">
                              How much capital do you have available to invest in this business:                            </label>
                            <input name="availableCapital" type="text" id="availableCapital" onChange={(e) => handleChange(e, 'availableCapital')} required="required" />
                          </fieldset>
                        </div>
                        <div className="col-md-12 col-sm-12">
                          <fieldset className="b-input_field">
                            <label className="b-input_row-label form-control-label" for="registration-form-email">
                              Please provide some examples of changes you have implemented that have significantly improved the profit of your business?
                            </label>
                            <input name="text" type="number" id="HEAQ" onChange={(e) => handleChange(e, 'examples')} required="required" />
                          </fieldset>
                        </div>

                        <div className="col-lg-12 text-center mx-auto">
                          <fieldset>
                            <button type="button" onClick={handleClick} id="form-submit" className="main-button pr-2 pl-2">Submit</button>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, { addFranchiseInquiry })(FranchiseFrom)