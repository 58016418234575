import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Modal, Container } from 'react-bootstrap'
import axios from 'axios'
import '../css/ContactUs/ContactUs.css'
import '../css/common/Banner.css'
import { APIRoutes } from '../../utils/BackendRoutes'
import { getProduct } from '../../actions/productActions'
import CustomButton from '../common/CustomButton'
import TextInput from '../common/TextInput'
import ImageAssets from '../../utils/ImageAssets'
import { toast } from 'react-toastify'

const ViewProduct = ({ ...props }) => {
  const productId = props.match.params.id
  const [show, setShow] = useState(false);
  const [coupon, setCoupon] = useState('');

  useEffect(() => {
    props.getProduct(productId)
  }, [])

  const initPayment = (data) => {
    const options = {
      key: 'rzp_live_G0GpXVMtxIaJ0L',
      amount: Number(data.amount),
      currency: data.currency,
      name: props.activeProduct.name,
      description: props.activeProduct.description,
      order_id: data.id,
      handler: async (response) => {
        try {
          const verifyPaymentUrl = APIRoutes.VERIFY_PAYMENT
          const { data } = await axios.post(verifyPaymentUrl, response)
          if (data.success) {
            toast.success('Thank you for Purchasing')
          }
        } catch (error) {
          console.log(error)
        }
      },
      theme: {
        color: '#3399CC'
      }
    }
    const rzp1 = new window.Razorpay(options)
    rzp1.open()
  }

  const handlePurchase = async () => {
    try {
      handleClose()
      const createPaymentUrl = APIRoutes.INITIATE_PAYMENT
      const description = 'For ' + props.activeProduct.name + ' product by ' + JSON.parse(localStorage.getItem('user')).firstName + ' ' + JSON.parse(localStorage.getItem('user')).lastName
      const { data } = await axios.post(createPaymentUrl, { name: props.activeProduct.name, description: description, userId: JSON.parse(localStorage.getItem('user'))._id, amount: props.activeProduct.price, coupon: coupon, productId: props.activeProduct._id })
      initPayment(data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleEdit = () => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  };

  const handleChange = (e) => {
    setCoupon(e.target.value)
  }

  return (
    <>
      <div className="container text-start">
        <div className="row d-flex">
          <div className="col-md-10 shadow-lg p-3 mb-5 bg-body rounded text-left">
            <div><img style={{ height: '345px', width: '420px' }} src={props.activeProduct.image || ImageAssets.common.logo} /></div>
            <div dangerouslySetInnerHTML={{ __html: props.activeProduct.link }} />
            <div><span className='fw-bold text-height-1'>Name:</span> {props.activeProduct.name}</div>
            <div><span className='fw-bold text-height-1'>Description: </span><span dangerouslySetInnerHTML={{ __html: props.activeProduct.description }}></span></div>
            <div><span className='fw-bold text-height-1'>Category:</span> {props.activeProduct.category?.name}</div>
            <div><span className='fw-bold text-height-1'>Price: ₹ </span>{props.activeProduct.price}</div>
            <CustomButton
              divClassName='pb-2'
              className='btn blue-button w-30 font-weight-bold mt-2'
              onClick={handleEdit}
              text='Buy Now'
            />
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Apply Coupon</Modal.Title>
          {/* <Modal.Title><h3>For Payment Please Contact : </h3><b> <p>
            <i className="fas fa-envelope me-3"></i><a href="mailto:info@careerconnectedu.com">info@careerconnectedu.com</a></p>
            <p><i className="fas fa-phone me-3"></i><a href="tel:+91 7016048104">+91 7016048104 </a></p></b>  </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Container>
            <TextInput
              type='text'
              onChange={(e) => handleChange(e)}
              divClassName="form-group pb-3"
              placeholder="Coupon"
              inputClassName="form-control"
              id="coupon"
              value={coupon.value}
              error={coupon.error}
            />
            <div className='text-left'>
              <br />
              Note:
              <br />
              Please make sure you have entered correct coupon code.
              <br />
              On next screen you will see deducted amount if coupon code applied successfully.
              <br />
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            divClassName='pb-2 w-25'
            className='btn blue-button w-100 font-weight-bold mt-2'
            onClick={handlePurchase}
            text='Proceed to Payment'
          />
          <CustomButton
            divClassName='pb-2 w-25'
            className='btn blue-button w-100 font-weight-bold mt-2'
            onClick={handleClose}
            text='Cancel'
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => ({
  activeProduct: state.products.activeProduct
})

export default connect(mapStateToProps, { getProduct })(ViewProduct)