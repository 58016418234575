import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { UIRoutes } from '../../utils/FrontendRoutes'
import ImageAssets from '../../utils/ImageAssets'
import '../css/common/Footer.css'
// import AOS from "aos";
// import "aos/dist/aos.css";

const Footer = () => {
//   useEffect(() => {
//     AOS.init({
//         duration: 1200
//     });
//     AOS.refresh();
// }, []);
  return <>
    <footer className="text-sm-center text-left text-lg-start text-light pt-3" >
      <section className="footer-top">
        <div className="container text-sm-center text-left text-md-start mt-5 inner">
          <div className="row mt-3">
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
            {/* <img className="logo horizontal-logo" src={ImageAssets.common.logo} alt="forecastr logo" /> */}
              <h6 className="text-uppercase fw-bold mb-4 mt-5 text-light">
                About
                {/* <i className="fas fa-gem me-3"></i>           */}
                {/* <img className="logo horizontal-logo" src={ImageAssets.common.logo} alt="forecastr logo" /> */}

              </h6>
              <p>
                “Career Connect” by its name is self-explanatory, i.e. establishing a connection between an individual’s likes, aptitude and talent to his/her appropriate career.
              </p>
              <div className="social-link">
                <a href="https://www.facebook.com/pages/category/Education/Career-Connect-1228862200548321/" target="_blank" rel="noopener noreferrer" className="me-4 text-reset">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="https://twitter.com/CAREERCONNECT9" target="_blank" rel="noopener noreferrer" className="me-4 text-reset">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="http://www.careerconnectedu.com/" target="_blank" rel="noopener noreferrer" className="me-4 text-reset">
                  <i className="fab fa-google"></i>
                </a>
                <a href="https://www.instagram.com/careerconnect29/" target="_blank" rel="noopener noreferrer" className="me-4 text-reset">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="https://www.linkedin.com/in/career-connect-496566179/" target="_blank" rel="noopener noreferrer" className="me-4 text-reset">
                  <i className="fab fa-linkedin"></i>
                </a>
                <a href="https://www.youtube.com/channel/UCfvZOnlJh_vg2amKJay8-eg?guided_help_flow=3" target="_blank" rel="noopener noreferrer" className="me-4 text-reset">
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
            </div>

            <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4 text-light">
                Products &amp; Services
              </h6>
              <p>
                <Link className="text-reset" to={UIRoutes.AICRI} >AICRA</Link>
              </p>
              <p>
                <Link className="text-reset" to={UIRoutes.CAREERFOUNDER}>Career Founder</Link>
              </p>
              <p>
                <Link className="text-reset" to={UIRoutes.DHEYACAREERMENTORS}>Dheya Career Mentors</Link>
              </p>
              {/* <p>
                <a to="#!" className="text-reset">Protecon Product</a>
              </p> */}
              <p>
                <Link className="text-reset" to={UIRoutes.SMARTSCHOOL}>Smart School</Link>
              </p>
              {/* <p>
                <a to="#!" className="text-reset">TIMTS</a>
              </p> */}
              <p> <Link className="text-reset" to={UIRoutes.STEMEDUCATION} >STEM Education</Link></p>
              <p> <Link className="text-reset" to={UIRoutes.GETSETGO} >Get Set Go</Link></p>
              <p><Link className="text-reset" to={UIRoutes.WINWHITELABEL} >Win-Win Whitelabel App Solution.</Link></p>
            </div>

            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4 text-light">
                Contact
              </h6>
              <p><i className="fas fa-home me-3"></i><a href="https://g.page/career-connect-valsad?share"> 229-232, Citipoint, Opp. S.T. Workshop, Dharampur Road, Abrama, Valsad-396001, Gujarat, India.</a></p>
              <p>
                <i className="fas fa-envelope me-3"></i><a href="mailto:info@careerconnectedu.com">info@careerconnectedu.com</a></p>
              
              <p><i className="fab fa-whatsapp me-3"></i><a href="tel:+91 9499517565">+91 9499517565 </a></p>
              <p><i className="fab fa-whatsapp me-3"></i><a href="tel:+91 7016048104">+91 7016048104 </a></p>
            </div>
          </div>
        </div>
      </section>

      <section className="d-block d-md-flex justify-content-center justify-content-lg-between p-4 border-bottom footer-bottom" >
        <div className="text-center copyright  me-5 d-lg-block col-md-4">
          © {new Date().getFullYear()} CAREER CONNECT . ALL RIGHTS RESERVED.
        </div>
        <h6 className="text-uppercase footer-logo fw-bold col-md-4">
          <img className="logo   horizontal-logo" src={ImageAssets.common.logo} alt="forecastr logo" />
        </h6>

        <div className="text-center policy-group col-md-4" >
          <Link className="terms-policy text-reset mr-5" to={UIRoutes.CANCELLATIONREFUNDPOLICY}>Cancellation and Refund Policy</Link>
          <Link className="terms-policy text-reset ml-3" to={UIRoutes.POLICY}>Privacy Policy</Link>
          <Link className="terms-policy text-reset ml-3" to={UIRoutes.TERMS}>Terms &amp; Conditions</Link>
        </div>

      </section>

    </footer>
    <a id="back-to-top" href="#" className="btn btn-light btn-lg back-to-top" role="button"><i
      className="fa fa-angle-up"></i></a>
  </>
}

export default Footer