import React, { useEffect } from "react"
import { connect } from 'react-redux'
import ImageAssets from "../../utils/ImageAssets"
import '../css/pages/Userdetailstree.css'
import { getUsersTree } from '../../actions/userActions'
import $ from 'jquery'

const UserDetailsTree = ({ ...props }) => {
  const userDetails = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    props.getUsersTree(JSON.parse(localStorage.getItem('user'))._id)
  }, [])

  useEffect(() => {
    $(function () {
      $('.genealogy-tree ul').hide();
      $('.genealogy-tree>ul').show();
      $('.genealogy-tree ul.active').show();
      $('.genealogy-tree li').on('click', function (e) {
        var children = $(this).find('> ul');
        if (children.is(":visible")) children.hide('fast').removeClass('active');
        else children.show('fast').addClass('active');
        e.stopPropagation();
      });
    });
  }, [])

  return <>
    <section>
      <div className="col-lg-12 col-md-12">
        <div className="">
          <div className="tree">
            <ul>
              <li>
                <a href="#" className="btn-group" data-toggle="popover-x" data-target="#" data-placement="left left-top" data-trigger="hover">
                  <div>
                    <img src={"https://getsetgoworld.com/webtheme/images/" + (userDetails.isGreen ? "user" : "red") + ".png"} />
                  </div>
                  {userDetails.firstName}
                </a>
                <div id={userDetails._id} className="popover popover-default popover-x in left left-top" aria-hidden="true">
                  <div className="arrow" ></div>
                  <div className="popover-body popover-content">
                    <div className="card mb-0 ">
                      <div className="card-body p-1">
                        <div className="media">
                          <img src={"https://getsetgoworld.com/webtheme/images/" + (userDetails.isGreen ? "user" : "red") + ".png"} alt="image" className="mr-3  avatar-sm rounded-circle" />
                          <div className="media-body">
                            <h5 className="mb-1 mt-1">{userDetails.firstName + ' ' + userDetails.lastName}</h5>
                            <p className="mb-0"> {userDetails._id} </p>
                          </div>
                        </div>
                        <div className="media">
                          <p className="mb-0">{userDetails.email}</p><br />
                        </div>
                        <div className="media">
                          <p className="mb-0">{userDetails.mobileNumber}</p><br />
                        </div>
                        <div className="media">
                          <p className="mb-0">Registration Date</p>
                          <p className="mb-0">{new Date(userDetails.createdAt)}</p><br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div><div className="popover-x-marker" style={{ display: "none" }}></div>
                <ul>
                  {
                    props.network.map((eachUser, index) => {
                      return (
                        <li>
                          <a href="#" className="btn-group" data-toggle="popover-x" data-target="#" data-placement="left left-top" data-trigger="hover">
                            <div>
                              <img src={"https://getsetgoworld.com/webtheme/images/" + (eachUser.isGreen ? "user" : "red") + ".png"} />
                            </div>
                            {eachUser.firstName}
                          </a>
                          <div id={eachUser._id} className="popover popover-default popover-x in left left-top" aria-hidden="true">
                            <div className="arrow" style={{ top: 10 }}></div>
                            <div className="popover-body popover-content">
                              <div className="card mb-0 ">
                                <div className="card-body p-1">
                                  <div className="media">
                                    <img src={"https://getsetgoworld.com/webtheme/images/" + (eachUser.isGreen ? "user" : "red") + ".png"} alt="image" className="mr-3  avatar-sm rounded-circle" />
                                    <div className="media-body">
                                      <h5 className="mb-1 mt-1">{eachUser.firstName}</h5>
                                      <p className="mb-0">{eachUser._id}</p>
                                    </div>
                                  </div>
                                  <div className="media">
                                    <p className="mb-0">{eachUser.email}</p><br />
                                  </div>
                                  <div className="media">
                                    <p className="mb-0">{eachUser.mobileNumber}</p><br />
                                  </div>
                                  <div className="media">
                                    <p className="mb-0">Registration Date</p>
                                    <p className="mb-0">{new Date(eachUser.createdAt)}</p><br />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="popover-x-marker" style={{ display: "none" }} />
                        </li>
                      )
                    })
                  }
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </>
}

const mapStateToProps = (state) => ({
  network: state.users.network
})

export default connect(mapStateToProps, { getUsersTree })(UserDetailsTree)