import React from 'react'
import { Route } from 'react-router-dom'

//This route prevents unauthenticated users to the login screen if they try to access restricted pages.
const PublicRoute = ({ component: Component, page, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      <Component
        {...props}
        page={page}
      />
    }
  />
)

export default PublicRoute;