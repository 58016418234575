import { ADD_CATEGORY, DELETE_CATEGORY, GET_CATEGORY, UPDATE_CATEGORY } from "../actions/types"

const initialState = {
  list: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_CATEGORY:
      return { ...state, list: [...state.list, action.payload] }
    case GET_CATEGORY:
      return { ...state, list: action.payload }
    case UPDATE_CATEGORY:
      let tempArray = []
      state.list.forEach(e => {
        if (String(e._id) === String(action.payload.id)) {
          tempArray.push({ ...e, name: action.payload.name })
        } else {
          tempArray.push(e)
        }
      })
      return { ...state, list: tempArray }
    case DELETE_CATEGORY:
      let tempArray1 = []
      state.list.forEach(e => {
        if (String(e._id) !== String(action.payload)) {
          tempArray1.push(e)
        }
      })
      return { ...state, list: tempArray1 }
    default:
      return state
  }
}