import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import SideMenu from '../component/common/SideMenu'
import { UIRoutes } from '../utils/FrontendRoutes'

let RestrictedRoute = ({
  component: Component,
  auth,
  ...rest
}) => (
  <Route
    {...rest}
    render={
      (props) =>
        auth.isAuthenticated && auth.role === 'ADMIN'
          ?
          <SideMenu {...props}>
            <Component {...props} />
          </SideMenu>
          :
          <Redirect to={UIRoutes.HOME} />
    }
  />
)

RestrictedRoute.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})

export default connect(mapStateToProps)(RestrictedRoute)
