import React, { useEffect } from 'react'
import '../css/ContactUs/ContactUs.css'
import '../css/common/Banner.css'
import Breadcrums from '../common/Banner'
// import { Card, Button } from 'react-bootstrap'
import { UIRoutes } from '../../utils/FrontendRoutes'
import '../css/pages/Aboutus.css'
import { ImageAssets } from '../../utils/ImageAssets'
import AOS from "aos";
import "aos/dist/aos.css";
import CustomButton from '../common/CustomButton'

const CouncelingDmit = ({ ...props }) => {
    useEffect(() => {
        AOS.init({
            duration: 1200
        });
        AOS.refresh();
    }, []);
    const redirectToAllProducts = () => {
        props.history(UIRoutes.PRODUCT_LIST)
    }
    return <>
        <section id="main" className="Smart-School Counceling clearfix" role="main">
            <Breadcrums divClassName="Counceling-banner main-banner"
                breadcrums={JSON.stringify([{ key: "Home ", value: UIRoutes.HOME }, { key: "Career Counceling with Dmit Test", value: UIRoutes.COUNCELINGDMIT }])}
            />
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="contents section-header pb-3 mt-3 mb-1 my-sm-4" data-aos="zoom-in">
                            <h2 className="section-title  wow fadeIn animated" data-wow-delay="0.2s">Career Counceling with Dermatoglyphics Multiple Intelligence
                                Test(D.M.I.T).</h2>
                        </div>
                    </div>
                </div>

                <div className="row">

                    <div className="col-lg-6" data-aos="zoom-out-left">
                        <h3 className="section-title" >What is Dermatoglyphics Multiple Intelligence Test.? </h3>
                        <h3 className="section-title" >It’s also Called DMIT Test</h3>
                        <ul>
                            <li>Dermatoglyphics Multiple Intelligence Test is a truly Scientific study
                                of fingerprint patterns.</li>
                            <li> This will help in understanding a great
                                individual’s Potential &amp; Personality Type. Dermatoglyphics Multiple
                                Intelligence Test (DMIT) is based on understanding from
                                Neuroscience, Genetics, Dermatoglyphics, Psychology, and
                                Embryology. </li>
                            <li> DMIT Test Assessment method has been formulated by
                                scientists and Medical experts.</li>
                            <li>DMIT Test has accepted by the Entire
                                world and also from renowned universities. Medical experts and
                                Approved clinical experience confirmed that fingerprints provide
                                accurate analysis of humans Multiple Intelligences and inborn
                                potential. </li>
                            <li>  Traditionally only IQ test is used to be a measurement tool
                                for the degree of intelligence. Now Dermatoglyphics Multiple
                                Intelligence Test Using in the field of School college and educational
                                institutions.</li>
                            <li> Human resource management. </li>
                            <li>  Children’s memory Enhancements Programs. </li>
                            <li>    Career Guidance and Career Counseling</li>
                        </ul>
                        <div className='buynow-home-product'>
                            <p className='text-danger'><del><small>17700/- <i className="fas fa-rupee-sign"></i></small></del>&nbsp;&nbsp;<strong className='pl-5'>11800/- <i className="fas fa-rupee-sign"></i></strong></p>
                            <CustomButton
                                divClassName=''
                                className='btn blue-button w-100 font-weight-bold mt-2'
                                onClick={redirectToAllProducts}
                                text='Buy Now'
                            />
                        </div>
                    </div>
                    <div className="col-lg-6" data-aos="zoom-out-right">
                        <img className="cfbanner horizontal-logo" src={ImageAssets.PRODUCTSSERVICES.PRODUCTSSERVICES10} alt="forecastr logo" />
                    </div>

                </div>


            </div>
        </section>
    </>
}

export default CouncelingDmit 