import React, { useEffect } from "react"
// import ImageAssets from "../../utils/ImageAssets"
import '../css/pages/Commission.css'

const Commission = ({ ...props }) => {

  // useEffect(() => {
  //   props.getCategory()
  // }, [])

  return <>

    <section className="timeline">

      <div className="shadow-lg p-3 mb-5 bg-body rounded">
        <h3 className="text-dark main-title-border ">Percentage Info</h3>
        <div className="container1">
          <div className="timeline-block timeline-block-right">
            <div className="left_content">
              <h3 className="text-dark">10% </h3>
            </div>
            <div className="marker"></div>
            <div className="timeline-content shadow p-3 mb-5 bg-white rounded w-80">
              <h3 className="text-dark">After 1 Direct Green User</h3>
              <h4 className=" p-3 bg-primary text-white">Total User-0</h4>
              <h4 className=" p-3 bg-success text-white">Green User-0</h4>
              <h4 className="p-3 bg-danger text-white">Red Users - 0</h4>

            </div>
          </div>

          <div className="timeline-block timeline-block-left">
            <div className="left_content">
              <h3 className="text-dark">15% </h3>
            </div>
            <div className="marker"></div>
            <div className="timeline-content shadow p-3 mb-5 bg-white rounded w-80">
              <h3 className="text-dark">After 5 First level Green users (You are here)
              </h3>
              <h4 className="p-3 bg-primary text-white">Total User-0</h4>
              <h4 className="p-3 bg-success text-white">Green User-0</h4>
              <h4 className="p-3 bg-danger text-white">Red Users - 0</h4>
            </div>
          </div>

          <div className="timeline-block timeline-block-right">
            <div className="left_content">
              <h3 className="text-dark">20% </h3>
            </div>
            <div className="marker"></div>
            <div className="timeline-content shadow p-3 mb-5 bg-white rounded w-80">
              <h3 className="text-dark">After 15 Second level Green users
              </h3>
              <h4 className="p-3 bg-primary text-white">Total User-0</h4>
              <h4 className="p-3 bg-success text-white">Green User-0</h4>
              <h4 className="p-3 bg-danger text-white">Red Users - 0</h4>
            </div>
          </div>

          <div className="timeline-block timeline-block-left">
            <div className="left_content">
              <h3 className="text-dark">25%</h3>
            </div>
            <div className="marker"></div>
            <div className="timeline-content shadow p-3 mb-5 bg-white rounded w-80">
              <h3 className="text-dark">After 25 Third level Green users
              </h3>
              <h4 className="p-3 bg-primary text-white">Total User-0</h4>
              <h4 className="p-3 bg-success text-white">Green User-0</h4>
              <h4 className="p-3 bg-danger text-white">Red Users - 0</h4>
            </div>
          </div>

          <div className="timeline-block timeline-block-right">
            <div className="left_content">
              <h3 className="text-dark">28%</h3>
            </div>
            <div className="marker"></div>
            <div className="timeline-content shadow p-3 mb-5 bg-white rounded w-80">
              <h3 className="text-dark">After 35 Fourth level Green users
              </h3>
              <h4 className="p-3 bg-primary text-white">Total User-0</h4>
              <h4 className="p-3 bg-success text-white">Green User-0</h4>
              <h4 className="p-3 bg-danger text-white">Red Users - 0</h4>
            </div>
          </div>

          <div className="timeline-block timeline-block-left">
            <div className="left_content">
              <h3 className="text-dark">30%</h3>
            </div>
            <div className="marker"></div>
            <div className="timeline-content shadow p-3 mb-5 bg-white rounded w-80">
              <h3>After 50 Fifth level Green users
              </h3>
              <h4 className="p-3 bg-primary text-white">Total User-0</h4>
              <h4 className="p-3 bg-success text-white">Green User-0</h4>
              <h4 className="p-3 bg-danger text-white">Red Users - 0</h4>

            </div>
          </div>
        </div>
      </div>
    </section>
  </>
}

export default Commission