import React from 'react'
import '../css/pages/Blog.css'
import '../css/common/Banner.css'
import Breadcrums from '../common/Banner'
import { UIRoutes } from '../../utils/FrontendRoutes'
import ImageAssets from '../../utils/ImageAssets'
import { Link } from 'react-router-dom'

const PastEvents = () => {
  return <>
    <section id="main" className="PastEvents clearfix" role="main">
      <Breadcrums divClassName="PastEvents-banner main-banner"
        breadcrums={JSON.stringify([{ key: "Home ", value: UIRoutes.HOME }, { key: "Past Events", value: UIRoutes.PASTEVENTS }])}
      />
      <div className="container  mt-3">
        <div className="row">
          <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5">
            <div className="card h-100">
              <img className="card-img-top" src={ImageAssets.blog.blog1} alt="Bologna" />
              <div className="card-body">
                <h4 className="card-title"><a href="#">Power in you – Students &amp; Parents Seminar </a></h4>
               <Link className="card-link text-secondary" to={UIRoutes.BLOG1} >Read More</Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5">
            <div className="card h-100">
              <img className="card-img-top" src={ImageAssets.PastEvents.PastEvents2} alt="Bologna" />
              <div className="card-body">
                <h4 className="card-title"><a href="#">TECHNOXIAN GIANT FESTIVAL</a></h4>
               <Link className="card-link text-secondary" to={UIRoutes.BLOG6} >Read More</Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5">
            <div className="card h-100">
              <img className="card-img-top" src={ImageAssets.PastEvents.PastEvents3} alt="Bologna" />
              <div className="card-body">
                <h4 className="card-title"><a href="#">AICRA INDIA STEM SUMMIT &amp; AWARDS 2019 ON MARCH 08,2019 IN NEW DELHI
                </a></h4>
               <Link className="card-link text-secondary" to={UIRoutes.BLOG7} >Read More</Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5">
            <div className="card h-100">
              <img className="card-img-top" src={ImageAssets.PastEvents.PastEvents4} alt="Bologna" />
              <div className="card-body">
                <h4 className="card-title"><a href="#">TECHNOXIAN 2018</a></h4>
               <Link className="card-link text-secondary" to={UIRoutes.BLOG8} >Read More</Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5">
            <div className="card h-100">
              <img className="card-img-top" src={ImageAssets.PastEvents.PastEvents5} alt="Bologna" />
              <div className="card-body">
                <h4 className="card-title"><a href="#">AICRA INDIA STEM SUMMIT &amp; AWARDS 2018</a></h4>
               <Link className="card-link text-secondary" to={UIRoutes.BLOG9} >Read More</Link>
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* test */}


    </section>
  </>
}

export default PastEvents