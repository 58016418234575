import { combineReducers } from 'redux'
import { AUTH_LOGOUT } from '../actions/types'
import authReducer from './authReducer'
import categoryReducer from './categoryReducer'
import loadingReducer from './loadingReducer'
import productReducer from './productReducer'
import userReducer from './userReducer'
import paymentReducer from './paymentReducer'
import couponReducer from './couponReducer'
import networkReducer from './networkReducer'
import galleryReducer from './galleryReducer'
import awardReducer from './awardReducer'
import reportReducer from './reportReducer'

const appReducer = combineReducers({
  auth: authReducer,
  loading: loadingReducer,
  category: categoryReducer,
  products: productReducer,
  users: userReducer,
  payments: paymentReducer,
  coupons: couponReducer,
  networks: networkReducer,
  galleries: galleryReducer,
  awards: awardReducer,
  reports: reportReducer
})

const rootReducer = (state, action) => {
  if (action.type === AUTH_LOGOUT) {
    //Reset the state to the initial state
    let newState = { ...state }
    newState.auth = {
      isAuthenticated: false,
      email: ''
    }
    state = { ...newState }
    return appReducer(state, action)
  }
  return appReducer(state, action)
}

export default rootReducer
