import React from 'react'
import '../css/pages/BlogSingle.css'
import '../css/common/Banner.css'
import Breadcrums from '../common/Banner'
import { UIRoutes } from '../../utils/FrontendRoutes'
import ImageAssets from '../../utils/ImageAssets'
import BlogSidebar from './blog-sidebar'
import { Link } from 'react-router-dom'



const Blog3 = () => {
  return <>
    <section id="main" className="BlogSingle clearfix" role="main">
      <Breadcrums divClassName="BlogSingle-banner main-banner"
        breadcrums={JSON.stringify([{ key: "Home ", value: UIRoutes.HOME }, { key: "Blog", value: UIRoutes.BLOG }, { key: "CAREER CONNECT", value: UIRoutes.BLOG3 }])}
      />
      <div className="container">

        <div className="row">

          <div className="col-lg-8 entries">

            <article className="entry entry-single">

              <div className="entry-img">
                <img className="card-img-top" src={ImageAssets.blog.blog4} alt="Bologna" />
              </div>

              <h2 className="entry-title text-black">
                CAREER CONNECT
              </h2>

              <div className="entry-meta">
                <ul>
                  <li className="d-flex align-items-center"><i className="icofont-user"></i> <a href="#">BY: ADMIN</a></li>
                  <li className="d-flex align-items-center"><i className="icofont-wall-clock"></i> <a href="#"><time datetime="2020-01-01">IN: BLOG, NEWS, UNCATEGORIZED, UPCOMING EVENTS</time></a></li>
                  <li className="d-flex align-items-center"><i className="icofont-comment"></i> <a href="#">12 Comments</a></li>
                </ul>
              </div>
              <div className="entry-description"><p>Career Connect Is Establishing A Connection Between An Individual’s Likes, Aptitude And Talent To His/Her Appropriate Career. It Aims To Nurture New Talent And Capitalize Young India . Career Connect Strongly Believe That Every Individual Is Unique And Have Their  Own Potential To Grow If Guided And Progress In The Right Way.</p>
              </div>
              <div className="entry-content mt-4">
                <p className='d-flex'>SHARE THIS POST:
                  <div className="social-link">
                    <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer" className="me-4 text-reset">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer" className="me-4 text-reset">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="http://www.careerconnectedu.com/" target="_blank" rel="noopener noreferrer" className="me-4 text-reset">
                      <i className="fab fa-google"></i>
                    </a>
                    <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer" className="me-4 text-reset">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href="https://www.linkedin.com/in/" target="_blank" rel="noopener noreferrer" className="me-4 text-reset">
                      <i className="fab fa-linkedin"></i>
                    </a>
                    <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer" className="me-4 text-reset">
                      <i className="fab fa-youtube"></i>
                    </a>
                  </div>
                </p>

                <p> RELATED POSTS</p>

                {/* <blockquote>
      <i className="icofont-quote-left quote-left"></i>
      <p>
        Et vero doloremque tempore voluptatem ratione vel aut. Deleniti sunt animi aut. Aut eos aliquam doloribus minus autem quos.
      </p>
      <i className="las la-quote-right quote-right"></i>
      <i className="icofont-quote-right quote-right"></i>
    </blockquote> */}

                <div className="row">
                  <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5">
                    <div className="card h-100">
                    <Link className="card-link " to={UIRoutes.BLOG1} >
                      <img className="card-img-top" src={ImageAssets.blog.blog1} alt="Bologna" />
                      <div className="card-body">
                        <h4 className="card-title"><a href="#">Power in you – Students &amp; Parents Seminar by Mrs. Nisha Butani </a></h4>
                        <Link className="card-link text-secondary" to={UIRoutes.BLOG1} >Read More</Link>
                      </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5">
                    <div className="card h-100">
                    <Link className="card-link text-secondary" to={UIRoutes.BLOG2} >
                      <img className="card-img-top" src={ImageAssets.blog.blog2} alt="Bologna" />
                      <div className="card-body">
                        <h4 className="card-title"><a href="#">CAREER FOUNDER</a></h4>
                        <Link className="card-link text-secondary" to={UIRoutes.BLOG2} >Read More</Link>
                      </div>
                      </Link>
                    </div>
                  </div>
                </div>

              </div>
              {/* 
  <div className="entry-footer clearfix">
    <div className="float-left">
      <i className="icofont-folder"></i>
      <ul className="cats">
        <li><a href="#">Business</a></li>
      </ul>

      <i className="icofont-tags"></i>
      <ul className="tags">
        <li><a href="#">Creative</a></li>
        <li><a href="#">Tips</a></li>
        <li><a href="#">Marketing</a></li>
      </ul>
    </div>

    <div className="float-right share">
      <a href="" title="Share on Twitter"><i className="icofont-twitter"></i></a>
      <a href="" title="Share on Facebook"><i className="icofont-facebook"></i></a>
      <a href="" title="Share on Instagram"><i className="icofont-instagram"></i></a>
    </div>

  </div> */}

            </article>
            {/* {/* End blog entry */}

            {/* <div className="blog-author clearfix">
  <img src="assets/img/blog-author.jpg" className="rounded-circle float-left" alt="" />
  <h4>Jane Smith</h4>
  <div className="social-links">
    <a href="https://twitters.com/#"><i className="icofont-twitter"></i></a>
    <a href="https://facebook.com/#"><i className="icofont-facebook"></i></a>
    <a href="https://instagram.com/#"><i className="icofont-instagram"></i></a>
  </div>
  <p>
    Itaque quidem optio quia voluptatibus dolorem dolor. Modi eum sed possimus accusantium. Quas repellat voluptatem officia numquam sint aspernatur voluptas. Esse et accusantium ut unde voluptas.
  </p>
</div> */}
            {/* End blog author bio */}


          </div>
          <BlogSidebar />
        </div>

      </div>
    </section>
  </>
}

export default Blog3