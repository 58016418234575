import { GET_USER_REPORT } from "../actions/types"

const initialState = {
  users: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_REPORT:
      return { ...state, users: { ...action.payload } }
    default:
      return state
  }
}