import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getGallery, deleteGallery } from '../../actions/galleryActions'
import Table from 'react-bootstrap/Table';
import '../css/pages/Aboutus.css'

const GalleryListing = ({ ...props }) => {

  useEffect(() => {
    props.getGallery()
  }, [])

  const handleChange = (id, index) => {
    props.deleteGallery(id, index)
  }

  return <>
    <section id="main" className="couponlisting clearfix" role="main">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-11 shadow-lg p-3 mb-5 bg-body rounded">
            <h3 className="text-dark main-title-border ">Gallery List </h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Gallery</th>
                  <th>Gallery Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  props.list.map((eachGallery, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          {
                            eachGallery.type === 'IMAGE'
                              ?
                              <img src={eachGallery.source} style={{ height: '100px', width: '100px' }} />
                              :
                              <video src={eachGallery.source} style={{ height: '100px', width: '100px' }} />
                          }
                        </td>
                        <td>{eachGallery.galleryType}</td>
                        <td><span onClick={() => handleChange(eachGallery._id, index)}>Delete</span></td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </section>
  </>
}

const mapStateToProps = (state) => ({
  list: state.galleries.list
})

export default connect(mapStateToProps, { getGallery, deleteGallery })(GalleryListing)