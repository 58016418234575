import React from 'react'
import '../css/ContactUs/ContactUs.css'
import '../css/common/Banner.css'
import Breadcrums from '../common/Banner'
import { UIRoutes } from '../../utils/FrontendRoutes'
import ImageAssets from '../../utils/ImageAssets'


const SmartSchool = () => {
    return <>
        <section id="main" className="Smart-School clearfix" role="main">
            <Breadcrums divClassName="smartschool-banner main-banner"
                breadcrums={JSON.stringify([{ key: "Home ", value: UIRoutes.HOME }, { key: "Smart School", value: UIRoutes.SMARTSCHOOL }])}
            />
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="contents section-header pb-3 mt-3 mb-1 my-sm-4">
                            <h2 className="section-title  wow fadeIn animated" data-wow-delay="0.2s">Smart School </h2>
                            <h3 className="text-center mb-3"> (Distributor – Gujarat)</h3>
                        </div>

                        <p className="text-center mb-5">&quot;Smart School offers world class Educational Products to targeted
                            students, schools and coaching institutes. The products are mapped as
                            per CBSE, ICSE and various STATE BOARD of India. The products
                            not only provide the best learning experience to students but also helps
                            them to revive their Interest in particular subjects because of its
                            Animated Audio &amp; Video Presentation Methodology. We have catered
                            more than 1,000,000+ students and over 1000 schools world-wide and
                            the company is poised to become one of the largest KG-12<sup>th</sup> technology
                            solution provider world over.&quot;
                        </p>

                    </div>
                    <div className='col-lg-12'>
                        <h3 className="text-center">World-class Educational Products</h3>
                        <p>Smart School offers world class education products, targeted towards students, schools and coaching institutes. The products are mapped as per CBSE, ICSE and various STATE BOARD of India. The products not only provides the best learning experience to students, but also create a great business opportunity in online education.</p>
                        <div className='smartschool-details'>
                            <h3>1.&nbsp; Smart School Tutor</h3>
                            <p>The Smart School Tutor is an offline product, which is designed for Indian students. The product consists of KG-12<sup>th</sup> digital content, mapped as per CBSE, ICSE and STATE BOARD and is supported by robust assessment software.</p>
                            <h4 className='mt-3 mb-3'>Smart School Tutor: The Education Power pack!</h4>
                            <p>Smart School Tutor is an eLearning package containing curriculum mapped KG-12<sup>th</sup> digital content supported by robust assessment software (Exam guru) and is integrated on an advanced offline/online LMS. With over 50,000 modules mapped to school curriculum, the software is best tool to secure high grades in the school examination. The tutor consists of three major components:-</p>
                            <ul>
                                <li>KG-12<sup>th</sup> Digital Content</li>
                                <li>Exam Guru</li>
                                <li>Offline/Online LMS</li>
                            </ul>
                            <h3>2.&nbsp; Smart School ICT</h3>
                            <p>Smart School ICT solution is a product specially designed for Indian schools. The product consists of KG-12<sup>th</sup> school digital content, which is mapped as per CBSE, ICSE and STATE BOARD and is powered with a robust assessment software.</p>
                            <h4 className='mt-3 mb-3'>A Revolutionary ICT solution!</h4>
                            <p>Smart School ICT is a revolutionary product for the next generation schools. The product has been designed to harness the best possible technology available and overcome the deficiencies in the offering of the existing players in the eLearning sector. The solution comprises of KG-12<sup>th</sup> Digital content, assessment suite hosted on a most advanced offline/online LMS. The Educational content is strictly mapped as per school curriculum and can be further localized as per need. The Smart School ICT comprises of the following components:-</p>
                            <ul>
                                <li>KG-12<sup>th</sup> Digital Content</li>
                                <li>Assessment suite</li>
                                <li>Online/Offline LMS</li>
                            </ul>
                            {/* <h3>3.&nbsp; Smart School Tab : The Education Powerhouse</h3>
                            <p>Smart School Tab is next generation Windows/Android based tablet, powered with superior hardware configuration and coupled with 1 year license for CBSE, ICSE and state mapped KG-12<sup>th</sup> school <strong>content supported</strong> by robust assessment software.</p>
                            <h4 className='mt-3 mb-3'>Smart School Tab: The Education Powerhouse</h4>
                            <p>Smart School Tab is next generation android based tablet, powered with superior hardware configuration and coupled with 1 year license for curriculum mapped KG-12<sup>th</sup> digital content supported by robust assessment software. Based on latest android operating system, the tablet comes with advanced android features, which makes it a complete learning package for the KG-12<sup>th</sup> community.</p>
                            <ul>
                                <li>KG-12<sup>th</sup> Digital Content &amp; Assessment</li>
                                <li>Superior Hardware configuration</li>
                                <li>Smart School Product Warranty</li>
                            </ul> */}
                            <h3>3.&nbsp; Smart School ERP</h3>
                            <p>Smart School ERP is a School Management system that is completely customizable and highly personalized a complete “Individualize experience” for education institutions same time coupled with the provision of running on a cloud.</p>
                            <div className='text-center'> <img draggable="false" className=""
                                alt="➡" src={ImageAssets.Aboutus.smartschool} /></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default SmartSchool