import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getNetwork } from '../../actions/networkActions'
import Table from 'react-bootstrap/Table';

const MyNetwork = ({ ...props }) => {

  useEffect(() => {
    props.getNetwork(JSON.parse(localStorage.getItem('user'))._id)
  }, [])

  return (
    <div className='shadow-lg p-3 mb-5 bg-body rounded'>
      <Table striped bordered hover responsive="md">
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Green Status</th>
            <th>Email</th>
            <th>Mobile Number</th>
          </tr>
        </thead>
        <tbody>
          {
            props.list.map((eachUser, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>{eachUser.firstName}</td>
                  <td>{eachUser.lastName}</td>
                  <td>{eachUser.isGreen ? 'Yes' : 'No'}</td>
                  <td>{eachUser.email}</td>
                  <td>{eachUser.mobileNumber}</td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    </div>
  )
}

const mapStateToProps = (state) => ({
  list: state.networks.list
})

export default connect(mapStateToProps, { getNetwork })(MyNetwork)