import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import '../css/pages/Gallery.css'
import '../css/common/Banner.css'
import '../css/pages/Aboutus.css'
import Breadcrums from '../common/Banner'
// import $ from 'jquery'
import { UIRoutes } from '../../utils/FrontendRoutes'
import ImageAssets from '../../utils/ImageAssets'
import { getGallery } from '../../actions/galleryActions'

const Gallery = ({ ...props }) => {
  const [active, setActive] = useState('all')

  useEffect(() => {
    props.getGallery()
  }, [])

  useEffect(() => {
    let sortBtn = document.querySelector('.filter-menu').children;
    let sortItem = document.querySelector('.filter-item').children;

    for (let i = 0; i < sortBtn.length; i++) {
      sortBtn[i].addEventListener('click', function () {
        for (let j = 0; j < sortBtn.length; j++) {
          sortBtn[j].classList.remove('current');
        }

        this.classList.add('current');


        let targetData = this.getAttribute('data-target');

        for (let k = 0; k < sortItem.length; k++) {
          sortItem[k].classList.remove('active');
          sortItem[k].classList.add('delete');

          if (sortItem[k].getAttribute('data-item') === targetData || targetData === "all") {
            sortItem[k].classList.remove('delete');
            sortItem[k].classList.add('active');
          }
        }
      });
    }
  }, [])

  const handleSetActive = (tab) => {
    setActive(tab)
  }

  return <>
    <section id="main" className="Gallery clearfix" role="main">
      <Breadcrums divClassName="gallery-banner main-banner"
        breadcrums={JSON.stringify([{ key: "Home", value: UIRoutes.HOME }, { key: "Gallery", value: UIRoutes.GALLERY }])}
      />
      <div className="container">
        <div className='row'>
          <div className='col-lg-12'>
            <ul className="filter-menu">
              <li className={'all ' + (active === 'all' ? 'current' : '')} onClick={() => handleSetActive('all')} data-target="all">All</li>
              <li className={(active === 'anand-workshop' ? 'current' : '')} onClick={() => handleSetActive('anand-workshop')} data-target="anand-workshop">Anand Workshop</li>
              <li className={(active === 'stem-india' ? 'current' : '')} onClick={() => handleSetActive('stem-india')} data-target="stem-india">Stem India</li>
              <li className={(active === 'technoxian' ? 'current' : '')} onClick={() => handleSetActive('technoxian')} data-target="technoxian">Technoxian</li>
              <li className={(active === 'isro-exhibition' ? 'current' : '')} onClick={() => handleSetActive('isro-exhibition')} data-target="isro-exhibition">ISRO Exhibition</li>
            </ul>
          </div>
        </div>
        <div className='row'>
          <ul className="filter-item">
            {
              props.list.map((eachGallery, index) => {
                return <div className="item col-lg-4" data-item={eachGallery.galleryType}>{eachGallery.type === 'IMAGE' ? <a href={eachGallery.source} data-fancybox="gallery"><img src={eachGallery.source} preload="auto"/></a> : <a href={eachGallery.source} data-fancybox="gallery"><video src={eachGallery.source} /></a>}</div>
              })
            }
            {/* <div className="item col-lg-4" data-item="Anand Workshop"><a href={ImageAssets.Gallery.gallery1} data-fancybox="gallery"><img src={ImageAssets.Gallery.gallery1} preload="auto" alt="Mountain reflection on lake" /></a></div>
            <div className="item col-lg-4" data-item="Stem India"><a href={ImageAssets.Gallery.gallery19} data-fancybox="gallery"><img src={ImageAssets.Gallery.gallery19} preload="auto" alt="gallery image" /></a></div>
            <div className="item col-lg-4" data-item="Technoxian"><a href={ImageAssets.Gallery.gallery23} data-fancybox="gallery"><img src={ImageAssets.Gallery.gallery23} preload="auto" alt="gallery image" /></a></div> */}
            <div className="item col-lg-4" data-item="isro-exhibition"><a href={ImageAssets.Gallery.gallery29} data-fancybox="isro-exhibition"><img src={ImageAssets.Gallery.gallery29} preload="auto" alt="gallery image" /></a></div>
            <div className="item col-lg-4" data-item="isro-exhibition"><a href={ImageAssets.Gallery.gallery30} data-fancybox="isro-exhibition"><img src={ImageAssets.Gallery.gallery30} preload="auto" alt="gallery image" /></a></div>
            <div className="item col-lg-4" data-item="isro-exhibition"><a href={ImageAssets.Gallery.gallery31} data-fancybox="isro-exhibition"><img src={ImageAssets.Gallery.gallery31} preload="auto" alt="gallery image" /></a></div>
            <div className="item col-lg-4" data-item="isro-exhibition"><a href={ImageAssets.Gallery.gallery32} data-fancybox="isro-exhibition"><img src={ImageAssets.Gallery.gallery32} preload="auto" alt="gallery image" /></a></div>
            <div className="item col-lg-4" data-item="isro-exhibition"><a href={ImageAssets.Gallery.gallery33} data-fancybox="isro-exhibition"><img src={ImageAssets.Gallery.gallery33} preload="auto" alt="gallery image" /></a></div>
            <div className="item col-lg-4" data-item="isro-exhibition"><a href={ImageAssets.Gallery.gallery34} data-fancybox="isro-exhibition"><img src={ImageAssets.Gallery.gallery34} preload="auto" alt="gallery image" /></a></div>
            <div className="item col-lg-4" data-item="isro-exhibition"><a href={ImageAssets.Gallery.gallery35} data-fancybox="isro-exhibition"><img src={ImageAssets.Gallery.gallery35} preload="auto" alt="gallery image" /></a></div>
            <div className="item col-lg-4" data-item="isro-exhibition"><a href={ImageAssets.Gallery.gallery36} data-fancybox="isro-exhibition"><img src={ImageAssets.Gallery.gallery36} preload="auto" alt="gallery image" /></a></div>
            <div className="item col-lg-4" data-item="isro-exhibition"><a href={ImageAssets.Gallery.gallery37} data-fancybox="isro-exhibition"><img src={ImageAssets.Gallery.gallery37} preload="auto" alt="gallery image" /></a></div>
            <div className="item col-lg-4" data-item="isro-exhibition"><a href={ImageAssets.Gallery.gallery38} data-fancybox="isro-exhibition"><img src={ImageAssets.Gallery.gallery38} preload="auto" alt="gallery image" /></a></div>
            <div className="item col-lg-4" data-item="isro-exhibition"><a href={ImageAssets.Gallery.gallery39} data-fancybox="isro-exhibition"><img src={ImageAssets.Gallery.gallery39} preload="auto" alt="gallery image" /></a></div>
            <div className="item col-lg-4" data-item="isro-exhibition"><a href={ImageAssets.Gallery.gallery40} data-fancybox="isro-exhibition"><img src={ImageAssets.Gallery.gallery40} preload="auto" alt="gallery image" /></a></div>
            <div className="item col-lg-4" data-item="isro-exhibition"><a href={ImageAssets.Gallery.gallery41} data-fancybox="isro-exhibition"><img src={ImageAssets.Gallery.gallery41} preload="auto" alt="gallery image" /></a></div>
            <div className="item col-lg-4" data-item="isro-exhibition"><a href={ImageAssets.Gallery.gallery42} data-fancybox="isro-exhibition"><img src={ImageAssets.Gallery.gallery42} preload="auto" alt="gallery image" /></a></div>
            <div className="item col-lg-4" data-item="isro-exhibition"><a href={ImageAssets.Gallery.gallery43} data-fancybox="isro-exhibition"><img src={ImageAssets.Gallery.gallery43} preload="auto" alt="gallery image" /></a></div>
          </ul>
        </div>
      </div>
    </section>
  </>
}

const mapStateToProps = (state) => ({
  list: state.galleries.list
})

export default connect(mapStateToProps, { getGallery })(Gallery)