import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import { UIRoutes } from '../../utils/FrontendRoutes'
import '../css/common/Header.css'
import ImageAssets from '../../utils/ImageAssets'
import { logoutUser } from '../../actions/authActions'
// import CustomButton from './CustomButton'

const Header = ({ ...props }) => {
  const [isShowClass, setIsShowClass] = useState('');

  useEffect(() => {
    /* navbar fixed */
    $(window).scroll(function () {
      if ($(window).scrollTop() > 63) {
        $('.navbar-main').addClass('navbar-fixed');
      }
      if ($(window).scrollTop() < 64) {
        $('.navbar-main').removeClass('navbar-fixed');
      }
    });

    $(document).ready(function () {
      $(window).scroll(function () {
        if ($(this).scrollTop() > 50) {
          $('#back-to-top').fadeIn();
        } else {
          $('#back-to-top').fadeOut();
        }
      });
      // scroll body to 0px on click
      $('#back-to-top').click(function () {
        window.scrollTo(0, 0)
        // $('body,html').animate({ scrollTop: 0 }, 400);
        return false;
      });
    });
  }, [])

  const handleSignout = () => {
    props.logoutUser();
  }

  const handleRedirectToAnotherPage = (classToSet) => {
    setIsShowClass(classToSet);
  }

  return (
    <>
      <nav className="navbar navbar-main navbar-light header-top">
      <div className="action" data-aos="zoom-out">
                <Link className="btn franchise-btn btn-light" to={UIRoutes.FRANCHISEFROM}> <span>Franchise Form</span> </Link>
              </div>
        <div className="navbar-brand"></div>
        <form className="form-inline">
          <div id="contact-info" className="wow bounceInDown animated pull-right animated">
            <div>
              <i className="fab fa-whatsapp"></i><a href="tel:+91 9499517565">+91 9499517565</a> |
            </div>
            <div>
              <i className="fas fa-envelope"></i><a href="mailto: info@careerconnectedu.com"> info@careerconnectedu.com </a> |
            </div>
            <div>
              <a href="https://www.facebook.com/pages/category/Education/Career-Connect-1228862200548321/" target="_blank" rel="noopener noreferrer" className="me-2 text-reset">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://twitter.com/CAREERCONNECT9" target="_blank" rel="noopener noreferrer" className="me-2 text-reset">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="http://www.careerconnectedu.com/" target="_blank" rel="noopener noreferrer" className="me-2 text-reset">
                <i className="fab fa-google"></i>
              </a>
              <a href="https://www.instagram.com/careerconnect29/" target="_blank" rel="noopener noreferrer" className="me-2 text-reset">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://www.linkedin.com/in/career-connect-496566179/" target="_blank" rel="noopener noreferrer" className="me-2 text-reset">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="https://www.youtube.com/channel/UCfvZOnlJh_vg2amKJay8-eg?guided_help_flow=3" target="_blank" rel="noopener noreferrer" className="me-2 text-reset">
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>
        </form>
      </nav>
      <nav className="navbar navbar-main navbar-expand-lg bg-white text-dark">
        <div className="container-fluid mx-3">
          <Link className="navbar-brand" to={UIRoutes.HOME}>
            <img className="logo horizontal-logo" src={ImageAssets.common.logo} alt="forecastr logo" />
          </Link>
          <button className="navbar-toggler" onClick={() => handleRedirectToAnotherPage('show')} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"><i className="fas fa-bars"></i></span>
          </button>
          <div className={"collapse navbar-collapse " + isShowClass} id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link className="nav-link" to={UIRoutes.HOME} onClick={() => handleRedirectToAnotherPage('')}>Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={UIRoutes.ABOUTUS} onClick={() => handleRedirectToAnotherPage('')}>About Us</Link>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link" to="#" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Product &amp; Services
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown2">
                  <Link className="dropdown-item" to={UIRoutes.CFAPPLICATION} onClick={() => handleRedirectToAnotherPage('')}>Career Founder Mobile Learning Application License</Link>
                  <Link className="dropdown-item" to={UIRoutes.COUNCELINGDMIT} onClick={() => handleRedirectToAnotherPage('')}>Career Counceling with Dmit Test</Link>
                  <Link className="dropdown-item" to={UIRoutes.COUNCELINGPSYCOMETRIC} onClick={() => handleRedirectToAnotherPage('')}>Career Counceling with Psycometric Test</Link>
                  <Link className="dropdown-item" to={UIRoutes.WINWHITELABEL} onClick={() => handleRedirectToAnotherPage('')}>Win-Win White Label App solution for school..</Link>
                  <Link className="dropdown-item" to={UIRoutes.CAREERFOUNDER} onClick={() => handleRedirectToAnotherPage('')}>Career Founder</Link>
                  <Link className="dropdown-item" to={UIRoutes.GETSETGO} onClick={() => handleRedirectToAnotherPage('')}>Get Set Go</Link>
                  <Link className="dropdown-item" to={UIRoutes.STEMEDUCATION} onClick={() => handleRedirectToAnotherPage('')}>STEM Education</Link>
                  <Link className="dropdown-item" to={UIRoutes.SMARTSCHOOL} onClick={() => handleRedirectToAnotherPage('')}>Smart School</Link>
                  <Link className="dropdown-item" to={UIRoutes.DHEYACAREERMENTORS} onClick={() => handleRedirectToAnotherPage('')}>Dheya Career Mentors</Link>
                  <Link className="dropdown-item" to={UIRoutes.AICRI} onClick={() => handleRedirectToAnotherPage('')}>AICRA</Link>
                  {/* <Link className="dropdown-item" to={UIRoutes.PROTECONPRODUCT} onClick={() => handleRedirectToAnotherPage('')}>Protecon Product</Link> */}
                  {/* <Link className="dropdown-item" to={UIRoutes.TIMTS} onClick={() => handleRedirectToAnotherPage('')}>TIMTS</Link> */}
                </div>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={UIRoutes.GALLERY} onClick={() => handleRedirectToAnotherPage('')}>Gallery</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={UIRoutes.AWARDSACHIEVEMENTS} onClick={() => handleRedirectToAnotherPage('')}>Awards &amp; Achievements</Link>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link " to="#" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  News &amp; Events
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown2">
                  <Link className="dropdown-item" to={UIRoutes.PASTEVENTS} onClick={() => handleRedirectToAnotherPage('')}>Past Events</Link>
                  <Link className="dropdown-item" to={UIRoutes.UPCOMINGEVENTS} onClick={() => handleRedirectToAnotherPage('')}>Upcoming Events</Link>
                </div>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={UIRoutes.BLOG} onClick={() => handleRedirectToAnotherPage('')}>Blog</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={UIRoutes.CONTACTUS} onClick={() => handleRedirectToAnotherPage('')}>Contact Us</Link>
              </li>
              {
                props.isAuthenticated
                  ?
                  <>
                    <li className="nav-item dropdown">
                      <a className="nav-link" to="#" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {props.firstName + ' ' + props.lastName}
                      </a>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdown2">
                        <Link className="dropdown-item" to={UIRoutes.DASHBOARD} onClick={() => handleRedirectToAnotherPage('')}>Dashboard</Link>
                        <a className="dropdown-item" onClick={handleSignout}>Sign Out</a>
                      </div>
                    </li>
                  </>
                  :
                  <li className="nav-item">
                    <Link className="nav-link" to={UIRoutes.SIGNIN} onClick={() => handleRedirectToAnotherPage('')}>Sign In </Link>
                  </li>
              }
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  role: state.auth.role,
  firstName: state.auth.firstName,
  lastName: state.auth.lastName
})

export default connect(mapStateToProps, { logoutUser })(Header)