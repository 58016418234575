import axios from 'axios'
import { toast } from 'react-toastify'
import setLoading from '../utils/LoadingManager'
import setAuthToken from '../interceptors/SetAuthToken'
import { AUTH_SET_USER, AUTH_LOGOUT } from './types'
import { APIRoutes } from '../utils/BackendRoutes'
import { UIRoutes } from '../utils/FrontendRoutes'
import { getErrorMessage } from '../utils/GetErrorMessage'

export const registerAction = (body, history) => dispatch => {
  setLoading(true)
  axios.post(APIRoutes.USER_SIGNUP, body)
    .then(res => {
      if (res.data.success) {
        localStorage.setItem('user', JSON.stringify(res.data.data))
        dispatch({
          type: AUTH_SET_USER,
          payload: res.data.data
        })
        let { token, refreshToken } = res.data.data
        // setAuthDataLocalStorage(token, refreshToken)
        setAuthToken(token)
        toast.success(res.data.message)
        setLoading(false)
        history.push(UIRoutes.HOME)
      } else {
        toast.error(res.data.message)
      }
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const loginAction = (body, history) => dispatch => {
  setLoading(true)
  axios.post(APIRoutes.USER_SIGNIN, body)
    .then(res => {
      if (res.data.success) {
        let { token, refreshToken } = res.data.data
        // if (rememberMe) {
        //   setAuthDataLocalStorage(token, refreshToken)
        // }
        localStorage.setItem('user', JSON.stringify(res.data.data))
        dispatch({
          type: AUTH_SET_USER,
          payload: res.data.data
        })
        setAuthToken(token)
        toast.success(res.data.message)
        setLoading(false)
        history.push(UIRoutes.HOME)
      } else {
        toast.error(res.data.message)
      }
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const forgotPasswordAction = (body, history) => dispatch => {
  toast.error('Please try again after sometime. Our email service is down unexpectedly')
  // setLoading(true)
  // axios.post(APIRoutes.USER_FORGET_PASSWORD, body)
  //   .then(res => {
  //     toast.success(res.data.message)
  //     history.push(UIRoutes.SIGNIN)
  //     setLoading(false)
  //   }).catch(err => {
  //     setLoading(false)
  //     toast.error(getErrorMessage(err))
  //   })
}

// export const resetPasswordAction = (body, history) => () => {
//   setLoading(true)
//   axios.post(APIRoutes.USER_RESET_PASSWORD, body)
//     .then(res => {
//       toast.success(res.data.message)
//       setLoading(false)
//       history.push({ pathname: UIRoutes.SIGNIN })
//     }).catch(err => {
//       setLoading(false)
//       toast.error(getErrorMessage(err))
//     })
// }

export const verifyEmailAction = (email, id, history) => (dispatch) => {
  setLoading(true)
  axios.get(APIRoutes.USER_VERIFY_EMAIL + email + '/' + id)
    .then(res => {
      toast.success(res.data.message)
      setLoading(false)
      history.push({ pathname: UIRoutes.SIGNIN })
    }).catch(err => {
      setLoading(false)
      history.push({ pathname: UIRoutes.SIGNIN })
      toast.error(getErrorMessage(err))
    })
}

// export const resendEmailAction = () => dispatch => {
//   setLoading(true)
//   axios.get(APIRoutes.USER_RESEND_EMAIL)
//     .then(res => {
//       toast.success(res.data.message)
//       setLoading(false)
//     }).catch(err => {
//       setLoading(false)
//       toast.error(getErrorMessage(err))
//     })
// }

// export const onRefreshTokenExpire = (dispatch) => {
//   removeAuthDataLocalStorage()
//   dispatch({ type: AUTH_LOGOUT })
//   setAuthToken(false)
// }

export const logoutUser = () => dispatch => {
  removeAuthDataLocalStorage()
  dispatch({ type: AUTH_LOGOUT })
  setAuthToken(false)
}

// export const setAuthDataLocalStorage = (token, refreshToken) => {
//   // Set token from local storage
//   localStorage.setItem('jwtToken', JSON.stringify(token))
//   localStorage.setItem('jwtRefreshToken', JSON.stringify(refreshToken))
// }

export const removeAuthDataLocalStorage = () => {
  // Remove token from local storage
  localStorage.removeItem('user')
}

// export const setUser = (token, userInfo, dispatch) => {
//   // set token to auth header
//   setAuthToken(token)
//   // set authenticated user before API gets response
//   dispatch({
//     type: AUTH_SET_USER,
//     payload: { email: userInfo.email, emailConfirmed: true, isAdmin: userInfo.isAdmin }
//   })
//   // get user details
//   getUserDetails(dispatch)
// }

// export const getUserDetails = (dispatch) => {
//   setLoading(true)
//   axios.get(APIRoutes.USER_DETAILS)
//     .then(res => {
//       dispatch({
//         type: AUTH_SET_USER,
//         payload: res.data.data
//       })
//       setLoading(false)
//     }).catch(err => {
//       setLoading(false)
//       toast.error(getErrorMessage(err))
//     })
// }

// export const closeHeader = (value) => (dispatch) => {
//   dispatch({
//     type: SET_HEADER,
//     payload: value
//   })
// }

// export const getFromPage = (value) => (dispatch) => {
//   dispatch({
//     type: SET_FORM_PAGE,
//     payload: value
//   })
// }