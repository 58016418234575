import axios from "axios"
import { toast } from "react-toastify"
import { APIRoutes } from "../utils/BackendRoutes"
import { getErrorMessage } from "../utils/GetErrorMessage"
import setLoading from "../utils/LoadingManager"
import { GET_USERS, GET_USERS_TREE } from "./types"

export const getUsers = () => dispatch => {
  setLoading(true)
  axios.get(APIRoutes.GET_USERS)
    .then(res => {
      dispatch({
        type: GET_USERS,
        payload: res.data.data
      })
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const getUsersTree = (id) => dispatch => {
  setLoading(true)
  axios.get(APIRoutes.GET_USERS_TREE + id)
    .then(res => {
      dispatch({
        type: GET_USERS_TREE,
        payload: res.data.data
      })
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const updateRole = (body) => dispatch => {
  setLoading(true)
  axios.put(APIRoutes.UPDATE_USER_ROLE, body)
    .then(res => {
      dispatch({
        type: GET_USERS,
        payload: res.data.data
      })
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}