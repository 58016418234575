import { GET_PAYMENT_LOGS, GET_EARNINGS, GET_PAYMENTS, GET_DASHBOARD } from '../actions/types'

const initialState = {
  logs: [],
  earnings: [],
  payments: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD:
      return { ...state, logs: action.payload.logs, earnings: action.payload.earnings, payments: action.payload.payments }
    case GET_PAYMENT_LOGS:
      return { ...state, logs: action.payload }
    case GET_EARNINGS:
      return { ...state, earnings: action.payload }
    case GET_PAYMENTS:
      return { ...state, payments: action.payload }
    default:
      return state
  }
}