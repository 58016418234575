import React from 'react'
import '../css/ContactUs/ContactUs.css'
import '../css/common/Banner.css'
import Breadcrums from '../common/Banner'
import { UIRoutes } from '../../utils/FrontendRoutes'
import ImageAssets from '../../utils/ImageAssets'


const STEMEducation = () => {
    return <>
        <section id="main" className="STEM-Education clearfix" role="main">
            <Breadcrums divClassName="STEM-Education-banner main-banner"
                breadcrums={JSON.stringify([{ key: "Home ", value: UIRoutes.HOME }, { key: "STEM Education", value: UIRoutes.STEMEDUCATION }])}
            />
            <div className="container">
                <div className="row">
                {/* <div className="contents col-lg-12 section-header pb-3 mt-3 mb-1 my-sm-4">
                            <h2 className="section-title  wow fadeIn animated" data-wow-delay="0.2s">STEM Education </h2>
                        </div> */}
                    <div className="col-lg-6 mt-5 mb-5">
                    <div className="contents section-header pb-3 mt-3 mb-1 my-sm-4">
                            <h2 className="section-title  wow fadeIn animated" data-wow-delay="0.2s">What is STEM Education? </h2>
                        </div>
                        {/* <h3 className="text-left mb-4">What is STEM Education?</h3> */}
                        <p className="text-justify mb-3">STEM is an abbreviation that stands for science, technology, engineering, and mathematics. STEM is an interdisciplinary approach to learning where academic concepts are coupled with real-world lessons. Students apply science, technology, engineering, and mathematics in contexts that make connections between the classroom and the world around them.</p>
                        <p className="text-justify mb-5">The importance of STEM education is emphasized by the&nbsp;<a href="https://study.com/academy/popular/stem-next-generation-science-standards.html" data-cname="article_link" className="external" target="_blank" rel="noopener noreferrer nofollow">Next Generation Science Standards,</a>&nbsp;which were designed to improve how students comprehend and apply science.&nbsp;<a href="https://study.com/academy/lesson/what-is-stem-education-definition-importance-standards.html" data-cname="article_link" className="external" target="_blank" rel="noopener noreferrer nofollow">STEM education</a>&nbsp;typically focuses on project-based learning in the classroom. The projects and activities incorporate&nbsp;<a href="https://study.com/academy/lesson/how-science-technology-are-related.html" data-cname="article_link" className="external" target="_blank" rel="noopener noreferrer nofollow">technology</a>&nbsp;to emphasize the application of science and prepare students for future classes.</p>
                    </div>
                    <div className="col-lg-6 mt-5 mb-5">
                        <img className="cfbanner horizontal-logo" src={ImageAssets.banner.stemedu} alt="forecastr logo" />
                    </div>
                    <div className="col-lg-12">
                        <h3 className="text-center mb-5"> Why is STEM Education Important?</h3>
                        <p>The importance of STEM education is intricately tied to&nbsp;<a href="https://study.com/academy/lesson/the-interrelationships-between-stem-society.html" data-cname="article_link" className="external" target="_blank" rel="noopener noreferrer nofollow">society’s technological and scientific advancements.</a>&nbsp;The National Science Foundation maintains that students will need a strong foundation in STEM subjects in order to be successful in the information-driven age.</p>
                        <p>STEM education and the application of&nbsp;<a href="https://study.com/academy/lesson/empowering-students-with-technology.html" data-cname="article_link" className="external" target="_blank" rel="noopener noreferrer nofollow">technology empowers every student</a>&nbsp;to use their creativity and develop their critical thinking skills. The benefits of STEM education are also far-reaching for students of all ages and backgrounds as recent years have seen initiatives intended to add more females and minorities to STEM fields.</p>
                        <p><p >Students with ADHD and other similar issues can also experience&nbsp;<a href="https://study.com/blog/how-can-stem-education-benefit-kids-with-adhd.html" data-cname="article_link" className="external" target="_blank" rel="noopener noreferrer nofollow">STEM education benefits,</a>&nbsp;such as the ability to complete long, intricate tasks involving robotics or computer programming projects. STEM education tends to involve hands-on activities that require creative thinking, an environment which is well-suited for students with ADHD.</p></p>
                        <h3 className="text-center mb-5">STEM in Schools</h3>
                        <p>There are a variety of ways in which teachers can introduce STEM into the classroom. This&nbsp;<a href="https://study.com/academy/lesson/understanding-stem-education-lesson-plan.html" data-cname="article_link" className="external" target="_blank" rel="noopener noreferrer nofollow">sample STEM lesson plan</a>&nbsp;provides teachers with a step-by-step guide on how to properly introduce the concept to their students. It involves a video lesson then allows students to develop their own STEM lesson.</p>
                        <p>Once teachers introduce STEM education to their students, they can analyze their classroom and determine how STEM projects and activities can be incorporated into it.&nbsp;<a href="https://study.com/academy/lesson/stem-classroom-setup-ideas-for-teachers.html" data-cname="article_link" className="external" target="_blank" rel="noopener noreferrer nofollow">Classroom set-up for STEM classes</a>&nbsp;is key when it comes to implementing projects and activities. Implementation ideas include the following:</p>
                        <ul className="article__content__list">
                            <li className="list__item">Desks and other furniture should be arranged to allow students maximum flexibility when working on STEM projects and activities. This can mean large aisles between desks or creating workstations based on the particular project or activity.</li>
                            <li className="list__item">Collaboration is an important component of any STEM project or activity. The classroom should be arranged so that it enables students to work together, such as clusters of chairs.</li>
                            <li className="list__item">Visual supports, such as charts and posters, can be key in helping students remember key concepts.</li>
                        </ul>
                        <p>STEM projects and activities for elementary to middle school students can include sensory water bottles, a&nbsp;<a href="https://study.com/academy/lesson/bridge-building-project.html" data-cname="article_link" className="external" target="_blank" rel="noopener noreferrer nofollow">bridge building challenge,</a>&nbsp;or&nbsp;<a href="https://study.com/academy/lesson/1st-grade-volcano-science-projects.html" data-cname="article_link" className="external" target="_blank" rel="noopener noreferrer nofollow">building a volcano.</a></p>
                        <p >Teachers at the&nbsp;<a href="https://study.com/academy/lesson/stem-projects-for-high-school.html" data-cname="article_link" className="external" target="_blank" rel="noopener noreferrer nofollow">high school level</a>&nbsp;can implement projects that involve all of the STEM fields. One such project would be a water quality survey where students would utilize underwater robots to collect data from a body of water. Teachers have the option for students to design their own robots or utilize purchased kits.</p>
                        <div className="side-course-cta__main__text">Implement STEM in your class with&nbsp;<a href="https://study.com/academy/course/understanding-applying-stem-education-in-the-classroom.html" data-cname="side_cta_link" className="external" target="_blank" rel="noopener noreferrer nofollow">STEM lessons, instructional strategies, and teaching tools.</a></div>
                        <h4>Earn continuing education hours</h4>
                        <img className="cfbanner horizontal-logo" src={ImageAssets.banner.stemedu1} alt="forecastr logo" />

                        {/* <div className='stems_study_img'><img className="side-bar-info__main__icon__img" src="https://study.com/images/teacher-info-hub/icon-subjects-color.svg" /></div> */}
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default STEMEducation