import React from "react"

const CustomButton = ({ ...props }) => {
  return <div className={props.divClassName} style={props.divStyle}>
    <button className={props.className} onClick={props.onClick} disabled={props.disabled} data-toggle={props.dataToggle} data-target={props.dataTarget} aria-controls={props.ariaControls} aria-expanded={props.ariaExpanded} aria-label={props.ariaLabel}>
      {props.text}
    </button>
  </div>
}

export default CustomButton