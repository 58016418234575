import axios from "axios"
import { toast } from "react-toastify"
import { APIRoutes } from "../utils/BackendRoutes"
import { getErrorMessage } from "../utils/GetErrorMessage"
import setLoading from "../utils/LoadingManager"
import { ADD_PRODUCTS, DELETE_PRODUCTS, GET_PRODUCTS, GET_SINGLE_PRODUCT } from "./types"

export const addProduct = (body) => dispatch => {
  setLoading(true)
  axios.post(APIRoutes.ADD_PRODUCTS, body)
    .then(res => {
      dispatch({
        type: ADD_PRODUCTS,
        payload: res.data.data
      })
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const deleteProducts = (id, index) => dispatch => {
  setLoading(true)
  axios.delete(APIRoutes.DELETE_PRODUCTS + id)
    .then(res => {
      dispatch({
        type: DELETE_PRODUCTS,
        payload: { id: id, index: index }
      })
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const getProducts = (type, role) => dispatch => {
  setLoading(true)
  axios.get(APIRoutes.GET_PRODUCTS + type + '/' + role)
    .then(res => {
      dispatch({
        type: GET_PRODUCTS,
        payload: res.data.data
      })
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const getProduct = (id) => dispatch => {
  setLoading(true)
  axios.get(APIRoutes.GET_SINGLE_PRODUCT + id)
    .then(res => {
      dispatch({
        type: GET_SINGLE_PRODUCT,
        payload: res.data.data
      })
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}