import React, { useEffect } from 'react'
import '../css/ContactUs/ContactUs.css'
import '../css/common/Banner.css'
import Breadcrums from '../common/Banner'
// import { Card, Button } from 'react-bootstrap'
import { UIRoutes } from '../../utils/FrontendRoutes'
import '../css/pages/Aboutus.css'
import { ImageAssets } from '../../utils/ImageAssets'
import AOS from "aos";
import "aos/dist/aos.css";
import CustomButton from '../common/CustomButton'

const CouncelingPsycometric = ({ ...props }) => {
    useEffect(() => {
        AOS.init({
            duration: 1200
        });
        AOS.refresh();
    }, []);
    const redirectToAllProducts = () => {
        props.history(UIRoutes.PRODUCT_LIST)
    }
    return <>
        <section id="main" className="Smart-School Counceling clearfix" role="main">
            <Breadcrums divClassName="Counceling-banner main-banner"
                breadcrums={JSON.stringify([{ key: "Home ", value: UIRoutes.HOME }, { key: "Career Counceling with Psycometric Test", value: UIRoutes.COUNCELINGPSYCOMETRIC }])}
            />
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="contents section-header pb-3 mt-3 mb-1 my-sm-4" data-aos="zoom-in">
                            <h2 className="section-title  wow fadeIn animated" data-wow-delay="0.2s">Career Counceling with Psycometric Test</h2>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6" data-aos="zoom-out-left">
                        <img className="cfbanner horizontal-logo" src={ImageAssets.PRODUCTSSERVICES.PRODUCTSSERVICES9} alt="forecastr logo" />
                    </div>
                    <div className="col-lg-6" data-aos="zoom-out-right">
                        <h3 className="section-title" >What is Psychometric Test? </h3>
                        <ul>
                            <p className='text-dark'> Psychometric Assessments are tests that are used to
                                objectively measure an Individual’s Personality Traits,
                                Intelligence, Abilities, Behavioral Style and Aptitude.
                                Psychometric Assessments are widely used in Career Guidance
                                and Employment to match a person’s abilities and personality
                                to a suitable career or role. Validity, reliability, and norming are
                                essential elements to keep in mind when determining the quality
                                of any Psychometric Test.</p>
                            <p className='text-dark'>Psychometric Assessments, also referred to as Psychometric
                                Tests, measure an individual on two Core Skills – Personality
                                and Ability. With organizations being extra vigilant about who to
                                hire, Psychometric Assessments have become a common
                                practice in the hiring stage to gauge fitment and interest.
                                Psychometric tests can be leveraged to impact every aspect of
                                an employee’s life cycle, starting with Recruitment, Training,
                                and Leadership Development.</p>
                        </ul>
                        <div className='buynow-home-product'>
                            <p className='text-danger'><del><small>23600/- <i className="fas fa-rupee-sign"></i></small></del>&nbsp;&nbsp;<strong className='pl-5'>17700/- <i className="fas fa-rupee-sign"></i></strong></p>
                            <CustomButton
                                divClassName=''
                                className='btn blue-button w-100 font-weight-bold mt-2'
                                onClick={redirectToAllProducts}
                                text='Buy Now'
                            />
                        </div>
                    </div>


                </div>


            </div>
        </section>
    </>
}

export default CouncelingPsycometric 