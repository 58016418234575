import React, { useEffect } from 'react'
import '../css/ContactUs/ContactUs.css'
import '../css/common/Banner.css'
import Breadcrums from '../common/Banner'
import { Card, Button } from 'react-bootstrap'
import { UIRoutes } from '../../utils/FrontendRoutes'
import '../css/pages/Aboutus.css'
import { ImageAssets } from '../../utils/ImageAssets'
import AOS from "aos";
import "aos/dist/aos.css";

const Winwhitelabel = () => {
    useEffect(() => {
        AOS.init({
            duration: 1200
        });
        AOS.refresh();
    }, []);
    return <>
        <section id="main" className="Smart-School winwhitelable clearfix" role="main">
            <Breadcrums divClassName="winwhitelable-banner main-banner"
                breadcrums={JSON.stringify([{ key: "Home ", value: UIRoutes.HOME }, { key: "Win-Win White Label App solution for school.", value: UIRoutes.WINWHITELABEL }])}
            />
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="contents section-header pb-3 mt-3 mb-1 my-sm-4" data-aos="zoom-in">
                            <h2 className="section-title  wow fadeIn animated" data-wow-delay="0.2s">Win-Win White Label App solution for school. </h2>
                        </div>
                        {/* <h3 className="text-center mb-4 mt-4">Win-Win Whitelabel App Solution</h3> */}
                    </div>
                </div>
                <div className="row">

                    <div className="col-lg-6" data-aos="zoom-out-left">
                        <h3 className="text-center mb-4 mt-4">Amazing Features</h3>
                        <ul>
                            <li>3D/4D (Anchor along with 3D based) animated modules </li>
                            <li>Ask a Doubt Feature </li>
                            <li>Choose a Book Feature </li>
                            <li>Bilingual Feature (Hindi & English ) </li>
                            <li>Quizzes with time limit and review options, Exams with AIR Rank Feature </li>
                            <li>History Timeline and Great personalities </li>
                            <li>Complete Usage Report Tracking </li>
                            <li>Worksheets in Printable Format, Sample Papers, HOTS questions </li>
                            <li>Interactive Games</li>
                            <li>Previous Year Question papers of Olympiad</li>
                            <li>Special Learning Zone for Dyslexia Students</li>
                            <li>General Knowledge section</li>
                            <li>Real Life Videos</li>
                            <li>Science Lab (Science Experiments)</li>
                            <li>Formula	Master (Maths Formula)</li>
                            <li>Fully Solved NCERT Book</li>
                            <li>Scholar Zone (IIT Foundation Questions)</li>
                        </ul>
                    </div>
                    <div className="col-lg-6" data-aos="zoom-out-right">
                        <img width="1280" height="855" src={ImageAssets.whiteapp.app5}
                            className='about-us-img' alt="" loading="lazy" />
                    </div>

                </div>
                <div className="row">
                    <div className="col-lg-6" data-aos="zoom-out-left">
                        <img width="100%" height="155px" src={ImageAssets.whiteapp.app3}
                            className='about-us-img' alt="" loading="lazy" />
                    </div>
                    <div className="col-lg-5" data-aos="zoom-out-right">
                        <h3 className="text-center mb-4 mt-4">App Benefits</h3>
                        <ul>
                            <li>Ed-Tech is nothing but Promoting The Habit of Self-Study through the power of  visualization, thereby reducing the dependence on tuitions  </li>
                            <li>Power of visualization leads to better Conceptual Clarity and reduced cramming  </li>
                            <li>Through movies, cartoons, games – Increase of Interest + Time Saving          </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-5" data-aos="zoom-out-left">
                        <h3 className="text-center mb-4 mt-4">How It Works?</h3>
                        <ul>
                            <li>Ed-Tech App of your school’s brand and uploaded on play store by your school name</li>
                            <li>Content as per school’s curriculum</li>
                            <li>Monitoring - Complete usage tracking and weak area identification of students</li>
                            <li>Bundled Smart Class content for no extra cost</li>
                            <li>Teaching platform to conduct Live classes, Track attendance, Fee collection, Notifications</li>
                            <li>Bilingual content + Content can run both online as well as offline</li>
                            <li>No Technical Team + No Support Team + No Hosting Charges Required From School                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-6" data-aos="zoom-out-right">
                        <img width="1280" height="855" src={ImageAssets.whiteapp.app2}
                            className='about-us-img' alt="" loading="lazy" />
                    </div>

                </div>
                <div className="row mt-5">
                    <div className="col-lg-6" data-aos="zoom-out-right">
                        <img width="1280" height="855" src={ImageAssets.whiteapp.app6}
                            className='about-us-img' alt="" loading="lazy" />
                        <img width="1280" height="855" src={ImageAssets.whiteapp.app7}
                            className='about-us-img' alt="" loading="lazy" />
                    </div>
                    <div className="col-lg-5" data-aos="zoom-out-left">
                        <h3 className="text-center mb-4 mt-4">Benefits to School</h3>
                        <ul>
                            <li>Whitelabel App will be a differentiating factor for the school. The branding of the  school will reach to the next level. How many schools provide anything like this?</li>
                            <li>Highlight this differentiating factor in your prospectus which will increase admissions.</li>
                            <li>We are the only content provider with proprietary “Choose a book” feature. Now  content will be mapped exactly as per what teachers would be teaching in the  classrooms.</li>
                            <li>Tracking Of  “Content Usage”  and “Improvement  Area Identification”  features. Complete  usage report of  students is visible to  Teachers, Admin  and Principal.</li>
                            <li>App will Boost Memorising Power of students thereby scores will improve which will  further increase the brand value of the school resulting in less attrition.</li>
                            <li>This App is priced to the school at a very subsidised rate so even after mark-up, cost of the App will be a very good offer for the parents as compared to other Ed-Tech Apps.</li>
                            <li>Whitelabel App is a Bundled Solution, hence now School have No Additional Cost for the smart class content and for the teaching platform.</li>
                            <li>Fee Tracking & Collection, monitoring dashboard and a Payment Gateway for online  payments is also integrated in this. Hence, better fee collection can take place.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default Winwhitelabel