import React from 'react'
import '../css/ContactUs/ContactUs.css'
import '../css/common/Banner.css'
import Breadcrums from '../common/Banner'
import { UIRoutes } from '../../utils/FrontendRoutes'
import ImageAssets from '../../utils/ImageAssets'


const AICRI = () => {
  return <>
    <section id="main" className="AICRI clearfix" role="main">
      <Breadcrums divClassName="aicra-banner main-banner"
        breadcrums={JSON.stringify([{ key: "Home ", value: UIRoutes.HOME }, { key: "AICRA", value: UIRoutes.AICRI }])}
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
          <div className="contents section-header pb-3 mt-3 mb-1 my-sm-4">
                            <h2 className="section-title wow fadeIn animated" data-wow-delay="0.2s"> All India Council for Robotics &amp; Automation
              (AICRA) </h2>
              {/* <h2 className="section-title wow fadeIn animated" data-wow-delay="0.2s"> (Gujarat State Co-ordinator): </h2> */}
              <h3 className="text-center"><strong>(Gujarat State Co-ordinator) </strong></h3> 
              
                        </div>
            {/* <h3 className="text-center mt-3"><strong>All India Council for Robotics &amp; Automation
              (AICRA) </strong></h3>
            // <h3 className="text-center"><strong>(Gujarat State Co-ordinator): </strong></h3> */}
            <p>&nbsp; </p>
            <p className="text-center">All India Council For Robotics &amp; Automation (AICRA)
              Is a Leading, global and non-profit organization that is setting the standard for
              robotics and automation by helping over 35,000 worldwide members and other
              professionals to solve difficult technical problems, while enhancing their
              leadership and personal career capabilities.It aims to create awareness about
              upcoming new technologies in the field of automation and robotics, which leads to
              new career opportunities for the students of school and colleges by way of
              organizing workshops and competitive events (TechnoXian) at regional, national and
              international level in co-ordination with Ministry of science and technologies,
              Govt.Of India.</p>
          </div>
          <div className="col-lg-8 mx-auto mb-5">
            <img className="cfbanner horizontal-logo" src={ImageAssets.banner.aicrabanner} alt="forecastr logo" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <h3 className="text-center mb-5">Workshop Technologies (26 Technologies)</h3>
          </div>
        </div>
        <div className="row hidden-md-up">
          <div className="col-md-4 mb-2 mb-sm-4">
            <div className="card h-100">
              <div className=" card-block p-sm-4 p-4">
                <p><strong>1. Wireless Robotics</strong></p>
                <p>Embedding knowledge of electronics, circuit designing &amp; creating wireless robot
                </p>
                <p><strong><u>Duration: </u></strong>&nbsp; <img draggable="false" className="emoji"
                  alt="➡" src={ImageAssets.Arrow.Arrow} /> 8hrs</p>
                <p><strong><u>Level: </u></strong>&nbsp; &nbsp; &nbsp; &nbsp; <img draggable="false"
                  className="emoji" alt="➡" src={ImageAssets.Arrow.Arrow} />
                  Basic</p>
                <p>Eligibility For Students: &nbsp; <img draggable="false" className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} /> Class 6
                  Onwards</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2 mb-sm-4">
            <div className="card h-100">
              <div className=" card-block p-sm-4 p-4">
                <p><strong>2. Autonomous Robotics</strong></p>
                <p>For beginners to go step ahead in Microcontroller level programming.</p>
                <p><strong><u>Duration: </u></strong>&nbsp; <img draggable="false" className="emoji"
                  alt="➡" src={ImageAssets.Arrow.Arrow} /> 8hrs</p>
                <p><strong><u>Level: </u></strong>&nbsp; &nbsp; &nbsp; &nbsp; <img draggable="false"
                  className="emoji" alt="➡" src={ImageAssets.Arrow.Arrow} />
                  Intermediate</p>
                <p>Eligibility For Students: &nbsp; <img draggable="false" className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} /> Class 10
                  Onwards</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2 mb-sm-4">
            <div className="card h-100">
              <div className=" card-block p-sm-4 p-4">
                <p><strong>3. Cell Phone controlled Robotics</strong></p>
                <p>For beginners having basic understanding of hardware programming and to go step ahead
                  in Microcontroller level programming.</p>
                <p><strong><u>Duration: </u></strong>&nbsp; <img draggable="false" className="emoji"
                  alt="➡" src={ImageAssets.Arrow.Arrow} /> 8hrs</p>
                <p><strong><u>Level: </u></strong>&nbsp; &nbsp; &nbsp; &nbsp; <img draggable="false"
                  className="emoji" alt="➡" src={ImageAssets.Arrow.Arrow} />
                  Intermediate</p>
                <p><strong><u>Eligibility For Students&nbsp;: </u></strong> <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} /> Class 10 Onwards
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2 mb-sm-4">
            <div className="card h-100">
              <div className=" card-block p-sm-4 p-4">
                <p><strong>4. Bluetooth Controlled Robotics</strong></p>
                <p>Learning USART for enhancing the Embedded C Programming Skills &amp; Advance
                  knowledge about register level knowledge of Micro controller.Can do more USART
                  programs like using Xbee, RF Module</p>
                <p><strong><u>Duration: </u></strong>&nbsp; <img draggable="false" className="emoji"
                  alt="➡" src={ImageAssets.Arrow.Arrow} />12hrs</p>
                <p><strong><u>Level: </u></strong>&nbsp; &nbsp; &nbsp; &nbsp; <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Advance</p>
                <p><strong><u>Eligibility For Students&nbsp;: </u></strong> <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Class 10 Onwards</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2 mb-sm-4">
            <div className="card h-100">
              <div className=" card-block p-sm-4 p-4">
                <p><strong>5. Arduino</strong></p>
                <p>Learn&nbsp; Open Source ARDUINO with unique Arduino Programming, developing sketch
                  as its quite easy Make Big Projects</p>
                <p><strong><u>Duration: </u></strong>&nbsp; <img draggable="false" className="emoji"
                  alt="➡" src={ImageAssets.Arrow.Arrow} />8hrs</p>
                <p><strong><u>Level: </u></strong>&nbsp; &nbsp; &nbsp; &nbsp; <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Basic</p>
                <p><strong><u>Eligibility For Students&nbsp;: </u></strong> <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Class 10 Onwards</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2 mb-sm-4">
            <div className="card h-100">
              <div className=" card-block p-sm-4 p-4">
                <p><strong>6. Raspberry Pi</strong></p>
                <p>Raspberry Pi is next generation smallest computer; learn it to have access to its
                  GPIO &amp; controlling its environment with sensor.Programming language PYTHON
                  &amp; platform is Raspbian.</p>
                <p><strong><u>Duration: </u></strong>&nbsp; <img draggable="false" className="emoji"
                  alt="➡" src={ImageAssets.Arrow.Arrow} />8hrs</p>
                <p><strong><u>Level: </u></strong>&nbsp; &nbsp; &nbsp; &nbsp; <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Advance</p>
                <p><strong><u>Eligibility For Students&nbsp;: </u></strong> <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Class 12 Onwards</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2 mb-sm-4">
            <div className="card h-100">
              <div className=" card-block p-sm-4 p-4">
                <p><strong>7. VLSI</strong></p>
                <p>Very-Large-Scale Integration (VLSI) is the process of creating an Integrated Circuit
                  (IC) by combining thousands of transistors into a single chip<br />
                  <strong><u>Duration: </u></strong>&nbsp; <img draggable="false" className="emoji"
                    alt="➡" src={ImageAssets.Arrow.Arrow} />12 hrs</p>
                <p><strong><u>Level: </u></strong>&nbsp; &nbsp; &nbsp; &nbsp; <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Advance</p>
                <p><strong><u>Eligibility For Students&nbsp;: </u></strong> <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />College</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2 mb-sm-4">
            <div className="card h-100">
              <div className=" card-block p-sm-4 p-4">
                <p><strong>8. Quad Copter/ UAV</strong></p>
                <p>Programming for the Quad Copter Flight Stabilization Board &amp; in the Filming
                  Videography, Photography</p>
                <p><strong><u>Duration: </u></strong>&nbsp; <img draggable="false" className="emoji"
                  alt="➡" src={ImageAssets.Arrow.Arrow} />8hrs</p>
                <p><strong><u>Level: </u></strong>&nbsp; &nbsp; &nbsp; &nbsp; <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Advance</p>
                <p><strong><u>Eligibility For Students: &nbsp; </u></strong> <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />College</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2 mb-sm-4">
            <div className="card h-100">
              <div className=" card-block p-sm-4 p-4">
                <p><strong>9. Auto Cad</strong></p>
                <p>AutoCAD Software tool for Drawing Purpose like: Command, Design Precision Etc.</p>
                <p><strong><u>Duration: </u></strong>&nbsp; <img draggable="false" className="emoji"
                  alt="➡" src={ImageAssets.Arrow.Arrow} />8hrs</p>
                <p><strong><u>Level: </u></strong>&nbsp; &nbsp; &nbsp; &nbsp; <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Advance</p>
                <p><strong><u>Eligibility For Students: &nbsp; </u></strong> <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />College</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2 mb-sm-4">
            <div className="card h-100">
              <div className=" card-block p-sm-4 p-4">
                <p><strong>10. 3D max</strong></p>
                <p>You can learn about 3D Designing Drawing, Modeling, Design Interface and Layout</p>
                <p><strong><u>Duration: </u></strong>&nbsp; <img draggable="false" className="emoji"
                  alt="➡" src={ImageAssets.Arrow.Arrow} />8hrs</p>
                <p><strong><u>Level: </u></strong>&nbsp; &nbsp; &nbsp; &nbsp; <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Advance</p>
                <p><strong><u>Eligibility For Students&nbsp;: </u></strong> <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />College</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2 mb-sm-4">
            <div className="card h-100">
              <div className=" card-block p-sm-4 p-4">
                <p><strong>11. Android Apps Development</strong></p>
                <p>Getting familiar to Android App Development &amp; learn to create multiple variants of an android APP</p>
                <p><strong><u>Duration: </u></strong>&nbsp; <img draggable="false" className="emoji"
                  alt="➡" src={ImageAssets.Arrow.Arrow} />8hrs</p>
                <p><strong><u>Level: </u></strong>&nbsp; &nbsp; &nbsp; &nbsp; <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Intermediate</p>
                <p><strong><u>Eligibility For Students: </u></strong> <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Class 10 Onwards</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2 mb-sm-4">
            <div className="card h-100">
              <div className=" card-block p-sm-4 p-4">
                <p><strong>12. Ethical Hacking</strong></p>
                <p>Learn how to secure out data form hackers, like Fake Page Hacking, Sql Injection,
                  Wi-Fi Hacking, Information Security</p>
                <p><strong><u>Duration: </u></strong>&nbsp; <img draggable="false" className="emoji"
                  alt="➡" src={ImageAssets.Arrow.Arrow} />8hrs</p>
                <p><strong><u>Level: </u></strong>&nbsp; &nbsp; &nbsp; &nbsp; <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Basic</p>
                <p><strong><u>Eligibility For Students&nbsp;: </u></strong> <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Class 6 Onwards</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2 mb-sm-4">
            <div className="card h-100">
              <div className=" card-block p-sm-4 p-4">
                <p><strong>13. Digital Marketing</strong></p>
                <p>Learn Social Media Marketing, Email Marketing, PR &amp; Online Reputation Management
                </p>
                <p><strong><u>Duration: </u></strong>&nbsp; <img draggable="false" className="emoji"
                  alt="➡" src={ImageAssets.Arrow.Arrow} />8hrs</p>
                <p><strong><u>Level: </u></strong>&nbsp; &nbsp; &nbsp; &nbsp; <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Advance</p>
                <p><strong><u>Eligibility For Students: &nbsp; </u></strong> <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Class 12 Onwards</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2 mb-sm-4">
            <div className="card h-100">
              <div className=" card-block p-sm-4 p-4">
                <p><strong>14. Auto Mechanics</strong></p>
                <p>Learn about IC Engine, 4 Stroke / 2 Stroke working internally.Practical working of
                  Engines &amp; Assembling dissembling of engines</p>
                <p><strong><u>Duration: </u></strong>&nbsp; <img draggable="false" className="emoji"
                  alt="➡" src={ImageAssets.Arrow.Arrow} />6hrs</p>
                <p><strong><u>Level: </u></strong>&nbsp; &nbsp; &nbsp; &nbsp; <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Advance</p>
                <p><strong><u>Eligibility For Students&nbsp;: </u></strong> <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />College</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2 mb-sm-4">
            <div className="card h-100">
              <div className=" card-block p-sm-4 p-4">
                <p><strong>15. Catia</strong></p>
                <p>Learn how to design your own models or one which your industry needs.Designing tool
                  to solve all designing queries.</p>
                <p><strong><u>Duration: </u></strong>&nbsp; <img draggable="false" className="emoji"
                  alt="➡" src={ImageAssets.Arrow.Arrow} />8hrs</p>
                <p><strong><u>Level: </u></strong>&nbsp; &nbsp; &nbsp; &nbsp; <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Advance</p>
                <p><strong><u>Eligibility For Students&nbsp;: </u></strong> <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />College</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2 mb-sm-4">
            <div className="card h-100">
              <div className=" card-block p-sm-4 p-4">
                <p><strong>16. Hand gesture Robotics</strong></p>
                <p>Learning ADC &amp; Interfacing with LCD for enhancing the Embedded C Programming
                  Skills &amp; Advance knowledge about register level knowledge of Microcontroller.
                  Can do more ADC programs like Temperature sensor &amp; other analog sensors.</p>
                <p><strong><u>Duration: </u></strong>&nbsp; <img draggable="false" className="emoji"
                  alt="➡" src={ImageAssets.Arrow.Arrow} />12hrs</p>
                <p><strong><u>Level: </u></strong>&nbsp; &nbsp; &nbsp; &nbsp; <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Advance</p>
                <p><strong><u>Eligibility For Students&nbsp;: </u></strong> <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Class 12 Onwards</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2 mb-sm-4">
            <div className="card h-100">
              <div className=" card-block p-sm-4 p-4">
                <p><strong>17. MATLAB</strong></p>
                <p>Learning MATLAB tools like: Image Processing tool, &amp; how to Use in Automation
                  Field</p>
                <p><strong><u>Duration: </u></strong>&nbsp; <img draggable="false" className="emoji"
                  alt="➡" src={ImageAssets.Arrow.Arrow} />8hrs</p>
                <p><strong><u>Level: </u></strong>&nbsp; &nbsp; &nbsp; &nbsp; <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Advance</p>
                <p><strong><u>Eligibility For Students&nbsp;: </u></strong> <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />College</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2 mb-sm-4">
            <div className="card h-100">
              <div className=" card-block p-sm-4 p-4">
                <p><strong>18. MATLAB with Robotics</strong></p>
                <p>Learning MATLAB software Toll &amp; How to Interface with Embedded System Boards,
                  Make Creative Project&nbsp; with the Help of MATLAB</p>
                <p><strong><u>Duration: </u></strong>&nbsp; <img draggable="false" className="emoji"
                  alt="➡" src={ImageAssets.Arrow.Arrow} />8hrs</p>
                <p><strong><u>Level: </u></strong>&nbsp; &nbsp; &nbsp; &nbsp; <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Advance</p>
                <p><strong><u>Eligibility For Students&nbsp;: </u></strong> <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />College</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2 mb-sm-4">
            <div className="card h-100">
              <div className=" card-block p-sm-4 p-4">
                <p><strong>19. Solid Works</strong></p>
                <p>Learn 2D Sketching, layout Interface, Modeling, Part Designing<strong>&nbsp; &nbsp;
                  &nbsp; &nbsp; </strong></p>
                <p><strong><u>Duration: </u></strong>&nbsp; <img draggable="false" className="emoji"
                  alt="➡" src={ImageAssets.Arrow.Arrow} />8hrs</p>
                <p><strong><u>Level: </u></strong>&nbsp; &nbsp; &nbsp; &nbsp; <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Advance</p>
                <p><strong><u>Eligibility For Students&nbsp;: </u></strong> <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />College</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2 mb-sm-4">
            <div className="card h-100">
              <div className=" card-block p-sm-4 p-4">
                <p><strong>20. X bee Controlled Robotics</strong></p>
                <p>Learning USART for enhancing the Embedded C Programming Skills &amp; Advance
                  knowledge about register level knowledge of Microcontroller.Can do more USART
                  programs like using Xbee, RF Module</p>
                <p><strong><u>Duration: </u></strong>&nbsp; <img draggable="false" className="emoji"
                  alt="➡" src={ImageAssets.Arrow.Arrow} />8hrs</p>
                <p><strong><u>Level: </u></strong>&nbsp; &nbsp; &nbsp; &nbsp; <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Advance</p>
                <p><strong><u>Eligibility For Students: </u></strong> <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />College</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2 mb-sm-4">
            <div className="card h-100">
              <div className=" card-block p-sm-4 p-4">
                <p><strong>21. PYTHON PROGRAMMING</strong></p>
                <p>Learning basic of Python, Loops, Data Types, Classes, Function Decorates &amp;
                  Generators.</p>
                <p><strong><u>Duration: </u></strong>&nbsp; <img draggable="false" className="emoji"
                  alt="➡" src={ImageAssets.Arrow.Arrow} />8hrs</p>
                <p><strong><u>Level: </u></strong>&nbsp; &nbsp; &nbsp; &nbsp; <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Advance</p>
                <p><strong><u>Eligibility For Students&nbsp;: </u></strong> <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />College</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2 mb-sm-4">
            <div className="card h-100">
              <div className=" card-block p-sm-4 p-4">
                <p><strong>22. AI &amp; MACHINE LEARNING</strong></p>
                <p>Introduction to AI, regression, classification, neural network.</p>
                <p><strong><u>Duration: </u></strong>&nbsp; <img draggable="false" className="emoji"
                  alt="➡" src={ImageAssets.Arrow.Arrow} />12hrs</p>
                <p><strong><u>Level: </u></strong>&nbsp; &nbsp; &nbsp; &nbsp; <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Advance</p>
                <p><strong><u>Eligibility For Students&nbsp;: </u></strong> <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />College</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2 mb-sm-4">
            <div className="card h-100">
              <div className=" card-block p-sm-4 p-4">
                <p><strong>23. IoT WITH NODE MCU</strong></p>
                <p>Learning concept of IoT, wireless embedded system, project development using node
                  MCU.</p>
                <p><strong><u>Duration: </u></strong>&nbsp; <img draggable="false" className="emoji"
                  alt="➡" src={ImageAssets.Arrow.Arrow} />8hrs</p>
                <p><strong><u>Level: </u></strong>&nbsp; &nbsp; &nbsp; &nbsp; <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Advance</p>
                <p><strong><u>Eligibility For Students&nbsp;: </u></strong> <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />College</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2 mb-sm-4">
            <div className="card h-100">
              <div className=" card-block p-sm-4 p-4">
                <p><strong>24. Big data &amp; Hadoop</strong></p>
                <p>Learning Big Data, data generation source, introduction to Apache Hadoop, Load unload
                  datasets</p>
                <p><strong><u>Duration: </u></strong>&nbsp; <img draggable="false" className="emoji"
                  alt="➡" src={ImageAssets.Arrow.Arrow} />12hrs</p>
                <p><strong><u>Level: </u></strong>&nbsp; &nbsp; &nbsp; &nbsp; <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Advance</p>
                <p><strong><u>Eligibility For Students&nbsp;: </u></strong> <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />College</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2 mb-sm-4">
            <div className="card h-100">
              <div className=" card-block p-sm-4 p-4">
                <p><strong>25. Cloud Computing</strong></p>
                <p>Learn cloud service model, deployment model of cloud computing, Infrastructure as a Service (IaaS), Platform as a Service (PaaS) and Software as a Service (SaaS)</p>
                <p><strong><u>Duration: </u></strong>&nbsp; <img draggable="false" className="emoji"
                  alt="➡" src={ImageAssets.Arrow.Arrow} />8hrs</p>
                <p><strong><u>Level: </u></strong>&nbsp; &nbsp; &nbsp; &nbsp; <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Advance</p>
                <p><strong><u>Eligibility For Students&nbsp;: </u></strong> <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />College</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2 mb-sm-4">
            <div className="card h-100">
              <div className=" card-block p-sm-4 p-4">
                <p><strong>26. Data Science</strong></p>
                <p>Learning NLP, decision tree, Apache spark Mlib, Using Python in data science</p>
                <p><strong><u>Duration: </u></strong>&nbsp; <img draggable="false" className="emoji"
                  alt="➡" src={ImageAssets.Arrow.Arrow} />12hrs</p>
                <p><strong><u>Level: </u></strong>&nbsp; &nbsp; &nbsp; &nbsp; <img draggable="false"
                  className="emoji" alt="➡"
                  src={ImageAssets.Arrow.Arrow} />Advance</p>

              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="wpb_wrapper">
              <h3 className="text-center"><strong><u>NxR Robotics Lab</u></strong></h3>
              <p><strong>“NxR Generation Robotics Lab (NxR)”</strong>&nbsp; known as AICRA Certified
                Robotics Lab Center.The concept established with the motto of “Creating an
                eco-system where learners become inspired by Robotics &amp; Automation Technology,
                Imbibe Technology and get exposure to international competitions.We have conquered
                short term instructional courses, and now we are ready to look beyond it.</p>
              <p>Robo Lab mixes the creativity and curiosity of young students with the hands-on
                education aspects of Science &amp; Technology.With focus on Robot building and
                Science based activities, our learning methodology takes up innovative and
                challenging ideas and converts them into solutions in the form of working robots or
                engaging science experiments.</p>
              <h4><u>Type of NxR Lab</u></h4>
              <ol>
                <li><strong>Instrumentation Section</strong></li>
                <li><strong>Mechanical Section</strong></li>
                <li><strong>Electronics Section</strong></li>
                <li><strong>Robotics Section</strong></li>
                <li><strong>Software Section</strong></li>
                <li><strong>UAV Section</strong></li>
                <li><strong>3D Printing Section</strong></li>
                <li><strong>Individual Kits</strong></li>
                <li><strong>In-house Training</strong></li>
                <li><strong>Project based curriculum</strong></li>
                <li><strong>Certification</strong></li>
                <li><strong>Competitions focused projects</strong></li>
                <li><strong>Revenue Sharing for Institute</strong></li>
              </ol>
              <p><strong>&nbsp; </strong></p>
              <h4><u>NxR Lab Features: </u></h4>
              <p>Institute can add-on Robotics as subject for its entire student’ studying Science,
                Technology, Engineering and Mathematics ISTEM).Low cost annual membership fee can
                be added in regular fee structure.All membership students would be availing following
                benefits throughout year: </p>
              <ul>
                <li><strong>In-house training</strong>: &nbsp; Certified trainer would visit institute
                  campus as per mutually decided schedule.</li>
                <li><strong>Study Materials: </strong>Comprehensive content developed with support of
                  eDC – IIT Delhi and AICRA</li>
                <li><strong>Equipment and Kits: </strong>Robotics Lab will be equipped with latest
                  components and instruments.Each member will be provided advance kit
                  individually to develop innovative projects</li>
                <li><strong>Industry Visit: </strong>Best performing learners will be getting
                  exposure to automation industry visit once in a year.</li>
                <li><strong>TTT Program: </strong>Project based training option can be opted for
                  institute faculties.When and if required.</li>
                <li><strong>Online &amp; Telephonic Support: </strong>All time support available for
                  faculties who have gone through TTT Program throughout the year.</li>
                <li><strong>Free Seminar: </strong>Twice in a year free seminar on updated technology
                  in robotics field.</li>
                <li><strong>International Competition Preparation: </strong>Training and guidance to
                  prepare learners to participate in International level of Robotics Competitions
                  to represent institute.</li>
                <li><strong>Certification: </strong>Progressive certification (O, A and B Level) from
                  Council for Robotics &amp; Automation (AICRA)</li>
              </ul>
              <p><strong><u>Associate Service Providers: </u></strong></p>
              <p>To ensure best of the services for NxR Lab, AICRA certify companies and vendors to
                deliver and execute all service operations as per the standards and guidelines of
                AICRA.Following are the associate companies to support NxR Lab: </p>
              <ul>
                <li><strong>TIMTS</strong>: NxR Lab requires trained and certified trainers to
                  deliver standard modules.TIMTS ensures experts to be hired from industry, their
                  certification from AICRA and making them available for regular training at NxR
                  Lab.</li>
                <li><strong>RoboHaat</strong>: NxR Lab requires genuine kits (designed specifically
                  for NxR Lab) and components as per the standard of AICRA.RoboHaat ensures
                  material arrangement at the most affordable cost duly approved by AICRA.</li>
              </ul>
              <h3 className="text-center"><u>Accreditation</u></h3>
              <ul>
                <li>The Council for Robotics &amp; Automation (AICRA) is a specialized accreditor
                  for robotics technology certification.The primary purpose of AICRA
                  accreditation is to encourage and recognize the attainment of certain
                  professional goals and standards for robotics technology and to encourage
                  continuous quality improvement through a voluntary and comprehensive evaluation
                  process.</li>
                <li>AICRA’s current scope of accreditation is to accredit certificate programs in
                  Robotics &amp; Automation Technology, artificial intelligence, and Internet of
                  Things (IoT) technology-related disciplines delivered at national or regional
                  accredited institutions across India.AICRA currently accredits a total of 13
                  certificate programs.AICRA also accredits the Autonomous Institutes and Clubs
                  System at the national level, with review of all programs offered by them.</li>
                <li>In addition to it, AICRA also provides a collaborative forum and collective
                  voice for the community of organizations in India that assess the quality of
                  specialized and professional higher education programs and schools.AICRA
                  operates to ensure that students in educational programs receive an education
                  consistent with standards for entry to practice or advanced practice in each of
                  their respective fields or disciplines.</li>
                <li>At the sole discretion of the AICRA, initial accreditation is granted for a
                  two-year period.Programs must submit to a comprehensive re-evaluation for
                  reaccreditation which, at the sole discretion of the AICRA Board of
                  Accreditation, is granted for a four-year period.Under certain circumstances,
                  the AICRA Board may require either a report or a visit during the accreditation
                  period.Only programs that are accredited and reaccredited by AICRA are
                  permitted to use the AICRA Accreditation logo in publications, displays and on
                  websites according to very specific guidelines.To verify the current
                  accreditation status of a program, please refer to the published list of AICRA
                  Accredited Programs on our website or contact the AICRA Accreditation Office.
                </li>
              </ul>
              <h3><u>Type of Accreditation</u></h3>
              <p>Under the Scheme, Colleges, Universities, Schools, Training institutes/ organizations
                in the non – formal sector, subject to meeting well-defined norms and criteria, are
                granted accreditation for conducting following specified levels of programs and
                conducting examinations under AICRA guidelines.</p>
              <ul>
                <li><strong>AICRA Certified Technology Associate (ACTA)</strong></li>
                <li><strong>AICRA Certified Technology Professional (ACTP)</strong></li>
                <li><strong>AICRA Certified Technology Expert (ACTE)</strong></li>
              </ul>
              <p><strong>All above programs required certification test conducted by AICRA.</strong>
              </p>
              <p>In addition, institutes will also be authorized to offer following certification
                programs to their students.</p>
              <ul>
                <li><strong>Advance Certificate in Embedded System</strong></li>
                <li><strong>Certificate in Embedded with 8051/8052</strong></li>
                <li><strong>Certificate in Embedded System with AVR</strong></li>
                <li><strong>Certificate in Embedded System with ARM</strong></li>
                <li><strong>Certificate in Embedded System with PIC</strong></li>
                <li><strong>Certificate in MATLAB with Robotics</strong></li>
                <li><strong>Certificate in Raspberry Pi</strong></li>
                <li><strong>Certificate in UAV Technology</strong></li>
                <li><strong>Certificate in Big Data &amp; Hadoop</strong></li>
                <li><strong>Certificate in Digital Marketing</strong></li>
                <li><strong>Certificate in Internet of Things (IoT)</strong></li>
                <li><strong>Certificate in Artificial Intelligence (AI Beginners)</strong></li>
                <li><strong>Advance Certificate in Artificial Intelligence</strong></li>
              </ul>
              <h4><u>Benefits of Accreditation</u></h4>
              <p>AICRA accreditation provides value to many: students, industry, faculty, academic
                institutions, and the public , to name a few.The AICRA Board of Accreditation, the
                visiting team members, and the leadership of AICRA itself are a diverse group of
                volunteers who represent a variety of academic and industrial institutions.Their
                valued perspective provides third-party confirmation of educational program quality,
                recognition by the profession, and the acceptability of transfer credits that is so
                important to students and parents.Academic institutions look for ways to
                distinguish themselves in their market while remaining true to their mission.
                Faculty is interested in identifying educational best practices that are underpinned
                by structured quality improvement capabilities.Industry needs access to top talent
                and a formal way to provide their input to the educational process because of its
                direct effect on professional practice.Public trust in the educational experience
                needs to be upheld.AICRA’s accreditation program meets all of these needs.</p>
              <ul>
                <li>Accreditation certifies quality and denotes high academic standards to students,
                  parents, employers, faculty, the administration, and the general public that
                  cannot be evaluated or verified in any other way.</li>
                <li>The accreditation by (AICRA) signifies the quality of our program in
                  relationship to other programs.</li>
                <li>Accreditation is most important to us in the area of student placement and the
                  development and maintenance of relationships with industry across India.</li>
                <li>The accreditation process provided us with a mechanism for the faculty to move
                  toward a common goal which included program development and improvement.</li>
                <li>Accreditation assures the student that quality is an important part of the
                  educational process.</li>
                <li>Accreditation has increased professional awareness and development among the
                  faculty</li>
                <li>Accreditation identifies the program as having met rigid standards, not only in
                  curriculum, but also in faculty knowledge and faculty experience in the
                  industrial field.</li>
                <li>We have noticed an improved placement record since our program was accredited by
                  (AICRA).</li>
                <li>Accreditation requires that the program curriculum be current and have high
                  standards, include all of the essential elements, and maintain a competitive
                  edge, so graduates can find employment compatible with their abilities and the
                  demands of industry</li>
                <li>Accreditation has permitted our program to attain a higher degree of acceptance
                  within our university and with the state higher education agency</li>
                <li>Accreditation has served as a catalyst to justify and obtain greater program
                  support from the university administration.</li>
                <li>Employers have selected the institution for recruitment because of our
                  accredited program.Accreditation has been very valuable in recruiting students
                  into our Industrial Technology program.</li>
                <li>Accreditation provided us with the opportunity for a searching self-review and
                  an external review, and it resulted in improvement in several areas
                  of course and curriculum development, outreach, and justification for increased
                  equipment allocations.</li>
              </ul>
              <p>&nbsp; </p>
              <h3 className="text-center"><strong><u>TECHNOXIAN</u></strong></h3>
              <p>A CREATIVE PLATFORM FOR YOU TO LEARN, EXPLORE AND SHARE YOUR KNOWLEDGE&nbsp;
                ON&nbsp; ROBOTICS &amp; AUTOMATION AT ONE PLATFORM WITH COORDINATION OF MINISTRY OF
                SCIENCE AND TECHNOLOGY, GOVERNMENT OF INDIA.</p>
              <ul>
                <li>In a nut shell, “we let you have fun as you learn!” We bring to you a global
                  opportunity to participate in different competitions related to innovation,
                  robotics, automation and many other fascinating challenge-based engineering
                  competitions.Be a part of our events, Build your work &amp; win prizes.</li>
                <li>TechnoXian organizes Workshops &amp; Trainings at different educational
                  institutions.Competitions are held on different zones for the students to be a
                  part.Grab a chance to be selected for the global championship on different
                  genres of engineering competitions.</li>
                <li>TechnoXian organizes Workshops &amp; Trainings at different educational
                  institutions.Competitions are held on different zones for the students to be a
                  part.Grab a chance to be selected for the global championship on different
                  genres of engineering competitions.</li>
              </ul>
              <p>&nbsp; </p>
              <h4><strong><u>PROGRAM CATEGORIES</u></strong></h4>
              <ul>
                <li><strong>International Robotics League (IRL)</strong></li>
              </ul>
              <p>We have realized the students who need employment in today’s competitive time, needs
                to do something beyond their Course curriculum thus the importance of the technology
                which we are being offered by us is of great benefit.To get feel of these
                technology we are conducting WRC on latest technologies which gives them peek a view
                to the latest in their field.A range of WRC will be conducted at TechnoXian venue,
                covering diverse topic.There would be industry/domain experts who would be sharing
                their knowledge and experience.Beneficial for all learners who wish to have deep
                insight of the 21st century and future technologies, these can be a great
                opportunity for them and never to be missed!</p>
              <ul>
                <li><strong>World Robotics Championship (WRC)</strong></li>
              </ul>
              <p>We have realized the students who need employable in today’s competitive time, needs
                to do something beyond their Course curriculum thus the importance of the technology
                which we are being offered by us is of great benefit.To get feel of these
                technology we are conducting WRC on latest technologies which gives them peek a view
                to the latest in their field.A range of WRC will be conducted at TechnoXian venue,
                covering diverse topic.There would be industry/domain experts who would be sharing
                their knowledge and experience.Beneficial for all learners who wish to have deep
                insight of the 21st century and future technologies, these can be a great
                opportunity for them and never to be missed!</p>
              <ul>
                <li><strong>District Championships</strong></li>
              </ul>
              <p><strong>Highlights: </strong></p>
              <ul>
                <li>181+ cities across India.Teams from schools and colleges would be competing
                  RoboRace and Fastest Line Follower Challenge at district level.</li>
                <li>Top team will be selected from each district who would represent their institute
                  for Pre- IRL in Regional Championships.</li>
                <li>All participants will get certification of Participation from &nbsp; “All India
                  Council for Robotics Automation (AICRA)”.</li>
                <li>Award worth INR 25,000/-* in India for each District Championship.</li>
                <li>AICRA would offer 1Yr Free membership to all registered participant</li>
                <li>Before Competition,&nbsp; Free 1 Day workshop&nbsp; on respective Technologies
                </li>
                <li>All registered participant will get &nbsp; free TechnoXian T-shirt.</li>
                <li>All registered participant will get TechnoXian’19 Grand Finale entry pass free
                  to see all activities venue.</li>
              </ul>
              <p>TechnoXian selected eight challenges for District Level of Competitions.Institute
                may select any of the challenge.TechnoXian team would provide initial workshop to
                learn technology.It aims to develop more and more champions across to represent
                their institute at International level of championships</p>
              <ul>
                <li><strong>ROBORACE CHALLENGE</strong></li>
                <li><strong>ROBOSOCCER CHALLENGE</strong></li>
                <li><strong>MINI ROBOWAR CHALLENGE</strong></li>
                <li><strong>RC CRAFT CHALLENGE</strong></li>
                <li><strong>QUADCOPTER CHALLENGE</strong></li>
                <li><strong>FATEST LINE FOLLOWER CHALLENGE</strong></li>
                <li><strong>MICROMOUSE CHALLENGE</strong></li>
                <li><strong>WATER ROCKET CHALLENGE</strong></li>
              </ul>
              <p><strong>&nbsp; </strong></p>
              <ul>
                <li>
                  <h4><strong>International Chapter</strong></h4>
                </li>
              </ul>
              <p><strong>The competition as scheduled offers below: </strong></p>
              <ul>
                <li>Workshop on various new technologies.</li>
                <li>International robotics league.</li>
                <li>Promotion of Institution.</li>
                <li>Access to workshop and training kit.</li>
                <li>Certification from &nbsp; Council for Robotics &amp; Automation.</li>
                <li>Benefits to a Gold or Platinum Partner, valid for a year.</li>
                <li>Great potential for annual earning.</li>
              </ul>
              <p>Here we’d like to invite you to become our business partner for your country.Be
                TechnoXian’s International Partner.Our partners can belong to a private or
                government body, from any of the below or more industries: </p>
              <ul>
                <li>Robotic Industry.</li>
                <li>Startup Investors.</li>
                <li>Education Councils.</li>
                <li>Technology Innovators.</li>
                <li>Technology entrepreneurs.</li>
                <li>Business and Trade Chambers.</li>
                <li>Artificial Intelligence Industry.</li>
                <li>Corporations in Engineering &amp; Technology.</li>
                <li>Education sector School, College or University, preferably where students are
                  pursuing Science, Technology, Engineering &amp;
                  Mathematics&nbsp; (STEM)&nbsp; courses, or related sciences.</li>
              </ul>

            </div>
          </div>
        </div>
      </div>
    </section>
  </>
}

export default AICRI