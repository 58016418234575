import axios from "axios"
import { toast } from "react-toastify"
import { APIRoutes } from "../utils/BackendRoutes"
import { getErrorMessage } from "../utils/GetErrorMessage"
import setLoading from "../utils/LoadingManager"
import { ADD_AWARDS, GET_AWARDS, DELETE_AWARDS } from "./types"

export const addAwards = (award) => (dispatch) => {
  setLoading(true)
  axios.post(APIRoutes.ADD_AWARDS, award)
    .then(res => {
      dispatch({
        type: ADD_AWARDS,
        payload: award
      })
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const getAwards = () => (dispatch) => {
  setLoading(true)
  axios.get(APIRoutes.GET_AWARDS)
    .then(res => {
      dispatch({
        type: GET_AWARDS,
        payload: res.data.data
      })
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const deleteAwards = (id, index) => (dispatch) => {
  setLoading(true)
  axios.delete(APIRoutes.DELETE_AWARDS + id)
    .then(res => {
      dispatch({
        type: DELETE_AWARDS,
        payload: { id, index }
      })
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}