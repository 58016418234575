import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { verifyEmailAction } from '../../actions/authActions'

const ActivateAccount = ({ ...props }) => {
  const userEmail = props.match.params.email
  const userId = props.match.params.id

  useEffect(() => {
    props.verifyEmailAction(userEmail, userId, props.history)
  }, [])

  return <></>
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, { verifyEmailAction })(ActivateAccount)