import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import '../../css/authentication/Signin.css'

const ResetPassword = ({ ...props }) => {
  const time = props.match.params.time
  const email = props.match.params.email

  useEffect(() => {

  }, [])

  return <>
    <div className="container login">
      <div className="row m-2 m-sm-5 justify-content-center">
        <div className="col-md-6  shadow-lg mb-5 rounded bg-white p-4 p-sm-5">
          <h3 className="pb-3 text-center blue-colour">Password reset</h3>
          <h6 className="pb-3 text-center">Set a New Password</h6>
          <div className="form-style">
            <form>
              <div className="form-group pb-3 password-input">
                <input type="password" placeholder="New Password" className="form-control" id="password-input" required="required" />
                {/* <a href="#" className="password-control" onClick="return show_hide_password(this);"></a> */}
              </div>
              <div className="form-group pb-3 password-input">
                <input type="password" placeholder="Confirm New Password " className="form-control" id="password-input" required="required" />
                {/* <a href="#" className="password-control" onClick="return show_hide_password(this);"></a> */}
              </div>
              <div className="pb-2">
                <button type="submit" className="btn blue-button w-100 font-weight-bold mt-2">Set Password</button>
              </div>
            </form>
          </div>
          <div className="shape1"></div>
          <div className="shape2"></div>
          <div className="shape3"></div>
          <div className="shape4"></div>
        </div>
      </div>
    </div>
  </>
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {})(ResetPassword)