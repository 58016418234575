export const AUTH_SET_USER = 'AUTH_SET_USER'
export const SET_LOADING = 'SET_LOADING'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const ADD_CATEGORY = 'ADD_CATEGORY'
export const GET_CATEGORY = 'GET_CATEGORY'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const GET_SINGLE_PRODUCT = 'GET_SINGLE_PRODUCT'
export const ADD_PRODUCTS = 'ADD_PRODUCTS'
export const DELETE_PRODUCTS = 'DELETE_PRODUCTS'
export const GET_USERS = 'GET_USERS'
export const GET_USERS_TREE = 'GET_USERS_TREE'
export const GET_PAYMENT_LOGS = 'GET_PAYMENT_LOGS'
export const GET_EARNINGS = 'GET_EARNINGS'
export const GET_PAYMENTS = 'GET_PAYMENTS'
export const GET_DASHBOARD = 'GET_DASHBOARD'
export const ADD_COUPON = 'ADD_COUPON'
export const GET_COUPONS = 'GET_COUPONS'
export const DELETE_COUPON = 'DELETE_COUPON'
export const GET_MY_NETWORK = 'GET_MY_NETWORK'
export const ADD_GALLERY = 'ADD_GALLERY'
export const DELETE_GALLERY = 'DELETE_GALLERY'
export const GET_GALLERY = 'GET_GALLERY'
export const ADD_AWARDS = 'ADD_AWARDS'
export const DELETE_AWARDS = 'DELETE_AWARDS'
export const GET_AWARDS = 'GET_AWARDS'
export const GET_USER_REPORT = 'GET_USER_REPORT'