import React from 'react'
import '../css/pages/Blog.css'
import '../css/common/Banner.css'
import Breadcrums from '../common/Banner'
import { UIRoutes } from '../../utils/FrontendRoutes'
import ImageAssets from '../../utils/ImageAssets'
import { Link } from 'react-router-dom'


const Blog = () => {
  return <>
    <section id="main" className="Blog clearfix" role="main">
      <Breadcrums divClassName="BlogSingle-banner main-banner"
        breadcrums={JSON.stringify([{ key: "Home ", value: UIRoutes.HOME }, { key: "Blog", value: UIRoutes.BLOG }])}
      />
      <div className="container  mt-3">
        <div className="row">
          <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5">
            <div className="card h-100">
              <img className="card-img-top" src={ImageAssets.blog.blog1} alt="Bologna" />
              <div className="card-body">
                <h4 className="card-title"><a href="#">Power in you – Students &amp; Parents Seminar by Mrs. Nisha Butani </a></h4>
                <Link className="card-link text-secondary" to={UIRoutes.BLOG1} >Read More</Link>
              </div>
            </div>
          </div>
          {/* <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5">
            <div className="card h-100">
              <img className="card-img-top" src={ImageAssets.blog.blog2} alt="Bologna" />
              <div className="card-body">
                <h4 className="card-title"><a href="#">ADMISSION FOR 2020-21 FOR ICSE/ISC NOW OPEN</a></h4>
                <Link className="card-link text-secondary" to={UIRoutes.BLOG1} >Read More</Link>
              </div>
            </div>
          </div> */}
          <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5">
            <div className="card h-100">
              <img className="card-img-top" src={ImageAssets.blog.blog3} alt="Bologna" />
              <div className="card-body">
                <h4 className="card-title"><a href="#">CAREER FOUNDER</a></h4>
                <Link className="card-link text-secondary" to={UIRoutes.BLOG2} >Read More</Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5">
            <div className="card h-100">
              <img className="card-img-top" src={ImageAssets.blog.blog4} alt="Bologna" />
              <div className="card-body">
                <h4 className="card-title"><a href="#">CAREER CONNECT</a></h4>
                <Link className="card-link text-secondary" to={UIRoutes.BLOG3} >Read More</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
}

export default Blog