import React from "react"
import '../css/policy/Policy.css'

const Terms = () => {
  return <>
    <section className="section policy">
      <div className="container">
        <div className="row">
          <div className="col-md-12 d-flex">
            <div className="contents section-header mx-auto text-center">
              <h2 className="section-title wow fadeIn animated " data-wow-delay="0.2s">Terms &amp; Conditions </h2>
            </div>
          </div>
          <div className="col-md-12">
            <p><b> 1. SIGN UP</b></p>
            <p> 1.1.    In order to access information on CAREER CONNECT you need to sign up and make an account.</p>
            <p>1.2.    To purchase/ enrolment for any course one needs to have an account.</p>
            <p>1.3.    While signing up, make sure all the information that you have provided is true and accurate. This information will be used to maintaining your account. If any information provided by you will cause any kind of harm to us or anybody (including yourself), you will be held responsible.</p>
            <p> 1.4.    Each person who signs up must be extremely careful with your password, to avoid any kind of faults, do not share your password.</p>
            <p> 1.5.    You can terminate your account by simply deleting your account; by contacting our support centre.</p>
            <p> 1.6.    CAREER CONNECT is fully authorized and has all rights to terminate your account in case of any defaults from your side.</p>
            <p>1.7.    Please note, once your account is terminated, it cannot be revoked, this means your data will be cleared from our databases and if there is an ongoing course on your account that will also be terminated. DO NOT contact us for refunds.</p>

            <p><b> 2. INSTRUCTOR/TEACHES POLICY</b></p>
            <p>  2.1.    One may become an instructor/Teacher with CAREER CONNECT by simply signing up for an instructor’s/Teacher’s account.</p>
            <p>2.2.    Every instructor/Teacher will have a contract with CAREER CONNECT, regardless of whether he/she has joined via any Distribution/Chenal Partner.</p>
            <p> 2.3.    Every instructor/Teacher has to duly fill in his credentials including his education details and other work-related details. If in any instance these details are found to be faulty, you will be blacklisted from CAREER CONNECT.</p>
            <p> 2.4.    Once you procure an instructor/Teacher, you will have to duly sign an agreement with CAREER CONNECT, which will clearly mention that no instructor/Teacher will be allowed to infringe with our Intellectual Property Rights.</p>
            <p>2.5.    You are not allowed to post any kind of racist, sexist, pornographic, abusive, anti-religious, violent, misleading, defamatory information on the website/Online Classes.</p>
            <p>2.6.    CAREER CONNECT is an educational Field related website. NO kind of inappropriate behaviour with the students/Parents will be tolerated.</p>
            <p>2.7.    No instructor/Teacher shall use the services of CAREER CONNECT for any other reasons other than tutoring, teaching and instructional services to students.</p>
            <p>2.8.    Any abuse of CAREER CONNECT’s resources will lead to termination of your license/Contract as an instructor/Teacher.</p>
            <p>2.9.    Piracy is a crime, in case of piracy of any content on the website; you will be bound to face legal repercussions.</p>

            <p><b> 3.  PRIVACY POLICY</b></p>
            <p>What kinds of information do we collect?</p>
            <p>CONTACT INFORMATION: Any data that identifies an individual either directly or indirectly when other data is combined is regarded as personal data. Name, Email ID, Mobile Number, Postal Address, etc. are all forms of personal data, which is obtained by the website when you sign up to get information about the products and services rendered on the website.</p>
            <p>1.  Location, usage and device information can be obtained by the website when you surf our website.</p>
            <p>2.  Device information such as the hardware model, device IP address, software, system and version of the devices; will be collected by the website when you use any device to access the website.</p>
            <p>3.  Our website may also collect your data via feedback options given to the students/Users and various surveys conducted on the website.</p>
            <p>4.  Transaction information such as billing name, billing address, payment method, etc. provided by the student/User while availing any service or while purchasing any product from our website will be collected. Please note that the website NEVER collets your Debit/Credit Card details, Expiry date, CVV, etc.</p>
            <p>5.  In other ways, information may be collected through cookies, which will help determine if a particular user had visited the site on a previous occasion and thus, save and remember any preferences that may have been set. Cookies maybe used to keep a track of your current web-browsing session.</p>
            <p>6.  Cookies may also be used by us to statistically monitor how many people are using our website and for what purpose.</p>
            <p><b>4. How do we use your information?</b></p>
            <p>1.  We use your personal information to verify your identity, to contact you, confirm your registration and improve products and services.</p>
            <p>2.  We use your contact information in order to send you SMS and E-mails about your transactions, marketing purposes and to inform you about our site trends</p>
            <p>3.  Device information is collected by us to make sure that your account is logged in a device which is trusted by you, this is to ensure the security of your personal information.</p>
            <p>4.  Your feedbacks help us improve and make your experience on our website better each day. Information collected via feedbacks and surveys will be used by our team to learn and understand the wants and needs of our consumer.</p>
            <p>5.  All information collected by cookies and other technologies help us remember your preferences and make a more personalized experience for our consumers.</p>
            <p>6.  We may use the information collected by us to investigate or address any claims for damages or disputes relating to your use of our service.  This information may also be as evidence if required in any official inquiry or any law related thereby.</p>



            <p><b>5. Analytics</b></p>
            <p>We may use third-party Service Providers to monitor and analyse the use of our Service.</p>
            <p><b> 6. Error Reporting and Feedback</b></p>
            <p>You may provide us either directly at info@careerconnectedu.com or via third party sites and tools with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Service (“Feedback”). You acknowledge and agree that:</p>
            <p>(i)	you shall not retain, acquire or assert any intellectual property right or other right, title or interest in or to the Feedback;</p>
            <p>(ii)	Company may have development ideas similar to the Feedback;</p>
            <p>(iii)	Feedback does not contain confidential information or proprietary information from you or any third party; and</p>
            <p>(iv)	Company is not under any obligation of confidentiality with respect to the Feedback. In the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, you grant Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative works, publish, distribute and commercialize) Feedback in any manner and for any purpose.</p>
            <p><b>7. Changes To Service</b></p>
            <p> We reserve the right to withdraw or amend our Service or Product, and any service or material we provide via Service, in our sole discretion without notice. We will not be liable if for any reason all or any part of Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of Service, or the entire Service, to users, including registered users.</p>
            <p><b>8. Acknowledgement</b></p>
            <p>BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM</p>
            <p><b>9. Contact Us</b></p>
            <p>Please send your feedback, comments, requests for technical support by email:info@careerconnectedu.com</p>

          </div>
        </div>
      </div>
    </section>
  </>
}

export default Terms