import React from 'react'
import '../css/ContactUs/ContactUs.css'
import '../css/common/Banner.css'
import Breadcrums from '../common/Banner'
import { UIRoutes } from '../../utils/FrontendRoutes'

const TIMTS = () => {
    return <>
        <section id="main" className="TIMTS clearfix" role="main">
            <Breadcrums divClassName="TIMTS-banner main-banner"
                breadcrums={JSON.stringify([{ key: "Home ", value: UIRoutes.HOME }, { key: "TIMTS", value: UIRoutes.TIMTS }])}
            />
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="text-center mb-4 mt-4">Times Institute Of Management and Technical Studies TIMTS </h3>
                        <h3 className="text-center mb-4">(Strategic Partner Gujarat)</h3>
                        <p className="text-center mb-5">"TIMTS is a leading international brand in Online Education and specialized in Management, Information Technology, eSecurity training and consulting. Being most preferred institute, providing affordable, quality education, every day everywhere as well as engaging, empowering and inspiring learners. India’s leading education brand, certifying for Technical and Management skills, in affiliation with world’s leading companies, Institutions. 15 eCampuses across India are ready to offer you training with blended approach. State of art facility at all e-Campuses provide Classroom training, Virtual Training and On-Campus Training from experts that will get you excellent exposure. TIMTS offers certification Program in Advance Robotics association with AIMA, AICRA, Autodesk, Adobe, etc. that have good acceptance in corporate in corporate and government jobs.</p>
                    </div>
                    <div className="col-lg-12 Embedded-system-details">
                        <h3 className="text-center text-blue mb-5"> Embedded System &amp; Robotics</h3>
                        <h3>1. Embedded System</h3>
                        <p>Real time computing system often dedicated to perform a function and embedded as a part of the system is termed as embedded system. Due to properties it possess like low power consumption, smaller size.</p>
                        <h3>2. Embedded System With AVR</h3>
                        <p>Developed by Atmel AVR belongs to the family of microcontrollers. RISC 8-bit microcontroller AVR was the first one to have flash memory for program storage.</p>
                        <h3>3. Embedded System With ARM</h3>
                        <p>Microcontroller and microprocessor based integrated circuits that are based on 32-bit processor cores with peripherals and tool support are known as ARM based processors with developers.</p>
                        <h3>4. Arduino</h3>
                        <p>Arduino physical computing based on ATmega328 microcontroller with more pinouts and memory capabilities. Arduino can be flexibly used with more conventional programming environment be it C, assembler etc</p>
                        <h3>5. Matlab With Robotics</h3>
                        <p>Matrix Laboratory is a numerical computing and 4th&nbsp;generation programming language which is developed by MathWorks. Code for MATLAB can be written in C, C++, Java making it a versatile language</p>
                        <h3>6. Quadcopter</h3>
                        <p>A helicopter with four rotors is known as quadrotor or quadcopter. Since the body of this craft is lifted by set of rotors or vertically oriented propellers it is also classified as rotorcraft.</p>
                        <h3>7. Internet of Things</h3>
                        <p>Embedded system or the hardware enabled to connect to the software or objects to collect &amp; exchange data in global standards known as Internet of Things (IoT). IoT allows objects to be sensed.</p>
                        <h3>8. Raspberry Pi</h3>
                        <p>First generation being released in 2012 Raspberry Pi later came up with more simpler and inexpensive model. This single board computer was developed to promote basic computer training.</p>
                        <h3>9. O Level</h3>
                        <p>‘O’ level program of AICRA is equivalent to a Foundation Level Course in Robotics Education. Students can acquire this qualification by undergoing this program and passing the examination conducted by AICRA. Working professionals can also appear in this examination directly, provided they possess the requisite educational qualification as indicated below.</p>
                        <h3>10.&nbsp;&nbsp; A Level</h3>
                        <p>‘A’ level program of AICRA is equivalent to an Advance Level of Certification in Robotics Education. Students can acquire this qualification by undergoing this program and passing the examination conducted by AICRA. Working professionals can also appear in this examination directly, provided they possess the requisite educational qualification as indicated below.</p>
                        <h3>11.&nbsp;&nbsp; B Level</h3>
                        <p>‘B’ level program of AICRA has been recognized as Specialized Program, equivalent to an Advance Level of Certification in Robotics Education. Students can acquire this qualification by undergoing this program and passing the examination conducted by AICRA. Working professionals can also appear in this examination directly, provided they possess the requisite educational qualification as indicated below.</p>
                        <p>&nbsp;</p>
                        <h3 className="text-center text-blue">Big Data Hadoop &amp; Analytics</h3>
                        <p className="text-center">Processing big amount of data and processing it at large scale has always been a task for the system, this is when engineers or Hadoop masters are required. Hadoop is a complete eco-system of open source project that provide us the frame work to deal with big data. Designed in a manner to manage from single server to thousands of machines, with local computation and storage the only challenge which is faced is the enormous investment and excessive time taken.</p>
                        <p>&nbsp;</p>
                        <h3 className="text-center text-blue">Cad Tools</h3>
                        <h3>1. Certification in Catia</h3>
                        <p>CATIA stands for Computer Aided Three-dimensional Interactive Application which is a Modelling software released in 1977 by Dassault System. Software is developed on C++ platform. Presently this is the only software which has the capability of CAD, CAM and CAE because of its feature as it has N number of work benches.</p>
                        <h3>2. Certification in Creo</h3>
                        <p>Creo is a family or suite of design software supporting product design for discrete manufacturers and is developed by PTC. The suite consists of apps, each delivering a distinct set of capabilities for a user role within product development. Creo runs on Microsoft Windows and provides apps for 3D CAD parametric feature solid modeling,</p>
                        <h3>3. Certification in AutoCad</h3>
                        <p>Commonly used and well known by most users for 2D and 3D designing is the software developed by Autodesk called as AutoCAD. Being the first CAD programs to be executed on personal computers AutoCAD did capture the major market of industrial designers. It was initially derived from a proprietary language called Interact.</p>
                        <h3>4. Certification in Ansys</h3>
                        <p>Ansys, Inc. is a public company based in Canonsburg, Pennsylvania. It develops and markets engineering simulation software. Ansys software is used to design products and semiconductors, as well as to create simulations that test a product’s durability, temperature distribution, fluid movements, and electromagnetic properties..</p>
                        <h3>5. Certificate in SolidWorks</h3>
                        <p>A solid modelling CAD or computer-aided design and CAE commonly known as computer-aided engineering is a computer program which needs Microsoft Windows as a platform to run and is known as SolidWorks. Founded in December 1993 by Massachusetts Institute of Technology SolidWorks is published by Dassault System.</p>
                        <h3>6. Certification in STAAD PRO</h3>
                        <p>STAAD or (STAAD.Pro) is a structural analysis and design computer program originally developed by Research Engineers International at Yorba Linda, CA in 1997. In late 2005, Research Engineers International was bought by Bentley Systems.</p>
                        <h3>7. Certification in 3D Max</h3>
                        <p>Render three-dimensional animation, models and images using Autodesk 3ds Max. Originally it was introduced as 3D studio which was created by Yost Group of Atari to work on the DOS platform for which development started in the year 1988.The software is used for multiple purpose from developing CGI, academic applications as well as game development.</p>
                        <h3>8. Certification in Revit</h3>
                        <p>In this course, you will learn to model buildings using the building information modeling software, Revit Architecture 2014. You will learn to navigate the user interface, model architectural elements like walls, windows, doors, roofs, floors, structure, curtain wall systems and stairs. You will annotate and dimension drawings, generate sections, details and schedules.</p>
                        <h3></h3>
                        <h3 className="text-center text-blue">IT &amp; Software Development</h3>
                        <h3>1. Certification in C++</h3>
                        <p>C++ also an extension of C, is an object oriented programming (OOP) language, developed by Bjarne Stroustrup. Therefore making it possible to code C++ in “C style” or “object-oriented style.” C++ comes with an advantage of being coded in either way in certain scenarios, and is thus an effective example of a hybrid language.</p>
                        <h3>2. Certification in C</h3>
                        <p>A general-purpose, high-level language, originally developed by Dennis M. Ritchie to develop the UNIX operating system at Bell Labs. This is one programming language without any expiry date.</p>
                        <h3>3. Advance Java</h3>
                        <p>If you are well versed with basics of Java or Core Java then learning advance java becomes a must while you plan to pursue your career in Java Technology. It is an application based concept, also known as Web application as it run on server. “Advanced Java” is everything that goes beyond – most importantly the APIs are defined in Java Enterprise Edition.</p>
                        <h3></h3>
                        <h3>4. Core Java</h3>
                        <p>Java is segregated in two categories     namely Core Java &amp; Advance Java. The fundamentals of java which deals with concept of oops and its implementation is called core java.</p>
                        <h3>5. Android App development</h3>
                        <p>Android is a user interfaced mobile operating system based on Linux and developed by Google. This being most popular with technology companies needs a ready-made, low cost and customized operating systems</p>
                        <h3></h3>
                        <h3 className="text-center text-blue">Digital Marketing</h3>
                        <p>&nbsp;</p>
                        <h3>1. Digital Marketing Diploma</h3>
                        <p>When we intend to market products using digital medium or digital technology the term used is Digital Marketing. Digital medium mainly includes Internet, while Mobile phone display advertising also belongs to the same category.</p>
                        <h3>2. SEO Program</h3>
                        <p>When we intend to market products using digital medium or digital technology the term used is Digital Marketing. Digital medium mainly includes Internet, while Mobile phone display advertising also belongs to the same category.</p>
                        <p>&nbsp;</p>
                        <h3 className="text-center text-blue">Web Development</h3>
                        <h3>1. Certificate in PHP</h3>
                        <p>A server scripting language and a powerful tool to make webpages dynamic and interactive PHP is used. PHP Hypertext Preprocessor is free, effective and commonly used as an alternative to stand by Microsoft tools. Being compatible and a server side language scripts are executed on server and runs on various platforms namely Windows, Linux, Unix, Mac OS X, etc.</p>
                        <h3>2. Core PHP</h3>
                        <p>A server scripting language and a powerful tool to make webpages dynamic and interactive PHP is used. PHP Hypertext Preprocessor is free, effective and commonly used as an alternative to stand by Microsoft tools. Being compatible and a server side language scripts are executed on server and runs on various platforms namely Windows, Linux, Unix, Mac OS X, etc.</p>
                        <h3></h3>
                        <h3 className="text-center text-blue">Web &amp; Graphic Design</h3>
                        <h3>1. Web Designing</h3>
                        <p>Websites are created using a markup language called HTML tags that define the content and metadata of each page. The layout and appearance of the elements within a webpage are typically defined using CSS, or cascading style sheets. Therefore, most websites include a combination of HTML and CSS that defines how each page will appear in a browser.</p>
                        <h3>2. Certification in Graphics</h3>
                        <p>Graphic designing is the way of communication by putting your thoughts on a piece of paper or on the computer. Become a Graphic designer with certification course, enhance your creative skills and visualization. Get trained to become a professional graphic designer by starting with the short term course and expanding your knowledge in particular field.</p>
                        <h3>3. Corel Draw certification</h3>
                        <p>To meet today’s market demand of creative work for advertisements, collaterals and web work, Corel draw is a must need tool in this industry. Using this software organizations get creative solution for almost all their needs as it offers you wide range of features &amp; tools that help with different design approaches.</p>
                        <h3>4. InDesign Certification</h3>
                        <p>Adobe InDesign is a desktop publishing software application produced by Adobe Systems. It can be used to create works such as posters, flyers, brochures, magazines, newspapers, presentations, books and eBooks. InDesign can also publish content suitable for tablet devices in conjunction with Adobe Digital Publishing Suite. Graphic designers and production artists are the principal users, creating and laying out periodical publications, posters, and print media.</p>
                        <h3>5. Illustrator Certification</h3>
                        <p>An illustrator is an artist who specializes in enhancing writing or elucidating concepts by providing a visual representation that corresponds to the content of the associated text or idea. The illustration may be intended to clarify complicated concepts or objects that are difficult to describe textually, which is the reason illustrations are often found in children books Illustration is the art of making images that work with something and add to it without needing direct attention and without distracting from what they illustrate.</p>
                        <h3>6. Photoshop Certification</h3>
                        <p>Photoshop allows you to make your images look good and eye-catching as it is the only Image editing application that is used to manipulate and enhance your digital photographs and images. TIMTS offers highly advanced and professional training on Photoshop software. We train students to be placed in renowned organizations and MNC’s in the field of advertising, printing and publishing.</p>
                        <h3></h3>
                        <h3 className="text-center text-blue">Management</h3>
                        <div className='justify-center'>
                            <p><strong>1. Operations</strong></p>
                            <p><strong>2. HRD</strong></p>
                            <p><strong>3. IB</strong></p>
                            <p><strong>4. Marketing</strong></p>
                            <p><strong>5. Finance</strong></p>
                            <p><strong>6. Retail</strong></p>
                        </div>
                        <div className='row'>
                            <li className='col-lg-6'><u>eCampus Programs : </u>
                                <ul>
                                    <li>IT Security</li>
                                    <li>Cloud Computing</li>
                                    <li>Embedded Systems &amp; Robotics</li>
                                    <li>Forensics &amp; Investigations</li>
                                    <li>Personality Development</li>
                                    <li>PHP/ .NET/ JAVA/ C++</li>
                                    <li>Mobile Applications Dev.</li>
                                    <li>MS Office, Languages</li>
                                    <li>Automation</li>
                                    <li>PGDM + MBA</li>
                                    <li>DBM + BBA</li>
                                    <li>PGDM Executive</li>
                                </ul>
                            </li>
                            <li className='col-lg-6'><u>Traditional Programs:</u>
                                <ul>
                                    <li>MBA, MCA, MSc, MCom, MA, M.Tech</li>
                                    <li>BBA, BCA, BSc, BCom, B.Tech</li>
                                    <li>PG Diploma, Diploma, Certificate Programs</li>
                                    <li>Ed., M.Ed., D.Ed., B.P.Ed., M.Phil., Ph.D.</li>
                                </ul>
                            </li>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </>
}

export default TIMTS