import React from 'react'
import '../css/pages/Blog.css'
import '../css/common/Banner.css'
import Breadcrums from '../common/Banner'
import { UIRoutes } from '../../utils/FrontendRoutes'
import ImageAssets from '../../utils/ImageAssets'
import { Link } from 'react-router-dom'


const UpcomingEvents = () => {
  return <>
    <section id="main" className="UpcomingEvents clearfix" role="main">
    <Breadcrums divClassName="UpcomingEvents-banner main-banner"
        breadcrums={JSON.stringify([{ key: "Home ", value: UIRoutes.HOME }, { key: "Upcoming Events ", value: UIRoutes.UPCOMINGEVENTS }])}
      />
      <div className="container  mt-3">
        <div className="row">
          <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5">
            <div className="card h-100">
              <img className="card-img-top" src={ImageAssets.banner1.banner1} alt="Bologna" />
              <div className="card-body">
                <h4 className="card-title"><a href="#">CLASSROOM TO CAREER </a></h4>
                <Link className="card-link text-secondary" to={UIRoutes.BLOG4} >Read More</Link>
              </div>
            </div>
          </div>
          {/* <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5">
            <div className="card h-100">
              <img className="card-img-top" src={ImageAssets.blog.blog2} alt="Bologna" />
              <div className="card-body">
                <h4 className="card-title"><a href="#">ADMISSION FOR 2020-21 FOR ICSE/ISC NOW OPEN</a></h4>
                <a href="#" className="card-link text-secondary">Read More</a>
              </div>
            </div>
          </div> */}
          <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5">
            <div className="card h-100">
              <img className="card-img-top" src={ImageAssets.blog.blog3} alt="Bologna" />
              <div className="card-body">
                <h4 className="card-title"><a href="#">CAREER FOUNDER NEW BRANCH OPNENING AT TITHAL ROAD</a></h4>
                <Link className="card-link text-secondary" to={UIRoutes.BLOG5} >Read More</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
}

export default UpcomingEvents