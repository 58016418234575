import React, { useState } from 'react'
import { connect } from 'react-redux'
import validator from "validator"
import CustomButton from "../common/CustomButton"
import TextInput from "../common/TextInput"
import { addCoupon } from '../../actions/couponActions'

const AddCoupons = ({ ...props }) => {
  const [name, setName] = useState('')
  const [percentageDisc, setPercentageDisc] = useState('')
  const [amountDisc, setAmountDisc] = useState('')

  const handleChange = (event, calledFor) => {
    let tempValue = { value: event.target.value, error: '' }
    if (!tempValue.value) {
      tempValue = { value: event.target.value, error: null }
      if (calledFor === 'Name') {
        tempValue = { ...tempValue, error: calledFor + ' is required' }
      }
    }
    switch (calledFor) {
      case 'Name':
        if (!tempValue.error && validator.isEmpty(tempValue.value)) {
          tempValue.error = 'Coupon name is not valid'
        }
        setName(tempValue)
        break
      case 'PercentageDisc':
        if (!tempValue.error && validator.isEmpty(tempValue.value)) {
          tempValue.error = 'Percentage discount is not valid'
        } else if (tempValue.value < 0 || tempValue.value > 100) {
          tempValue.error = 'Percentage discount is not valid'
        }
        setPercentageDisc(tempValue)
        break
      case 'AmountDisc':
        if (!tempValue.error && validator.isEmpty(tempValue.value)) {
          tempValue.error = 'Amount discount is not valid'
        }
        setAmountDisc(tempValue)
        break
    }
  }

  const handleSave = () => {
    const requestBody = {
      name: name.value,
      percentageDisc: percentageDisc.value,
      amountDisc: amountDisc.value
    }
    props.addCoupon(requestBody)
  }

  return <>
    <div className="container">
      <div className="row justify-content-center text-center">
        <div className="col-lg-8 shadow-lg p-3 mb-5 bg-body rounded">
          <h3 className="text-dark main-title-border ">Add Coupon </h3>
          <div className="col-lg-12">
            <TextInput
              divClassName="form-group pb-3"
              type="text"
              placeholder="Coupon Name"
              onChange={(e) => handleChange(e, 'Name')}
              inputClassName="form-control"
              id="name"
              required="required"
              value={name.value}
              error={name.error}
            />
          </div>
          <div className="col-lg-12">
            <TextInput
              divClassName="form-group pb-3"
              type="number"
              placeholder="Discount Percentage"
              onChange={(e) => handleChange(e, 'PercentageDisc')}
              inputClassName="form-control"
              id="PercentageDisc"
              required="required"
              value={percentageDisc.value}
              error={percentageDisc.error}
            />
          </div>
          <div className="col-lg-12">
            <TextInput
              divClassName="form-group pb-3"
              type="number"
              placeholder="Discount Amount"
              onChange={(e) => handleChange(e, 'AmountDisc')}
              inputClassName="form-control"
              id="AmountDisc"
              required="required"
              value={amountDisc.value}
              error={amountDisc.error}
            />
          </div>
          <div className="col-lg-12 d-flex justify-content-center mt-4">
            <CustomButton
              divClassName='pb-2 w-25'
              className='btn blue-button w-100 font-weight-bold text-center mx-auto mt-2'
              onClick={handleSave}
              text='Save'
              disabled={!name.value || name.error}
            />
          </div>
        </div>
      </div>
    </div>
  </>
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, { addCoupon })(AddCoupons)