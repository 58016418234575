import { ADD_GALLERY, DELETE_GALLERY, GET_GALLERY } from "../actions/types"

const initialState = {
  list: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_GALLERY:
      return { ...state, list: [...state.list, action.payload] }
    case GET_GALLERY:
      return { ...state, list: action.payload }
    case DELETE_GALLERY:
      const tempGallery = [...state.list]
      tempGallery.splice(action.payload.index, 1)
      return { ...state, list: [...tempGallery] }
    default:
      return state
  }
}