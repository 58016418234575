import axios from "axios"
import { toast } from "react-toastify"
import { APIRoutes } from "../utils/BackendRoutes"
import { getErrorMessage } from "../utils/GetErrorMessage"
import setLoading from "../utils/LoadingManager"
import { GET_DASHBOARD } from "./types"

export const getPaymentLogs = (role, id) => dispatch => {
  setLoading(true)
  axios.get(APIRoutes.GET_PAYMENT_LOGS + role + '/' + id)
    .then(res => {
      axios.get(APIRoutes.GET_EARNINGS + id)
        .then(res1 => {
          axios.get(APIRoutes.GET_PAYMENTS + id)
            .then(res2 => {
              dispatch({
                type: GET_DASHBOARD,
                payload: {
                  logs: res.data.data,
                  earnings: res1.data.data,
                  payments: res2.data.data
                }
              })
              toast.success('Details retrieved successfully')
              setLoading(false)
            }).catch(err => {
              setLoading(false)
              toast.error(getErrorMessage(err))
            })
        }).catch(err => {
          setLoading(false)
          toast.error(getErrorMessage(err))
        })
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}