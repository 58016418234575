import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import '../css/pages/Aboutus.css'
import '../css/dashboard/dashboard.css'
import { getPaymentLogs } from '../../actions/paymentActions'
import Table from 'react-bootstrap/Table';

const Dashboard = ({ ...props }) => {

  useEffect(() => {
    props.getPaymentLogs(JSON.parse(localStorage.getItem('user')).role, JSON.parse(localStorage.getItem('user'))._id)
  }, [])

  return <>
    <div className='shadow-lg p-3 mb-5 bg-body rounded'>
  
      <h3 className="text-dark main-title-border mb-2">Payment Logs</h3>
      <Table striped bordered hover responsive="md" className='mb-5'>
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Note</th>
            <th>Amount</th>
            <th>Payment Date</th>
          </tr>
        </thead>
        <tbody>
          {props.logs.map((eachPayment, index) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td>{eachPayment.description}</td>
                <td>₹ {eachPayment.price}</td>
                <td>{new Date(eachPayment.updatedAt).toLocaleDateString('en-IN')}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>

      <h3 className="text-dark main-title-border ">Products Purchased by your Subordinates</h3>
      <Table striped bordered hover responsive="md" className='mb-5'>
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Name</th>
            <th>Course Name</th>
            <th>Amount</th>
            <th>Commission Percentage</th>
            <th>Commission Amount</th>
            <th>Payment Date</th>
          </tr>
        </thead>
        <tbody>
          {props.earnings.map((eachPayment, index) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td>{eachPayment.userId.firstName + ' ' + eachPayment.userId.lastName}</td>
                <td>{eachPayment.name}</td>
                <td>₹ {eachPayment.price}</td>
                <td>{eachPayment.commissionPercentage}</td>
                <td>₹ {(eachPayment.commissionPercentage * eachPayment.price) / 100}</td>
                <td>{new Date(eachPayment.updatedAt).toLocaleDateString('en-IN')}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>

      <h3 className="text-dark main-title-border ">Payments</h3>
      <Table striped bordered hover responsive="md" >
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Course Name</th>
            <th>Amount</th>
            <th>Payment Date</th>
          </tr>
        </thead>
        <tbody>
          {props.payments.map((eachPayment, index) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td>{eachPayment.name}</td>
                <td>₹ {eachPayment.price}</td>
                <td>{new Date(eachPayment.updatedAt).toLocaleDateString('en-IN')}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  </>
}

const mapStateToProps = (state) => ({
  logs: state.payments.logs,
  earnings: state.payments.earnings,
  payments: state.payments.payments,
})

export default connect(mapStateToProps, { getPaymentLogs })(Dashboard)