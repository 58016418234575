import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import validator from 'validator'
import { loginAction } from '../../../actions/authActions'
import { UIRoutes } from '../../../utils/FrontendRoutes'
import CustomButton from '../../common/CustomButton'
import TextInput from '../../common/TextInput'
import '../../css/authentication/Signin.css'

const Signin = ({ ...props }) => {
  const referralCode = new URLSearchParams(props.location.search).get("referralCode")

  const [username, setUsername] = useState({ value: '', error: '' })
  const [password, setPassword] = useState({ value: '', error: '' })

  const handleChange = (event, calledFor) => {
    let tempValue = { value: event.target.value, error: '' }
    if (!tempValue.value) {
      tempValue = { value: event.target.value, error: calledFor + ' is required' }
    }
    switch (calledFor) {
      case 'Username':
        if (!tempValue.error && !validator.isEmail(tempValue.value)) {
          tempValue.error = 'Username is not valid'
        }
        setUsername(tempValue)
        break
      case 'Password':
        if (!tempValue.error && !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/.test(tempValue.value)) {
          tempValue.error = 'Password did\'t match with defined criteria'
        }
        setPassword(tempValue)
        break
    }
  }

  const handleSubmit = () => {
    const requestBody = {
      email: username.value,
      password: password.value
    }
    props.loginAction(requestBody, props.history)
  }

  return <>
    {/* <script src="https://use.fontawesome.com/f59bcd8580.js"></script> */}
    <div className="container login">
      <div className="row m-2 m-sm-5 justify-content-center">
        {/* <div className="col-md-6 d-none d-md-block">
          <img src="https://images.unsplash.com/photo-1566888596782-c7f41cc184c5?ixlib=rb-1.2.1&auto=format&fit=crop&w=2134&q=80" className="img-fluid" style="min-height:100%;" />
        </div> */}
        <div className="col-md-6  shadow-lg mb-5 rounded bg-white p-4 p-sm-5">
          <h3 className="pb-3 text-center blue-colour">Sign In</h3>
          <div className="form-style">
            {/* <Breadcrums breadcrums={JSON.stringify([{ key: "Home / ", value: UIRoutes.HOME }, { key: "Signin", value: UIRoutes.SIGNIN }])} /> */}
            <form>
              <TextInput
                divClassName="form-group pb-3"
                type="email"
                placeholder="Email"
                onChange={(e) => handleChange(e, 'Username')}
                inputClassName="form-control"
                id="email"
                describedBy="emailHelp"
                required="required"
                value={username.value}
                error={username.error}
              />
              <TextInput
                divClassName="form-group pb-3 password-input"
                type="password"
                placeholder="Password"
                onChange={(e) => handleChange(e, 'Password')}
                inputClassName="form-control"
                id="password-input"
                required="required"
                value={password.value}
                error={password.error}
              />
              {/* <a href="#" className="password-control" onClick="return show_hide_password(this);"></a> */}
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center"><input name="" type="checkbox" value="" /> <span className="pl-2 font-weight-bold">Remember Me</span></div>
                <div><Link className="Forget-link" to={UIRoutes.FORGETPASSWORD}>Forget Password</Link></div>
              </div>
            </form>
            <CustomButton
              divClassName='pb-2'
              className='btn blue-button w-100 font-weight-bold mt-2'
              onClick={handleSubmit}
              text='Login'
              disabled={!username.value || username.error || !password.value || password.error}
            />
            <div className="pt-4 text-center">
              Don't have an account? <Link className="login-link" to={UIRoutes.SIGNUP + (referralCode ? '?referralCode=' + referralCode : '')}>Sign Up</Link>
            </div>
            <div className="shape1"></div>
            <div className="shape2"></div>
            <div className="shape3"></div>
            <div className="shape4"></div>
          </div>
        </div>
      </div>
    </div>
  </>
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, { loginAction })(Signin)