import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { UIRoutes } from '../utils/FrontendRoutes'

//This Route is used to redirect a logged in user to their profile page if they try to access pages that shouldn't be accessed by a logged in user like the login page or resend email validation page.
const RedirectProfileRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth.isAuthenticated
        ?
        <Redirect to={UIRoutes.HOME} />
        :
        <Component {...props} />
    }
  />
)

RedirectProfileRoute.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps)(RedirectProfileRoute)