import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import '../css/pages/Gallery.css'
import '../css/common/Banner.css'
import '../css/pages/Aboutus.css'
import Breadcrums from '../common/Banner'
import { UIRoutes } from '../../utils/FrontendRoutes'
import ImageAssets from '../../utils/ImageAssets'
import { getAwards } from '../../actions/awardActions'

const AwardsAchievements = ({ ...props }) => {
  const [active, setActive] = useState('all')

  useEffect(() => {
    props.getAwards()
  }, [])

  useEffect(() => {
    let sortBtn = document.querySelector('.filter-menu').children;
    let sortItem = document.querySelector('.filter-item').children;

    for (let i = 0; i < sortBtn.length; i++) {
      sortBtn[i].addEventListener('click', function () {
        for (let j = 0; j < sortBtn.length; j++) {
          sortBtn[j].classList.remove('current');
        }

        this.classList.add('current');


        let targetData = this.getAttribute('data-target');

        for (let k = 0; k < sortItem.length; k++) {
          sortItem[k].classList.remove('active');
          sortItem[k].classList.add('delete');

          if (sortItem[k].getAttribute('data-item') == targetData || targetData == "all") {
            sortItem[k].classList.remove('delete');
            sortItem[k].classList.add('active');
          }
        }
      });
    }
  }, [])

  const handleSetActive = (tab) => {
    setActive(tab)
  }

  return <>
    <section id="main" className="Gallery Achievements clearfix" role="main">
      <Breadcrums divClassName="Achievements-banner main-banner"
        breadcrums={JSON.stringify([{ key: "Home", value: UIRoutes.HOME }, { key: "Awards & Achievements", value: UIRoutes.AWARDSACHIEVEMENTS }])}
      />
      <div className="container">
        <div className='row'>
          <div className='col-lg-12'>
            <ul className="filter-menu">
              <li className={'all ' + (active === 'all' ? 'current' : '')} onClick={() => handleSetActive('all')} data-target="all">All</li>
              <li className={(active === 'company-achievements' ? 'current' : '')} onClick={() => handleSetActive('company-achievements')} data-target="company-achievements">Company Achievements </li>
              <li className={(active === 'achievements-of-partner-companies' ? 'current' : '')} onClick={() => handleSetActive('achievements-of-partner-companies')} data-target="achievements-of-partner-companies">Achievements of Partner companies</li>
              <li className={(active === 'individual-achievements' ? 'current' : '')} onClick={() => handleSetActive('individual-achievements')} data-target="individual-achievements">Individual Achievements</li>
            </ul>
          </div>
        </div>
        <div className='row'>
          <ul className="filter-item">
            {
              props.list.map((eachAward, index) => {
                return (
                  <div className="item col-lg-4" data-item={eachAward.type}><a href={eachAward.source} data-fancybox="awards"><img src={eachAward.source} preload="auto" alt="Achievements" /></a></div>
                )
              })
            }
            {/* <div className="item col-lg-4" data-item="Individual Achievements"><a href={ImageAssets.Achievements.gallery2} data-fancybox="gallery"><img src={ImageAssets.Achievements.gallery2} preload="auto" alt="Achievements" /></a></div>
            <div className="item col-lg-4" data-item="Individual Achievements"><a href={ImageAssets.Achievements.gallery3} data-fancybox="gallery"><img src={ImageAssets.Achievements.gallery3} preload="auto" alt="Achievements" /></a></div> */}
          </ul>
        </div>
      </div>
    </section>
  </>
}

const mapStateToProps = (state) => ({
  list: state.awards.list
})

export default connect(mapStateToProps, { getAwards })(AwardsAchievements)