import React, { useState } from "react"
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import validator from "validator"
import { registerAction } from "../../../actions/authActions"
import { UIRoutes } from '../../../utils/FrontendRoutes'
import CustomButton from "../../common/CustomButton"
import TextInput from "../../common/TextInput"
import '../../css/authentication/Signup.css'

const Signup = ({ ...props }) => {
  const referenceId = new URLSearchParams(props.location.search).get("referralCode")

  const [firstName, setFirstName] = useState({ value: '', error: '' })
  const [lastName, setLastName] = useState({ value: '', error: '' })
  const [mobileNumber, setMobileNumber] = useState({ value: '', error: '' })
  const [username, setUsername] = useState({ value: '', error: '' })
  const [password, setPassword] = useState({ value: '', error: '' })

  const handleChange = (event, calledFor) => {
    let tempValue = { value: event.target.value, error: '' }
    if (!tempValue.value) {
      tempValue = { value: event.target.value, error: calledFor + ' is required' }
    }
    switch (calledFor) {
      case 'Firstname':
        if (!tempValue.error && !validator.isAlpha(tempValue.value)) {
          tempValue.error = 'Firstname is not valid'
        }
        setFirstName(tempValue)
        break
      case 'Lastname':
        if (!tempValue.error && !validator.isAlpha(tempValue.value)) {
          tempValue.error = 'Lastname is not valid'
        }
        setLastName(tempValue)
        break
      case 'Mobile':
        if (!tempValue.error && !validator.isMobilePhone(tempValue.value, 'en-IN')) {
          tempValue.error = 'Mobile Number is not valid'
        }
        setMobileNumber(tempValue)
        break
      case 'Username':
        if (!tempValue.error && !validator.isEmail(tempValue.value)) {
          tempValue.error = 'Username is not valid'
        }
        setUsername(tempValue)
        break
      case 'Password':
        if (!tempValue.error && !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/.test(tempValue.value)) {
          tempValue.error = 'Password did\'t match with defined criteria'
        }
        setPassword(tempValue)
        break
    }
  }

  const handleSubmit = async () => {
    const requestBody = {
      firstName: firstName.value,
      lastName: lastName.value,
      mobileNumber: mobileNumber.value,
      email: username.value,
      password: password.value,
      reference: referenceId
    }
    props.registerAction(requestBody, props.history)
  }

  return <>
    <script src="https://use.fontawesome.com/f59bcd8580.js"></script>
    <div className="container">
      <div className="row  m-2 m-sm-5  justify-content-center">

        <div className="col-md-6 card-bg  shadow-lg mb-5 rounded bg-white p-4 p-sm-5">
          <h3 className="pb-3 text-center blue-colour">Sign Up</h3>
          <div className="form-style mx-auto">
            <form>
              <TextInput
                divClassName="form-group pb-3"
                type="text"
                placeholder="Firstname"
                onChange={(e) => handleChange(e, 'Firstname')}
                inputClassName="form-control"
                id="firstname"
                required="required"
                value={firstName.value}
                error={firstName.error}
              />
              <TextInput
                divClassName="form-group pb-3"
                type="text"
                placeholder="Lastname"
                onChange={(e) => handleChange(e, 'Lastname')}
                inputClassName="form-control"
                id="lastname"
                required="required"
                value={lastName.value}
                error={lastName.error}
              />
              <TextInput
                divClassName="form-group pb-3"
                type="text"
                placeholder="Phone Number"
                onChange={(e) => handleChange(e, 'Mobile')}
                inputClassName="form-control"
                id="phoneno"
                required="required"
                value={mobileNumber.value}
                error={mobileNumber.error}
              />
              <TextInput
                divClassName="form-group pb-3"
                type="email"
                placeholder="Email"
                onChange={(e) => handleChange(e, 'Username')}
                inputClassName="form-control"
                id="email"
                required="required"
                value={username.value}
                error={username.error}
              />
              <TextInput
                divClassName="form-group pb-3 password-input"
                type="password"
                placeholder="Password"
                onChange={(e) => handleChange(e, 'Password')}
                inputClassName="form-control"
                id="password-input"
                required="required"
                value={password.value}
                error={password.error}
              />
            </form>
            <CustomButton
              divClassName='pb-2'
              className='btn blue-button w-100 font-weight-bold mt-2'
              onClick={handleSubmit}
              text='Sign Up'
              disabled={!firstName.value || firstName.error || !lastName.value || lastName.error || !mobileNumber.value || mobileNumber.error || !username.value || username.error || !password.value || password.error}
            />
            <div className="sideline"><span>By clicking the button above, you agree to our Terms of Service and Privacy Policy.
            </span></div>
            <div className="pt-4 text-center">
              Already have an account?<Link className="signin-link" to={UIRoutes.SIGNIN + (referenceId ? '?referralCode=' + referenceId : '')}>Sign In </Link>
            </div>
          </div>
          <div className="shape1"></div>
          <div className="shape2"></div>
          <div className="shape3"></div>
          <div className="shape4"></div>
        </div>
      </div>
    </div>
  </>
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, { registerAction })(Signup)