import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import SideMenu from '../component/common/SideMenu'
import { UIRoutes } from '../utils/FrontendRoutes'

let RestrictedRoute = ({ component: Component, auth, page, ...rest }) => (
  <Route
    {...rest}
    render={
      (props) =>
        auth.isAuthenticated
          ?
          <SideMenu {...props}>
            <Component {...props} page={page} />
          </SideMenu>
          :
          <Redirect to={UIRoutes.SIGNIN + (new URLSearchParams(props.location.search).get("referralCode") ? '?referralCode=' + new URLSearchParams(props.location.search).get("referralCode") : '')} />
    }
  />
)

RestrictedRoute.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})

export default connect(mapStateToProps)(RestrictedRoute)
