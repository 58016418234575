import { ADD_COUPON, DELETE_COUPON, GET_COUPONS } from "../actions/types"

const initialState = {
  list: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_COUPON:
      return { ...state, list: [...state.list, action.payload] }
    case GET_COUPONS:
      return { ...state, list: action.payload }
    case DELETE_COUPON:
      const tempCoupons = [...state.list]
      tempCoupons[action.payload.index] = { ...tempCoupons[action.payload.index], isDeleted: !tempCoupons[action.payload.index].isDeleted }
      return { ...state, list: tempCoupons }
    default:
      return state
  }
}