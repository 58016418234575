import { ADD_AWARDS, DELETE_AWARDS, GET_AWARDS } from "../actions/types"

const initialState = {
  list: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_AWARDS:
      return { ...state, list: [...state.list, action.payload] }
    case GET_AWARDS:
      return { ...state, list: action.payload }
    case DELETE_AWARDS:
      const tempAwards = [...state.list]
      tempAwards.splice(action.payload.index, 1)
      return { ...state, list: [...tempAwards] }
    default:
      return state
  }
}