import React, { useState } from 'react'
import { connect } from 'react-redux'
import CustomButton from "../common/CustomButton"
import { addAwards } from '../../actions/awardActions'

const AddAward = ({ ...props }) => {
  const [source, setSource] = useState('')
  const [type, setType] = useState({ value: 'company-achievements', error: null })

  const handleChange = (event, calledFor) => {
    let tempValue = { value: event.target.value, error: '' }
    if (!tempValue.value) {
      tempValue = { value: event.target.value, error: null }
    }
    switch (calledFor) {
      case 'Type':
        setType(tempValue)
        break
      case 'Source':
        let reader = new FileReader()
        let file = document.querySelector('input[type=file]').files[0]
        reader.readAsDataURL(file)
        setTimeout(
          () => {
            setSource(reader)
          }, 1500)
        break
    }
  }

  const handleSave = () => {
    const requestBody = {
      source: source.result,
      type: type.value
    }
    props.addAwards(requestBody)
  }

  return <>
    <div className="container">
      <div className="row justify-content-center text-center">
        <div className="col-lg-8 shadow-lg p-3 mb-5 bg-body rounded">
          <h3 className="text-dark main-title-border mb-3">Add Award </h3>
          <div className="">
            <input
              divClassName="form-group pb-3"
              type="file"
              accept={"image/png, image/jpg, image/jpeg"}
              placeholder="Award/Achievement Image"
              onChange={(e) => handleChange(e, 'Source')}
              inputClassName="form-control"
              id="image"
              required="required"
            />
          </div>
          <div className=" pb-3 mt-3">
            <select className="text-left d-block addproduct-category" placeholder="Select Type" onChange={(e) => handleChange(e, 'Type')}>
              <option value="company-achievements">Company Achievements</option>
              <option value="achievements-of-partner-companies">Achievements of Partner Companies</option>
              <option value="individual-achievements">Individual Achievements</option>
            </select>
          </div>
          <div className="col-lg-12 d-flex justify-content-center mt-4">
            <CustomButton
              divClassName='pb-2 w-25'
              className='btn blue-button w-100 font-weight-bold mt-2'
              onClick={handleSave}
              text='Save'
              disabled={!type.value || type.error || !source}
            />
          </div>
        </div>
      </div>
    </div>
  </>
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, { addAwards })(AddAward)