import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Card, Badge, Row, Col } from 'react-bootstrap'
import copy from 'copy-to-clipboard'
import '../css/pages/Aboutus.css'
import '../css/common/Banner.css'
import { getProducts, deleteProducts } from '../../actions/productActions'
import { ImageAssets } from '../../utils/ImageAssets'
import { UIRoutes } from '../../utils/FrontendRoutes'
import CustomButton from '../common/CustomButton'

const ProductsListing = ({ ...props }) => {
  const categoryId = props.match.params.categoryId
  const userCode = JSON.parse(localStorage.getItem('user'))._id

  useEffect(() => {
    props.getProducts(categoryId, JSON.parse(localStorage.getItem('user')).role)
  }, [])

  const viewProduct = (categoryId, id) => {
    props.history.push(UIRoutes.PRODUCTS + categoryId + '/' + id)
  }

  const handleChange = (id, index) => {
    props.deleteProducts(id, index)
  }

  const handleLinkClick = (categoryId, id, link) => {
    // navigator.clipboard.writeText('http://www.careerconnectedu.com/' + UIRoutes.PRODUCTS + categoryId + '/' + id + '/' + '?referralCode=' + userCode)
    copy('http://www.careerconnectedu.com/' + UIRoutes.PRODUCTS + categoryId + '/' + id + '/' + '?referralCode=' + userCode)
    alert('Link copied to your clipboard')
  }

  return <>
    <section id="main" className="productlisting clearfix" role="main">
      <div className='container productlisting-details shadow-lg p-3 mb-5 bg-body rounded'>
        <h3 className="text-dark main-title-border mb-2">All Products</h3>
        <div className='row'>
          <Row xs={1} sm={1} md={2} lg={4} xl={4}>
            {
              props.list.map((eachProduct, index) => {
                return (
                  <Col>
                    <Card className='mb-5'>
                      <div className='linkcard'>
                        <span onClick={() => handleLinkClick(eachProduct.category._id, eachProduct._id, eachProduct.link + '?referralCode=' + userCode)} className="copy_text">
                          <i className="fa fa-link" aria-hidden="true"></i>
                        </span>
                        <input type="checkbox" checked={eachProduct.isActive} onChange={() => handleChange(eachProduct._id, index)} id={"checkbox" + index} />
                        <label for={"checkbox" + index}></label>
                      </div>
                      <Card.Img variant="top" src={eachProduct.image || ImageAssets.common.logo} />
                      <Card.Body className='text-center'>
                        <Card.Title>{eachProduct.name}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted"><span dangerouslySetInnerHTML={{ __html: eachProduct.description }}></span></Card.Subtitle>
                        <Badge variant='primary'>{eachProduct.category.name}</Badge>
                      </Card.Body>
                      <Card.Footer>
                        <small className="text-muted">
                          <Row className='text-center'>
                            <Col className='review'><span>₹ {eachProduct.price}</span></Col>
                            <Col>
                              <span>
                                <CustomButton
                                  divClassName='pb-2'
                                  className='btn blue-button w-100 font-weight-bold mt-2'
                                  onClick={() => viewProduct(eachProduct.category._id, eachProduct._id)}
                                  text='View More'
                                />
                              </span>
                            </Col>
                          </Row>
                        </small>
                      </Card.Footer>
                    </Card>
                  </Col>
                )
              })
            }
          </Row>
        </div>
      </div>
    </section>
  </>
}

const mapStateToProps = state => ({
  list: state.products.list
})

export default connect(mapStateToProps, { getProducts, deleteProducts })(ProductsListing)