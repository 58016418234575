import { AUTH_SET_USER } from '../actions/types'

const initialState = {
  isAuthenticated: false,
  email: '',
  firstName: '',
  lastName: '',
  role: 'USER',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case AUTH_SET_USER:
      return {
        ...state,
        isAuthenticated: action.payload.email ? true : false,
        email: action.payload.email,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        role: action.payload.role
      }
    default:
      return state
  }
}