const apiPath = 'https://cc-kspx.onrender.com/'

export const APIRoutes = {
  USER_SIGNUP: apiPath + 'users/register',
  USER_SIGNIN: apiPath + 'users/login',
  USER_FORGET_PASSWORD: apiPath + 'users/forgot',
  USER_VERIFY_EMAIL: apiPath + 'users/activate/',
  ADD_CATEGORY: apiPath + 'categories',
  GET_CATEGORY: apiPath + 'categories',
  PUT_CATEGORY: apiPath + 'categories/',
  DELETE_CATEGORY: apiPath + 'categories/',
  GET_PRODUCTS: apiPath + 'products/',
  GET_SINGLE_PRODUCT: apiPath + 'products/single/',
  ADD_PRODUCTS: apiPath + 'products',
  DELETE_PRODUCTS: apiPath + 'products/',
  GET_USERS: apiPath + 'users',
  GET_USERS_TREE: apiPath + 'users/',
  UPDATE_USER_ROLE: apiPath + 'users/role',
  INITIATE_PAYMENT: apiPath + 'payments',
  VERIFY_PAYMENT: apiPath + 'payments/verify',
  GET_EARNINGS: apiPath + 'payments/earnings/',
  GET_PAYMENTS: apiPath + 'payments/',
  GET_PAYMENT_LOGS: apiPath + 'payments/logs/',
  ADD_COUPON: apiPath + 'coupons',
  GET_COUPONS: apiPath + 'coupons',
  DELETE_COUPONS: apiPath + 'coupons/',
  ADD_GALLERY: apiPath + 'galleries',
  GET_GALLERY: apiPath + 'galleries',
  DELETE_GALLERY: apiPath + 'galleries/',
  ADD_AWARDS: apiPath + 'awards',
  GET_AWARDS: apiPath + 'awards',
  DELETE_AWARDS: apiPath + 'awards/',
  GET_NETWORK: apiPath + 'networks/',
  ADD_FRANCHISE_INQUIRY: apiPath + 'contacts/franchise',
  ADD_CONTACT_INQUIRY: apiPath + 'contacts',
  GET_USER_REPORT: apiPath + 'reports/users'
}