export const ImageAssets = {
    common: {
        logo: require("../images/common/logo.png")
    },
    banner4: {
        banner4: require("../images/page/home/banner4.jpg")
    },
    banner1: {
        banner1: require("../images/page/home/banner1.jpg")
    },
    banner2: {
        banner2: require("../images/page/home/banner2.jpg")
    },
    banner3: {
        banner3: require("../images/page/home/banner3.jpg")
    },
    banner5: {
        banner5: require("../images/page/home/boost-post-for-fb.jpg")
    },
    Dhaycaree: {
        Dhaycaree: require("../images/page/banner/dheyacareeprice.png")
    },
    Arrow: {
        Arrow: require("../images/common/arrow.svg"),
        Dots: require("../images/common/dots.svg")
    },
    PRODUCTSSERVICES: {
        PRODUCTSSERVICES1: require("../images/page/home/product-services1.jpg"),
        PRODUCTSSERVICES2: require("../images/page/home/COMMON-1.jpg"),
        PRODUCTSSERVICES3: require("../images/page/home/NEW-DHEYA.png"),
        PRODUCTSSERVICES4: require("../images/page/home/protecon.jpg"),
        PRODUCTSSERVICES5: require("../images/page/home/smart.jpg"),
        // PRODUCTSSERVICES6: require("../images/page/home/timts.jpg"),
        PRODUCTSSERVICES6: require("../images/page/home/GetSetGo.png"),
        PRODUCTSSERVICES7: require("../images/page/home/newimg/a.png"),
        PRODUCTSSERVICES8: require("../images/page/home/newimg/b.png"),
        PRODUCTSSERVICES12: require("../images/page/home/newimg/c.png"),
        PRODUCTSSERVICES9: require("../images/page/home/newimg/MLA-3.png"),
        PRODUCTSSERVICES10: require("../images/page/home/newimg/CC1.png"),
        PRODUCTSSERVICES11: require("../images/page/home/newimg/Win-WinWhitelabelAppSolution.png"),
    },
    DownloadLeaflet: {
        DownloadLeaflet: require("../images/page/home/CareerFounder-Final-A4.pdf"),
        msg: require("../images/page/home/call_to_action_icon.png"),
    },
    NEWSEVENTS: {
        NEWSEVENTS1: require("../images/page/home/NEWSEVENTS1.jpg"),
        NEWSEVENTS2: require("../images/page/home/NEWSEVENTS3.jpg"),
        NEWSEVENTS3: require("../images/page/home/NEWSEVENTS2.jpg"),
    },
    managementteam: {
        managementteam1: require("../images/page/aboutus/parthiv.png"),
        managementteam2: require("../images/page/aboutus/sonal.png"),
    },
    banner: {
        cfbanner: require("../images/page/banner/career-founder.jpg"),
        aicrabanner: require("../images/page/banner/aicra.jpg"),
        stemedu: require("../images/page/home/banner2.jpg"),
        stemedu1: require("../images/page/banner/stemedu2.webp"),
    },
    blog: {
        blog1: require("../images/page/blog/blog1.jpg"),
        blog2: require("../images/page/blog/blog2.jpg"),
        blog3: require("../images/page/blog/blog3.jpg"),
        blog4: require("../images/page/blog/blog4.png"),
        blog4main: require("../images/page/blog/blog4.jpg"),
        blog4details: require("../images/page/blog/CLASSROOM-TO-CAREER-blog4.jpg"),
    },
    blog1: {
        blog1: require("../images/page/blog/blog1/blog1.jpg"),
        blog2: require("../images/page/blog/blog1/blog2.jpg"),
        blog3: require("../images/page/blog/blog1/blog3.jpg"),
        blog4: require("../images/page/blog/blog1/blog4.jpg"),
        blog5: require("../images/page/blog/blog1/blog5.jpg"),
        blog6: require("../images/page/blog/blog1/blog6.jpg"),
        blog7: require("../images/page/blog/blog1/blog7.jpg"),
        blog8: require("../images/page/blog/blog1/blog8.jpg"),
    },
    PastEvents: {
        PastEvents1: require("../images/page/blog/blog1.jpg"),
        PastEvents2: require("../images/page/blog/pastevent2.jpg"),
        PastEvents3: require("../images/page/blog/pastevent3.jpg"),
        PastEvents4: require("../images/page/blog/pastevent4.jpg"),
        PastEvents5: require("../images/page/blog/pastevent5.jpg"),
    },
    Gallery: {
        gallery1: require("../images/page/gallery/Anand_Workshop/1.jpeg"),
        gallery2: require("../images/page/gallery/Anand_Workshop/2.jpeg"),
        gallery3: require("../images/page/gallery/Anand_Workshop/3.jpeg"),
        gallery4: require("../images/page/gallery/Anand_Workshop/4.jpeg"),
        gallery5: require("../images/page/gallery/Anand_Workshop/5.jpeg"),
        gallery6: require("../images/page/gallery/Anand_Workshop/6.jpeg"),
        gallery7: require("../images/page/gallery/Anand_Workshop/7.jpeg"),
        gallery8: require("../images/page/gallery/Anand_Workshop/8.jpeg"),
        gallery9: require("../images/page/gallery/Anand_Workshop/9.jpeg"),
        gallery10: require("../images/page/gallery/Anand_Workshop/10.jpeg"),
        gallery11: require("../images/page/gallery/Stem_India/11.jpg"),
        gallery12: require("../images/page/gallery/Stem_India/12.jpg"),
        gallery13: require("../images/page/gallery/Stem_India/13.jpg"),
        gallery14: require("../images/page/gallery/Stem_India/14.jpg"),
        gallery15: require("../images/page/gallery/Stem_India/15.jpg"),
        gallery16: require("../images/page/gallery/Stem_India/16.jpg"),
        gallery17: require("../images/page/gallery/Stem_India/17.jpg"),
        gallery18: require("../images/page/gallery/Stem_India/18.jpg"),
        gallery19: require("../images/page/gallery/Stem_India/19.jpg"),
        gallery20: require("../images/page/gallery/Technoxian/20.jpg"),
        gallery21: require("../images/page/gallery/Technoxian/21.jpg"),
        gallery22: require("../images/page/gallery/Technoxian/22.jpg"),
        gallery23: require("../images/page/gallery/Technoxian/23.png"),
        gallery24: require("../images/page/gallery/Technoxian/24.jpg"),
        gallery25: require("../images/page/gallery/Technoxian/25.jpg"),
        gallery26: require("../images/page/gallery/Technoxian/26.jpg"),
        gallery27: require("../images/page/gallery/Technoxian/27.jpg"),
        gallery28: require("../images/page/gallery/Technoxian/28.jpg"),
        gallery29: require("../images/page/gallery/isro-exhibition/29.jpeg"),
        gallery30: require("../images/page/gallery/isro-exhibition/30.jpeg"),
        gallery31: require("../images/page/gallery/isro-exhibition/31.jpeg"),
        gallery32: require("../images/page/gallery/isro-exhibition/32.jpeg"),
        gallery33: require("../images/page/gallery/isro-exhibition/33.jpeg"),
        gallery34: require("../images/page/gallery/isro-exhibition/34.jpeg"),
        gallery35: require("../images/page/gallery/isro-exhibition/35.jpeg"),
        gallery36: require("../images/page/gallery/isro-exhibition/36.jpeg"),
        gallery37: require("../images/page/gallery/isro-exhibition/37.jpeg"),
        gallery38: require("../images/page/gallery/isro-exhibition/38.jpeg"),
        gallery39: require("../images/page/gallery/isro-exhibition/39.jpeg"),
        gallery40: require("../images/page/gallery/isro-exhibition/40.jpeg"),
        gallery41: require("../images/page/gallery/isro-exhibition/41.jpeg"),
        gallery42: require("../images/page/gallery/isro-exhibition/42.jpeg"),
        gallery43: require("../images/page/gallery/isro-exhibition/43.jpeg"),
    },
    Achievements: {
        gallery1: require("../images/page/Awards_Achievements/Achievements_of_Partner_companies/1.jpg"),
        gallery2: require("../images/page/Awards_Achievements/Individual_Achievements/1.jpg"),
        gallery3: require("../images/page/Awards_Achievements/Individual_Achievements/2.jpg"),
    },
    Model: {
        Model1: require("../images/page/banner/GUJRATI-POST-1.jpg"),
        Model2: require("../images/page/home/newimg/Sai-Paridhi-CF-Ad.jpg"),
        Model3: require("../images/page/home/newimg/a.png"),
        Model4: require("../images/page/home/newimg/b.png"),
        Model6: require("../images/page/home/newimg/c.png"),
        Model5: require("../images/page/home/newimg/careerfounder.png"),
        PlayButton: require("../images/page/home/newimg/playstore.png"),
        CBSE_approved: require("../images/page/home/newimg/cbse_approved.jpg")
    },
    Aboutus: {
        aboutus: require("../images/page/aboutus/aboutus.jpeg"),
        aboutus1: require("../images/page/aboutus/lightbulb.gif"),
        aboutus2: require("../images/page/aboutus/vision.gif"),
        aboutusmain: require("../images/page/aboutus/about-img.png"),
        dheyacareer1: require("../images/page/aboutus/dheya1.png"),
        dheyacareer2: require("../images/page/aboutus/dheya2.png"),
        dheyacareer3: require("../images/page/aboutus/dheya3.png"),
        dheyacareer4: require("../images/page/aboutus/dheya4.png"),
        dheyacareer5: require("../images/page/aboutus/dheya5.png"),
        dheyacareer6: require("../images/page/aboutus/dheya6.png"),
        smartschool: require("../images/page/aboutus/smart-school.jpg"),
        usericon: require("../images/page/aboutus/user.png"),
        aboutHome: require("../images/page/home/career-img.png"),
    },
    whiteapp: {
        app: require("../images/page/banner/app.png"),
        app1: require("../images/page/banner/app1.png"),
        app2: require("../images/page/banner/app2.png"),
        app3: require("../images/page/banner/app3.jpg"),
        app4: require("../images/page/banner/app4.jpg"),
        app5: require("../images/page/banner/app5.png"),
        app6: require("../images/page/banner/app6.png"),
        app7: require("../images/page/banner/app7.png"),
    },
    Getsetgo: {
        getsetgo1: require("../images/page/getsetgo/Skill_hub_thumbnail.png"),
        getsetgo2: require("../images/page/getsetgo/art_Payment.jpg"),
        getsetgo3: require("../images/page/getsetgo/season_2.png"),
        getsetgo4: require("../images/page/getsetgo/purple1.png"),
        getsetgo5: require("../images/page/getsetgo/Screenshot_(359).png"),
        getsetgo6: require("../images/page/getsetgo/Thumbnail1.jpeg"),
        getsetgo7: require("../images/page/getsetgo/Thumbnail.jpeg"),
        getsetgo8: require("../images/page/getsetgo/BulletProofWarrior.jpg"),
        getsetgo9: require("../images/page/getsetgo/WhatsApp_Image_2021-05-15_at_11_27_57_AM.jpeg"),
        getsetgo10: require("../images/page/getsetgo/SLT.jpeg"),
        getsetgo11: require("../images/page/getsetgo/thumbnail.jpg"),
        getsetgo12: require("../images/page/getsetgo/success_habits.jpg"),
        getsetgo13: require("../images/page/getsetgo/5.png"),
        getsetgo14: require("../images/page/getsetgo/Thumbnail_HQ.png"),
        getsetgo15: require("../images/page/getsetgo/2.jpg"),
        getsetgo16: require("../images/page/getsetgo/gsg-affiliate-partner.jpg"),
        getsetgo17: require("../images/page/getsetgo/REcorded_Sessions2.png"),
        getsetgo18: require("../images/page/getsetgo/EffectiveCounselingSecrets.jpg"),
        getsetgo19: require("../images/page/getsetgo/cpb-final-2.jpg"),
        getsetgo20: require("../images/page/getsetgo/328409-PA7RGA-278.jpg"),
        getsetgo21: require("../images/page/getsetgo/freedom_90day_challenge.jpg"),
        getsetgo22: require("../images/page/getsetgo/webinar_selling_formula.jpg"),
        getsetgo23: require("../images/page/getsetgo/internet_traffic_blueprint.jpg"),
        getsetgo24: require("../images/page/getsetgo/freedom_inner_circle_vault.jpg"),
        getsetgo25: require("../images/page/getsetgo/freedom_hall_of_fame.jpg"),
        getsetgo26: require("../images/page/getsetgo/facebook_traffic_mastery.jpg"),
        getsetgo27: require("../images/page/getsetgo/funnel_building_formula.jpg"),
        getsetgo28: require("../images/page/getsetgo/freedom_leadgen.jpg"),
        getsetgo29: require("../images/page/getsetgo/freedom_business_blueprint.jpg"),
        getsetgo30: require("../images/page/getsetgo/sbb-thumbnail.jpg"),
    }
}

export default ImageAssets