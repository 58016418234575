import { GET_USERS, GET_USERS_TREE } from "../actions/types"

const initialState = {
  list: [],
  network: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
      return { ...state, list: action.payload }
    case GET_USERS_TREE:
      return { ...state, network: action.payload }
    default:
      return state
  }
}