import axios from "axios"
import { toast } from "react-toastify"
import { APIRoutes } from "../utils/BackendRoutes"
import { getErrorMessage } from "../utils/GetErrorMessage"
import setLoading from "../utils/LoadingManager"
import { GET_MY_NETWORK } from "./types"

export const getNetwork = (id) => dispatch => {
  setLoading(true)
  axios.get(APIRoutes.GET_NETWORK + id)
    .then(res => {
      dispatch({
        type: GET_MY_NETWORK,
        payload: res.data.data
      })
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}