export const UIRoutes = {
  // User auth routes
  SIGNUP: '/sign-up',
  SIGNIN: '/sign-in',
  FORGETPASSWORD: '/forget-password',
  RESET_PASSWORD: '/reset-password/:time/:email',
  POLICY: '/policy',
  CANCELLATIONREFUNDPOLICY: '/Cancellation-and-Refund-Policy',
  TERMS: '/terms',
  CONTACTUS: '/contact-us',
  AICRI: '/AICRI',
  CAREERFOUNDER: '/career-founder',
  DHEYACAREERMENTORS: '/DheyaCareerMentors',
  PROTECONPRODUCT: '/protecon-product',
  STEMEDUCATION: '/STEMEducation',
  TIMTS: '/TIMTS',
  SMARTSCHOOL: '/smart-school',
  GETSETGO: '/GetSetGo',
  WINWHITELABEL: '/WIN-WIN WHITELABEL',
  ABOUTUS: '/aboutus',
  GALLERY: '/gallery',
  AWARDSACHIEVEMENTS: '/awards-achievements',
  BLOG: '/blog',
  BLOGSINGLE: '/blog-single',
  UPCOMINGEVENTS: '/upcoming-events',
  PASTEVENTS: '/past-events',
  HOME: '/',
  CATEGORIES: '/categories',
  DASHBOARD: '/dashboard',
  ADD_PRODUCT: '/product/add',
  PRODUCTS: '/products/',
  PRODUCT_LIST: '/products/:categoryId',
  VIEW_PRODUCT: '/products/:categoryId/:id',
  USERS_LIST: '/users',
  USERDETAILSTREE: '/tree/users',
  FRANCHISEFROM: '/franchise-form',
  BUY_PRODUCT: '/buy-product',
  COUPON_LIST: '/coupons',
  ADD_COUPON: '/coupons/add',
  NETWORK: '/network/',
  MY_NETWORK: '/network/:id',
  ACCOUNT_ACTIVATE: '/activate/:email/:id',
  GALLERY_LIST: '/gallery/list',
  ADD_GALLERY: '/gallery/add',
  AWARD_LIST: '/awards/list',
  ADD_AWARD: '/awards/add',
  USERS_REPORT: '/reports/users',
  BLOG1: '/blog/power-in-you-students-parents-seminar-by-mrs-nisha-butani',
  BLOG2: '/blog/career-founder',
  BLOG3: '/blog/career-connect',
  BLOG4: '/upcoming-events/classroom-to-career/',
  BLOG5: '/upcoming-events/career-founder-new-branch-opnening-at-tithal-road',
  BLOG6: '/past-events/technoxian-giant-festival',
  BLOG7: '/past-events/aicra-india-stem-summit-awards-2019-on-march-082019-in-new-delhi/',
  BLOG8: '/past-events/technoxian-2018/',
  BLOG9: '/past-events/aicra-india-stem-summit-awards-2018',
  COMMISSION: '/Commission',
  CFAPPLICATION:'/Career_founder_mobile_learning_application',
  COUNCELINGDMIT:'/Counceling_Dmit/',
  COUNCELINGPSYCOMETRIC:'/Counceling_Psycometric/'
}