import axios from "axios"
import { toast } from "react-toastify"
import { APIRoutes } from "../utils/BackendRoutes"
import { getErrorMessage } from "../utils/GetErrorMessage"
import setLoading from "../utils/LoadingManager"

export const addFranchiseInquiry = (body) => dispatch => {
  setLoading(true)
  axios.post(APIRoutes.ADD_FRANCHISE_INQUIRY, body)
    .then(res => {
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const addContactInquiry = (body) => dispatch => {
  setLoading(true)
  axios.post(APIRoutes.ADD_CONTACT_INQUIRY, body)
    .then(res => {
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}