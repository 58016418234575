import React from "react"
import { Link } from "react-router-dom"

const Breadcrums = ({ ...props }) => {
    return (
        <>
            <div className={props.divClassName}>
                <div className="opacity-medium bg-extra-dark-gray bg-opacity-color"></div>
            </div>
            <div className="breadcrumb">
                <ul>
                    {JSON.parse(props.breadcrums).map(eachkey => {
                        return (<li><Link to={eachkey.value}>{eachkey.key}</Link></li>)
                    })}
                </ul>
            </div>
        </>
    )
}
export default Breadcrums