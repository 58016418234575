import React, { useEffect } from 'react'
import '../css/pages/Aboutus.css'
import '../css/common/Banner.css'
import ImageAssets from '../../utils/ImageAssets'
import Breadcrums from '../common/Banner'
import { UIRoutes } from '../../utils/FrontendRoutes'
import AOS from "aos";
import "aos/dist/aos.css";

const Aboutus = () => {
    useEffect(() => {
        AOS.init({
            duration: 1200
        });
        AOS.refresh();
    }, []);
    return <>
        <section id="main" className="Aboutus clearfix" role="main">
            <Breadcrums divClassName="about-banner main-banner"
                breadcrums={JSON.stringify([{ key: "Home", value: UIRoutes.HOME }, { key: "About Us", value: UIRoutes.ABOUTUS }])}
            />
            <div className='container' data-aos="fade-up" >
                <div className='row mt-5' >
                    <div className='col-lg-5 d-flex flex-column mx-auto justify-content-center' data-aos="fade-up-left">
                        <div className="contents section-header">
                            <h2 className="section-title wow fadeIn animated" data-wow-delay="0.2s"> About Us </h2>
                        </div>

                        <div className='text-justify'>
                            <p > <span className='font-weight-bold'>“Career Connect”</span> by its name is self-explanatory, i.e. establishing a connection between an individual's likes, aptitude and talent to his/her appropriate career.</p>
                            <p >It is India's fastest growing career planning as well as educational products &amp; services Provider Company.</p>
                            <p >It is the brainchild of a couple with an intelligent, vision viz,<br/> Mr .Parthiv Desai and Mrs. Sonal Desai.</p>
                        </div>
                        {/* <p> “Career Connect” by its name is self - explanatory, i.e.establishing a connection between an individual’ s likes, aptitude and talent to his / her appropriate career.
                            It is India’ s fastest growing career planning as well as educational products &amp; amp;
                            services Provider Company.
                            It is the brainchild of a couple with an intelligent, vision viz, < br />
                            MR.Parthiv Desai and Mrs.Sonal Desai. </p> */}
                    </div>
                    <div className='col-lg-6'>
                        <img width="1280" height="855" src={ImageAssets.Aboutus.aboutusmain}
                            className='about-us-img' alt="" loading="lazy" data-aos="fade-up-right" />
                    </div>
                </div >
            </div>
            <div className='agenda'>
                <div className="container" >
                    <div className='row' >
                        <div className="col-lg-12" data-aos="fade-left">
                            <div className="contents section-header">
                                <h2 className="section-title white wow fadeIn animated" data-wow-delay="0.2s"> AGENDA</h2>
                            </div>                            <ul className='method-detalis'>
                                <li> ‘Career Connect’ aims to nurture new talent and capitalize young India which is:
                                    <ul>
                                        <li> Below 35 years age group which is about 65 % population and </li>
                                        <li> Below 25 years age group which is about 50 % population </li>
                                    </ul >
                                </li>
                                <li> Youth is the future of any nation and so we believe that in helping them grow we are contributing to the growth of the nation.We strongly believe that every individual is unique and have their own potential to grow if guided &amp; amp;progress in the right way.‘Career Connect’ is engaged in some specialized &amp; amp; futuristic products &amp; amp;services to empower the youth with knowledge &amp; amp;increase their employability. </li>
                            </ul>
                            <ul className='method-detalis'>
                                <li> We, the CAREER CONNECT team, aim to provide all of this under the same roof. </li>
                            </ul>
                        </div>
                    </div >
                </div>
            </div >
            <section id="values" className="values mt-5 mb-5">
                <div className="container my-5">
                    <div className="row">
                        <div className="col-md-6 p-0 text-center ">
                            <img width="1280" height="855" src={ImageAssets.Aboutus.aboutus1}
                                className='mission-vision' alt="" loading="lazy" />                        </div>
                        <div className="col-md-6 p-4 p-sm-5 d-flex flex-column mx-auto justify-content-center">
                            <div className="shadow-lg p-3 mb-5 bg-white rounded"  data-aos="flip-left">
                                <div className="card-body" >
                                    <div className="contents section-header">
                                        <h2 className="section-title wow fadeIn animated" data-wow-delay="0.2s"> Mission </h2>
                                    </div>
                                    {/* <h2 className="card-title"> <a href="" > Mission </a></h2 > */}
                                    <p className="card-text" > “Consistent efforts to nurture new talent and capitalized our Human Assets, the young India(65 % population below 35 years &amp; 50 % population below 25 years age group) for the growth of Nation by contributing to enhance their talent &amp; increase the employability with Ethical business code of conducts in self &amp; National interest.” </p>
                                </div >
                            </div>

                        </div>
                        <div className='order-change d-flex'>

                            <div className="col-md-6 p-4 p-sm-5  d-flex flex-column mx-auto justify-content-center">
                                <div className="shadow-lg p-3 mb-5 bg-white rounded" data-aos="flip-right">
                                    <div className="card-body" >
                                        <div className="contents section-header">
                                            <h2 className="section-title wow fadeIn animated" data-wow-delay="0.2s"> Vision </h2>
                                        </div>
                                        {/* <h2 className="card-title"> <a href=""> Vision </a></h2> */}
                                        <p className="card-text">                                   
                                        Impacting the lives of 1 lakh students from August 15, 2022, to August 15, 2025, across India, including micro-rural and urban areas, by nurturing their talents and providing them with the best career-oriented conceptual training to help them achieve their career goals.
                                        </p>
                                    </div >
                                </div>

                            </div>
                            <div className="col-md-6 p-0 text-center">
                                <img width="1280" height="855" src={ImageAssets.Aboutus.aboutus2}
                                    className='mission-vision' alt="" loading="lazy" />                        </div>
                        </div>
                    </div>
                </div>

                {/* <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="200">
                            <div className="card Mission" >
                                <div className="card-body" >
                                    <h5 className="card-title"> <a href="" > Mission </a></h5 >
                                    <p className="card-text" > “Consistent efforts to nurture new talent and capitalized our Human Assets, the young India(65 % population below 35 years &amp; 50 % population below 25 years age group) for the growth of Nation by contributing to enhance their talent &amp; increase the employability with Ethical business code of conducts in self &amp; National interest.” </p>
                                </div >
                            </div>
                        </div>
                        <div className="col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="200">
                            <div className="card Vision">
                                <div className="card-body" >
                                    <h5 className="card-title"> <a href=""> Vision </a></h5>
                                    <p className="card-text"> Impacting 1 Lacs students Life on or before 15 th Aug 2022 across India including micro rural &amp; urban area by nurturing their talent &amp; providing them best career oriented conceptual training to achieve their Career Goal. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>
            <div className='methods'>
                <div className='container'>
                    <div className='row' >
                        <div className="col-lg-12" data-aos="fade-right">
                            <div className="contents section-header pb-3 mt-3 mb-1 my-sm-4">
                                <h2 className="section-title white wow fadeIn animated" data-wow-delay="0.2s">MEthods </h2>
                            </div>
                            <ul className='method-detalis'>
                                <li> To achieve the above, we use some specialized and futuristic products and services;
                                    this includes:
                                    <ol className='list-disc'>
                                        <li> Career Research, Decision &amp; Planning </li>
                                        <li> Academic Support </li>
                                        <li> Teaching techniques </li>
                                        <li> STP Formula </li>
                                        <li> Smart Tutorial </li>
                                        <li> Training Programme </li>
                                    </ol>
                                </li>
                                <li> Robotics and Automation, AI, IOT etc. </li>
                                <li> STEM(science, technology, engineering, mathematics) </li> <li> Stream from kindergarten to post graduation(KG to PG) </li> <li> We have a huge product basket catering to a wide and a diverse clientele base which includes kids to middle age group, KG to PG, schools to colleges and the corporates to the industries.This helps us achieve our goals in an easy and a smarter way. </li> <li> For Engineering graduates specialized training programs to make them employable with or without job guarantee. </li> </ul>
                            <p className='method-detalis'> We believe and strictly practice Ethical Business code of conduct in self and National interest. </p>

                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="container">
                    <div className='row'>
                        <div className="col-lg-12">
                            <div className="contents section-header pb-3 mt-3 mb-1 my-sm-4">
                                <h2 className="section-title wow fadeIn animated" data-wow-delay="0.2s"> The Management Team </h2>
                            </div>
                        </div>
                        <div className="col-lg-6" data-aos="zoom-in-left">
                            <div className="About-module_person-oVuIt About-module_personDee-bUUve" >
                                <div className='dots-bg'>
                                    <img draggable="false" className="Dots"
                                        alt="➡" src={ImageAssets.Arrow.Dots} />
                                    <img src={ImageAssets.managementteam.managementteam1} className='about-us-img home-about-main' alt="Dee Vazquez smiling." />
                                </div>
                                <div>
                                    <h3> MR. PARTHIV DESAI: </h3>
                                </div>
                                <div className="About-module_bio-zRXvc">
                                    <p>The founder, a diverse and multifaceted gentleman. His qualifications are BSc with chemistry and MBA in marketing. He carries with him 23 years of experience in a variety of fields like FMCG (fast moving consumer goods), OTC, INSURANCE, REAL ESTATE, ORGANIC FARMING and CAREER CONUSELLING. He has been associated with Godrej Pillsbury Ltd, Surya Herbal Ltd, Nippo Batteries Ltd and ICICI Prudential LIC Ltd before venturing out on his own. He is also associated with Ronvel Orchids, Valsad which deals in real estate. He is very actively involved with BNI (Captivators Chapter, Valsad Vapi Region) which facilitates business networking and enhancing it. He is at present committed to career and personal- social counselling and life skills programme with school student and middle age group segment. Besides having organized many blood donation camps, he himself is a blood donor. He too is socially connected since 1989 with Gayatri Parivar, Anavil Parivar (committee member, Valsad), Kalayatan (music activities), Rang Pooja Theatres (drama activities), Valsad Raktdan Kendra (Blood Donation camp Organizer &amp; Apheresis Donner).</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6" data-aos="zoom-in-right">
                            <div className="About-module_person-oVuIt About-module_personDee-bUUve" >
                                <div className='dots-bg'>
                                    <img draggable="false" className="Dots"
                                        alt="➡" src={ImageAssets.Arrow.Dots} />
                                    <img src={ImageAssets.managementteam.managementteam2} className='about-us-img home-about-main' alt="Dee Vazquez smiling." />
                                    {/* <img width="1280" height="855" src={ImageAssets.Aboutus.aboutus}
                                        className='about-us-img home-about-main' alt="" loading="lazy" /> */}
                                </div>

                                <div>
                                    <h3> MRS. SONAL PARTHIV DESAI:</h3>
                                </div>
                                <div className="About-module_bio-zRXvc">
                                    <p> The co-founder, a dynamic lady with 21 years of academic experience. Her qualifications are BSc (Mathematics), MSc (Mathematics) and MBA in Marketing.</p>
                                    <p> Her Teaching Experience:</p>
                                    <ul className='method-detalis'>
                                        <li>While studying, she has been an ad – hoc lecturer for 9 years at Shri. B.K.M. Science College, Valsad and also a private Tutor. She has taught about 4000 students with the number increasing every day.</li>
                                        <li>She has been a visiting faculty for renowned institutes like K.P.S. Commerce College, Valsad, DUAIS, Valsad and ROFEL College, Vapi.</li>
                                        <li>She has an active social connect with social clubs like Anavil Parivar, Gayatri Parivar and the Innerwheel Club of Bulsar.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <blockquote><p>
                                We at CAREER CONNECT, humbly strive to professionally and ethically guide young India from all walks of life towards their right careers and hence contribute to a more productive India.
                            </p></blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}
export default Aboutus