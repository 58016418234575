import axios from "axios"
import { toast } from "react-toastify"
import { APIRoutes } from "../utils/BackendRoutes"
import { getErrorMessage } from "../utils/GetErrorMessage"
import setLoading from "../utils/LoadingManager"
import { ADD_COUPON, GET_COUPONS, DELETE_COUPON } from "./types"

export const addCoupon = (coupon) => (dispatch) => {
  setLoading(true)
  axios.post(APIRoutes.ADD_COUPON, coupon)
    .then(res => {
      dispatch({
        type: ADD_COUPON,
        payload: coupon
      })
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const getCoupons = () => (dispatch) => {
  setLoading(true)
  axios.get(APIRoutes.GET_COUPONS)
    .then(res => {
      dispatch({
        type: GET_COUPONS,
        payload: res.data.data
      })
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const deleteCoupons = (id, index) => (dispatch) => {
  setLoading(true)
  axios.delete(APIRoutes.DELETE_COUPONS + id)
    .then(res => {
      dispatch({
        type: DELETE_COUPON,
        payload: { id, index }
      })
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}