import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getCoupons, deleteCoupons } from '../../actions/couponActions'
import Table from 'react-bootstrap/Table';
import '../css/pages/Aboutus.css'

const CouponListing = ({ ...props }) => {

  useEffect(() => {
    props.getCoupons()
  }, [])

  const handleChange = (id, index) => {
    props.deleteCoupons(id, index)
  }

  return <>
    <section id="main" className="couponlisting clearfix shadow-lg p-3 mb-5 bg-body rounded" role="main">
    <h3 className="text-dark main-title-border ">Coupon Listing</h3>
      <Table striped bordered hover responsive="md">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Name</th>
            <th>Discount</th>
            <th>on/off</th>
          </tr>
        </thead>
        <tbody>
          {
            props.list.map((eachCoupon, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>{eachCoupon.name}</td>
                  <td>{eachCoupon.percentageDisc ? eachCoupon.percentageDisc + ' %' : eachCoupon.amountDisc + ' ₹'}</td>
                  <td>
                    <input type="checkbox" checked={!eachCoupon.isDeleted} onChange={() => handleChange(eachCoupon._id, index)} id={"checkbox" + index} />
                    <label for={"checkbox" + index}></label>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    </section>
  </>
}

const mapStateToProps = (state) => ({
  list: state.coupons.list
})

export default connect(mapStateToProps, { getCoupons, deleteCoupons })(CouponListing)