import { toast } from 'react-toastify'
import React, { Component } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import RedirectProfileRoute from './interceptors/RedirectProfileRoute'
import './interceptors/RefreshToken'
import ScrollToTop from './interceptors/ScrollToTop'
import { UIRoutes } from './utils/FrontendRoutes'
import store from './store'

// Common components
import Header from './component/common/Header'
import Footer from './component/common/Footer'

//pages
import Contactus from './component/pages/Contactus'
import AICRI from './component/pages/AICRI'
import CareerFounder from './component/pages/CareerFounder'
import DheyaCareerMentors from './component/pages/DheyaCareerMentors'
import ProteconProduct from './component/pages/ProteconProduct'
import STEMEducation from './component/pages/STEMEducation'
import TIMTS from './component/pages/TIMTS'
import SmartSchool from './component/pages/SmartSchool'
import GetSetGo from './component/pages/GetSetGo'
import Aboutus from './component/pages/Aboutus'
import Gallery from './component/pages/Gallery'
import Blog from './component/pages/Blog'
import BlogSingle from './component/pages/BlogSingle'
import PastEvents from './component/pages/PastEvent'
import UpcomingEvents from './component/pages/UpcomingEvents'
import Home from './component/pages/Home'
import CategoryListing from './component/pages/CategoryListing'
import AwardsAchievements from './component/pages/AwardsAchievements'
import FranchiseFrom from './component/pages/FranchiseFrom'
import ViewProduct from './component/pages/ViewProduct'
import Dashboard from './component/dashboard/Dashboard'
import CouponListing from './component/coupon/CouponListing'
import AddCoupons from './component/coupon/AddCoupons'
import GalleryListing from './component/gallery/GalleryListing'
import AddGallery from './component/gallery/AddGallery'
import AwardListing from './component/awards/AwardListing'
import AddAward from './component/awards/AddAward'
import UsersReport from './component/reports/UsersReport'
import MyNetwork from './component/network/MyNetwork'

//blogs
import Blog1 from './component/singleblogs/Blog1'
import Blog2 from './component/singleblogs/Blog2'
import Blog3 from './component/singleblogs/Blog3'
import Blog4 from './component/singleblogs/Blog4'
import Blog5 from './component/singleblogs/Blog5'
import Blog6 from './component/singleblogs/Blog6'
import Blog7 from './component/singleblogs/Blog7'
import Blog8 from './component/singleblogs/Blog8'
import Blog9 from './component/singleblogs/Blog9'

// Auth components
import Signup from './component/authentication/Signup/Signup'
import Signin from './component/authentication/Signin/Signin'
import ForgetPassword from './component/authentication/ForgetPassword/ForgetPassword'
import Policy from './component/policy/Policy'
import Terms from './component/policy/Terms'
import CancellationandRefundPolicy from './component/policy/CancellationandRefundPolicy'
import { AUTH_SET_USER } from './actions/types'
import ProductsListing from './component/pages/ProductsListing'
import AdminPrivateRoute from './interceptors/AdminPrivateRoute'
import PrivateRoute from './interceptors/PrivateRoute'
import PublicRoute from './interceptors/PublicRoute'
import AddProducts from './component/pages/AddProducts'
import Users from './component/pages/Users'
import ActivateAccount from './component/pages/ActivateAccount'
import ResetPassword from './component/authentication/ResetPassword/ResetPassword'
import Winwhitelabel from './component/pages/Winwhitelabel'
import UserDetailsTree from './component/pages/UserDetailsTree'
import Commission from './component/pages/Commission'
import ApplicationLicense from './component/pages/ApplicationLicense'
import CouncelingDmit from './component/pages/CouncelingDmit'
import CouncelingPsycometric from './component/pages/CouncelingPsycometric'

if (localStorage.user) {
  // set user details to store
  store.dispatch({ type: AUTH_SET_USER, payload: JSON.parse(localStorage.getItem('user')) })
}

// display toast message position
toast.configure({
  autoClose: 5000,
  position: 'bottom-right',
  hideProgressBar: true
})

class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <Router>
          <ScrollToTop>
            <Header />
            <Switch>
              {/*Home */}
              <PublicRoute exact sensitive path={UIRoutes.HOME} component={Home} />
              {/* User auth routes */}
              <RedirectProfileRoute exact sensitive path={UIRoutes.SIGNUP} component={Signup} />
              <RedirectProfileRoute exact sensitive path={UIRoutes.SIGNIN} component={Signin} />
              {/* FORGETPASSWORD */}
              <RedirectProfileRoute exact sensitive path={UIRoutes.FORGETPASSWORD} component={ForgetPassword} />
              {/* RESET PASSWORD */}
              <RedirectProfileRoute exact sensitive path={UIRoutes.RESET_PASSWORD} component={ResetPassword} />
              {/*APCRI */}
              <PublicRoute exact sensitive path={UIRoutes.AICRI} component={AICRI} />
              {/* CareerFounder */}
              <PublicRoute exact sensitive path={UIRoutes.CAREERFOUNDER} component={CareerFounder} />
              {/* DheyaCareerMentors */}
              <PublicRoute exact sensitive path={UIRoutes.DHEYACAREERMENTORS} component={DheyaCareerMentors} />
              {/* ProteconProduct */}
              <PublicRoute exact sensitive path={UIRoutes.PROTECONPRODUCT} component={ProteconProduct} />
              {/* STEMEducation */}
              <PublicRoute exact sensitive path={UIRoutes.STEMEDUCATION} component={STEMEducation} />
              {/* TIMTS */}
              <PublicRoute exact sensitive path={UIRoutes.TIMTS} component={TIMTS} />
              {/* SmartSchool */}
              <PublicRoute exact sensitive path={UIRoutes.SMARTSCHOOL} component={SmartSchool} />
              {/* GetSetGo */}
              <PublicRoute exact sensitive path={UIRoutes.GETSETGO} component={GetSetGo} />
              {/* WINWHITELABEL */}
              <PublicRoute exact sensitive path={UIRoutes.WINWHITELABEL} component={Winwhitelabel} />
              {/* ApplicationLicense */}
              <PublicRoute exact sensitive path={UIRoutes.CFAPPLICATION} component={ApplicationLicense} />
              {/* /CouncelingDmit */}
              <PublicRoute exact sensitive path={UIRoutes.COUNCELINGDMIT} component={CouncelingDmit} />
              {/* CouncelingPsycometric */}
              <PublicRoute exact sensitive path={UIRoutes.COUNCELINGPSYCOMETRIC} component={CouncelingPsycometric} />
              {/* Aboutus */}
              <PublicRoute exact sensitive path={UIRoutes.ABOUTUS} component={Aboutus} />
              {/* Contact us */}
              <PublicRoute exact sensitive path={UIRoutes.CONTACTUS} component={Contactus} />
              {/* gallery */}
              <PublicRoute exact sensitive path={UIRoutes.GALLERY} component={Gallery} />
              {/* AwardsAchievements */}
              <PublicRoute exact sensitive path={UIRoutes.AWARDSACHIEVEMENTS} component={AwardsAchievements} />
              {/* Blog */}
              <PublicRoute exact sensitive path={UIRoutes.BLOG} component={Blog} />
              {/* BlogSingle */}
              <PublicRoute exact sensitive path={UIRoutes.BLOGSINGLE} component={BlogSingle} />
              {/* Policy */}
              <PublicRoute exact sensitive path={UIRoutes.POLICY} component={Policy} />
              {/* CancellationandRefundPolicy */}
              <PublicRoute exact sensitive path={UIRoutes.CANCELLATIONREFUNDPOLICY} component={CancellationandRefundPolicy} />
              {/* Terms */}
              <PublicRoute exact sensitive path={UIRoutes.TERMS} component={Terms} />
              {/* UpcomingEvents */}
              <PublicRoute exact sensitive path={UIRoutes.UPCOMINGEVENTS} component={UpcomingEvents} />
              {/* PastEvents */}
              <PublicRoute exact sensitive path={UIRoutes.PASTEVENTS} component={PastEvents} />
              {/* Franchise Form */}
              <PublicRoute exact sensitive path={UIRoutes.FRANCHISEFROM} component={FranchiseFrom} />
              {/* Activate Account */}
              <PublicRoute exact sensitive path={UIRoutes.ACCOUNT_ACTIVATE} component={ActivateAccount} />
              {/* Blogs */}
              <PublicRoute exact sensitive path={UIRoutes.BLOG1} component={Blog1} />
              <PublicRoute exact sensitive path={UIRoutes.BLOG2} component={Blog2} />
              <PublicRoute exact sensitive path={UIRoutes.BLOG3} component={Blog3} />
              <PublicRoute exact sensitive path={UIRoutes.BLOG4} component={Blog4} />
              <PublicRoute exact sensitive path={UIRoutes.BLOG5} component={Blog5} />
              <PublicRoute exact sensitive path={UIRoutes.BLOG6} component={Blog6} />
              <PublicRoute exact sensitive path={UIRoutes.BLOG7} component={Blog7} />
              <PublicRoute exact sensitive path={UIRoutes.BLOG8} component={Blog8} />
              <PublicRoute exact sensitive path={UIRoutes.BLOG9} component={Blog9} />

              {/* Dashboard links */}
              <PrivateRoute exact sensitive path={UIRoutes.DASHBOARD} component={Dashboard} />
              <AdminPrivateRoute exact sensitive path={UIRoutes.CATEGORIES} component={CategoryListing} />
              <PrivateRoute exact sensitive path={UIRoutes.PRODUCT_LIST} component={ProductsListing} />
              <PrivateRoute exact sensitive path={UIRoutes.VIEW_PRODUCT} component={ViewProduct} />
              <PrivateRoute exact sensitive path={UIRoutes.MY_NETWORK} component={MyNetwork} />
              <AdminPrivateRoute exact sensitive path={UIRoutes.ADD_PRODUCT} component={AddProducts} />
              <AdminPrivateRoute exact sensitive path={UIRoutes.COUPON_LIST} component={CouponListing} />
              <AdminPrivateRoute exact sensitive path={UIRoutes.ADD_COUPON} component={AddCoupons} />
              <AdminPrivateRoute exact sensitive path={UIRoutes.GALLERY_LIST} component={GalleryListing} />
              <AdminPrivateRoute exact sensitive path={UIRoutes.ADD_GALLERY} component={AddGallery} />
              <AdminPrivateRoute exact sensitive path={UIRoutes.AWARD_LIST} component={AwardListing} />
              <AdminPrivateRoute exact sensitive path={UIRoutes.ADD_AWARD} component={AddAward} />
              <AdminPrivateRoute exact sensitive path={UIRoutes.USERS_REPORT} component={UsersReport} />
              <AdminPrivateRoute exact sensitive path={UIRoutes.USERS_LIST} component={Users} />
              <PrivateRoute exact sensitive path={UIRoutes.USERDETAILSTREE} component={UserDetailsTree} />
              <PrivateRoute exact sensitive path={UIRoutes.COMMISSION} component={Commission} />
            </Switch>
            <Footer />
          </ScrollToTop>
        </Router>
      </Provider>
    )
  }
}

export default App
