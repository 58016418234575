import React from 'react'
import '../css/ContactUs/ContactUs.css'
import '../css/common/Banner.css'
import Breadcrums from '../common/Banner'
import { Card, Button } from 'react-bootstrap'
import { UIRoutes } from '../../utils/FrontendRoutes'
import '../css/pages/Aboutus.css'
import { ImageAssets } from '../../utils/ImageAssets'

const GetSetGo = () => {
    return <>
        <section id="main" className="Smart-School clearfix" role="main">
            <Breadcrums divClassName="Getsetgo-banner main-banner"
                breadcrums={JSON.stringify([{ key: "Home ", value: UIRoutes.HOME }, { key: "Get Set Go", value: UIRoutes.GETSETGO }])}
            />
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="contents section-header pb-3 mt-3 mb-1 my-sm-4">
                            <h2 className="section-title  wow fadeIn animated" data-wow-delay="0.2s">Get Set Go </h2>
                        </div>
                        {/* <h3 className="text-center mb-4 mt-4">Get Set Go</h3> */}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/skill-hub?rf=584658671&course=skill-hub'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/skill-hub?rf=584658671&course=skill-hub'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo1} />
                                <Card.Body>
                                    <Card.Title>Skill Hub</Card.Title>
                                    <Card.Text className='text-center'>   ENTREPRENEURSHIP                                    </Card.Text>
                                    <Card.Text className='text-center'>   Are you someone who has an entrepreneurial drive but cannot start the journey because of a lack of funds, lack of systems, or inability to understand what to do or from where to start?  Then this is a Golden opportunity for you.  We present to you GetSetGo World’s - Skill HUB Partner Program, Where you have an opportunity to start your Learn and Earn entrepreneur journey</Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/counselling-part-payment?rf=584658671&course=counselling-part-payment'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/counselling-part-payment?rf=584658671&course=counselling-part-payment'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo2} />
                                <Card.Body>
                                    <Card.Title>Counselling - Part Payment</Card.Title>
                                    <Card.Text className='text-center'>PROFESSIONAL</Card.Text>
                                    <Card.Text className='text-center'>Counselling Franchise Fees Part  Payment</Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/social-media-mastery?rf=584658671&course=social-media-mastery'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/social-media-mastery?rf=584658671&course=social-media-mastery'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo3} />
                                <Card.Body>
                                    <Card.Title>Social Media Mastery</Card.Title>
                                    <Card.Text className='text-center'>                                    DIGITAL BRANDING                                    </Card.Text>
                                    <Card.Text>You can Say DIGITAL CHANGE. In this era, it's very easy to reach maximum people in a very short span of time... It's like the whole world is waiting for you, just you need to identify the way or tool to connect with them and that's the tool is Social</Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/social-media-mastery-women-entrepreneurs?rf=584658671&course=social-media-mastery-women-entrepreneurs'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/social-media-mastery-women-entrepreneurs?rf=584658671&course=social-media-mastery-women-entrepreneurs'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo4} />
                                <Card.Body>
                                    <Card.Title>Social Media Mastery - Women Entrepreneurs</Card.Title>
                                    <Card.Text className='text-center'>DIGITAL BRANDING </Card.Text>
                                    <Card.Text className='text-center'>  To Practice Have you ever thought about why most Talented or Knowledgeable person suffers in this Digital Era?  Yes, you are right!!!  Because they are not able to cope up with the change. You can Say DIGITAL CHANGE. In this era, it's very easy to reach maximum people in a very short span of time... It's like the whole world is waiting for you, just you need to identify the way or tool to connect with them and that's the tool is Social Media.  </Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/dominate-your-year?rf=584658671&course=dominate-your-year'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/dominate-your-year?rf=584658671&course=dominate-your-year'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo5} />
                                <Card.Body>
                                    <Card.Title>Dominate Your Year</Card.Title>
                                    <Card.Text className='text-center'>SELF DEVELOPMENT</Card.Text>
                                    <Card.Text className='text-center'>Deciding to set goals is one of the most important things you can do to improve your life. However, nearly 98% of people take the time to set clear goals for themselves in their life. It's essential for you to think about where you want to be, whether it's in one month, one year, or one decade from today.</Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/entrepreneurship-development-partner?rf=584658671&course=entrepreneurship-development-partner'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/entrepreneurship-development-partner?rf=584658671&course=entrepreneurship-development-partner'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo6} />
                                <Card.Body>
                                    <Card.Title>Entrepreneurship Development Partner</Card.Title>
                                    <Card.Text className='text-center'>   ENTREPRENEURSHIP   </Card.Text>
                                    <Card.Text className='text-center'>    Get Set Go World an online edutech company is giving you all an opportunity to start your journey towards Hub / Community Building. By becoming GSG's Entrepreneurship Development Partner you will learn all those tools & techniques.  </Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/laser-focus?rf=584658671&course=laser-focus'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/laser-focus?rf=584658671&course=laser-focus'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo7} />
                                <Card.Body>
                                    <Card.Title>Laser Focus</Card.Title>
                                    <Card.Text className='text-center'>
                                        SELF DEVELOPMENT                                    </Card.Text>
                                    <Card.Text className='text-center'>
                                        Electronic devices and instant internet gratification have shortened our attention spans to that of small furry mammals, but what the above statistic shows is that this is no joke at all. In this course, we will learn how to Increase our Focus.                                    </Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/bulletproof-warrior-mindset?rf=584658671&course=bulletproof-warrior-mindset'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/bulletproof-warrior-mindset?rf=584658671&course=bulletproof-warrior-mindset'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo8} />
                                <Card.Body>
                                    <Card.Title>Bulletproof Warrior Mindset</Card.Title>
                                    <Card.Text className='text-center'>
                                        SELF DEVELOPMENT
                                    </Card.Text>
                                    <Card.Text className='text-center'>
                                        What is the warrior mindset? Simply, this is an approach to life that might resemble the approach we imagine a warrior would have taken.   In this course, we are going to take a closer look at what is meant by the term ‘warrior mindset’ and we are going                                    </Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/art-of-counselling?rf=584658671&course=art-of-counselling'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/art-of-counselling?rf=584658671&course=art-of-counselling'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo9} />
                                <Card.Body>
                                    <Card.Title>Art of Counselling</Card.Title>
                                    <Card.Text className='text-center'>   PROFESSIONAL </Card.Text>
                                    <Card.Text className='text-center'>   The Art of Counselling course gives you an insight into understanding the Human Brain. After completing this course you will be able to know your own self better and you will also be able to understand others.. It doesn't matter what your profession is,  or what you currently do... This course will help you everywhere..                                    </Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/super-learning-techniques?rf=584658671&course=super-learning-techniques'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/super-learning-techniques?rf=584658671&course=super-learning-techniques'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo10} />
                                <Card.Body>
                                    <Card.Title>super Learning Techniques</Card.Title>
                                    <Card.Text className='text-center'>BRAIN & MEMORY
                                    </Card.Text>
                                    <Card.Text className='text-center'>SUPER LEARNING TECHNIQUES is one of the best solutions for students struggling in their academics.  Most school examinations are designed to test the memorizing skills or the recalling skills of a student and not if the student has really understood the concepts.  This is why many bright students struggle to score well, as they find it difficult to memorize their notes. </Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/business-success-habits?rf=584658671&course=business-success-habits'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/business-success-habits?rf=584658671&course=business-success-habits'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo11} />
                                <Card.Body>
                                    <Card.Title>BUSINESS SUCCESS HABITS
                                    </Card.Title>
                                    <Card.Text className='text-center'>
                                        BUSINESS DEVELOPMENT                                  </Card.Text>
                                    <Card.Text className='text-center'>
                                        Running your own business and steering it on a path to success is a constant challenge. That’s partly because there’s no structure in place when you start. There's no boss, there's no organization, there's no training manual - you have to start from scratch and create the structure and processes yourself. Many of us find ourselves managing our own businesses even when we never imagined it.                                   </Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/success-habits?rf=584658671&course=success-habits'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/success-habits?rf=584658671&course=success-habits'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo12} />
                                <Card.Body>
                                    <Card.Title>SUCCESS HABITS</Card.Title>
                                    <Card.Text className='text-center'>SELF DEVELOPMENT
                                    </Card.Text>
                                    <Card.Text className='text-center'>Do you want to finally eliminate your bad habits and form new and good habits instead? If you ever wondered and said 'yes' to any of the above, then  What's The Solution? Everyone wants to be more successful in their life, but actually knowing how to take the steps needed to achieve that success can be mystifying.  </Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/junior-champs-club?rf=584658671&course=junior-champs-club'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/junior-champs-club?rf=584658671&course=junior-champs-club'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo13} />
                                <Card.Body>
                                    <Card.Title>JUNIOR CHAMPS CLUB
                                    </Card.Title>
                                    <Card.Text className='text-center'>CLUB</Card.Text>
                                    <Card.Text className='text-center'>Nowadays they are on mobile for almost the whole day and we don’t know how much productive hours they spent and “It’s the battle cry of millions of parents,” Does this happen at your house too? By keeping this in mind we have launched Junior Champs Club Junior Champs Club is an exclusive club for young children aged 4 to 14years. </Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/advance-course?rf=584658671&course=advance-course'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/advance-course?rf=584658671&course=advance-course'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo14} />
                                <Card.Body>
                                    <Card.Title>Visual Creative Bootcamp
                                    </Card.Title>
                                    <Card.Text className='text-center'>
                                        DIGITAL MARKETING
                                    </Card.Text>
                                    <Card.Text className='text-center'>
                                        visuals can make all the difference to whether someone looks at your content or not. People love visuals because they have an immediate emotional impact and they're easy to take in at a glance. No matter what industry you're in, visual content should be an important component of your content marketing strategy. In the past, only large companies could afford to invest in visuals.
                                    </Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/super-kid-development?rf=584658671&course=super-kid-development'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/super-kid-development?rf=584658671&course=super-kid-development'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo15} />
                                <Card.Body>
                                    <Card.Title>Super Kid Development
                                    </Card.Title>
                                    <Card.Text className='text-center'>
                                        BRAIN & MEMORY
                                    </Card.Text>
                                    <Card.Text className='text-center'>
                                        An online cognitive assessment is conducted to find out your child's talent, core mental abilities, strengths & skill set. It tests your child's IQ, focus, speed, creativity & learning style. As a parent, you get many invaluable insights about your child's real learning nature. Based on the test analysis, you get personalized activities, worksheets, unique experimental tasks, guidance and recommendations for your child.                                       </Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/affiliate-marketing-blueprint-batch-8?rf=584658671&course=affiliate-marketing-blueprint-batch-8'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/affiliate-marketing-blueprint-batch-8?rf=584658671&course=affiliate-marketing-blueprint-batch-8'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo16} />
                                <Card.Body>
                                    <Card.Title>GSG Affiliate Partner
                                    </Card.Title>
                                    <Card.Text className='text-center'>
                                        DIGITAL BUSINESS
                                    </Card.Text>
                                    <Card.Text className='text-center'>
                                        Affiliate marketing is something anyone can do. The great thing about affiliate websites is that if you build them correctly, they'll mature over time and reward you month after month, year after year in passive income.  However, if you build them incorrectly, you will simply be wasting your time. This course is created to show you how you can build a successful affiliate business now, in 2020.                                    </Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/recorded-session-smm-9?rf=584658671&course=recorded-session-smm-9'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/recorded-session-smm-9?rf=584658671&course=recorded-session-smm-9'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo17} />
                                <Card.Body>
                                    <Card.Title>SMM Recorded Session
                                    </Card.Title>
                                    <Card.Text className='text-center'>   DIGITAL MARKETING
                                    </Card.Text>
                                    <Card.Text className='text-center'>   You can Say DIGITAL CHANGE. In this era, it's very easy to reach maximum people in a very short span of time... It's like the whole world is waiting for you, just you need to identify the way or tool to connect with them and that's the tool is Social Media. It's very important to create your presence online and Social Media helps you to connect with the whole world                                    </Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/career-planning-blueprint?rf=584658671&course=career-planning-blueprint'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/career-planning-blueprint?rf=584658671&course=career-planning-blueprint'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo19} />
                                <Card.Body>
                                    <Card.Title>Career Planning Blueprint

                                    </Card.Title>
                                    <Card.Text className='text-center'>CAREER</Card.Text>
                                    <Card.Text className='text-center'>Career seems a six letter word but many people invest their 60years of life and they fail to achive the right & succesfull career but you don't need to worry, you are at the right place at the right time because this course "Career Planning Blueprint" will help you to know first about yourself, Your Personalities, strenths, Improvement areas, Likes & Dislikes then it will take you through the journey of different types of people & their mindset </Card.Text>

                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/how-to-design-career-scientifically?rf=584658671&course=how-to-design-career-scientifically'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/how-to-design-career-scientifically?rf=584658671&course=how-to-design-career-scientifically'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo20} />
                                <Card.Body>
                                    <Card.Title>How to Design Career Scientifically ?
                                    </Card.Title>
                                    <Card.Text className='text-center'>CAREER</Card.Text>
                                    <Card.Text className='text-center'>If you are confused about Your child's Career or an Academics, or you are an individual who is confused about his own career selection or not satisfied with his or her own academic performance or maybe you are in the hunt to improve your career & academics then you are right here... This course is for you, This course gives a clear picture of the various types of personalities, types of learning styles, types of thinking style & types of behaviour style.</Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/freedom-90-days-challenge?rf=584658671&course=freedom-90-days-challenge'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/freedom-90-days-challenge?rf=584658671&course=freedom-90-days-challenge'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo21} />
                                <Card.Body>
                                    <Card.Title>Freedom 90 Days Challenge
                                    </Card.Title>
                                    <Card.Text className='text-center'>DIGITAL BUSINESS
                                    </Card.Text>
                                    <Card.Text className='text-center'>After the freedom business model and freedom setup challenge, this is the level up course which will help you to actually set up your own Financial Freedom Business. This is a 90-day course in which  mentor of Mr Siddharth Rajsekar will be guiding you through the whole journey of becoming financially free. </Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/we?rf=584658671&course=we'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/we?rf=584658671&course=we'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo22} />
                                <Card.Body>
                                    <Card.Title>Webinar Selling Formula
                                    </Card.Title>
                                    <Card.Text className='text-center'>DIGITAL BUSINESS
                                    </Card.Text>
                                    <Card.Text className='text-center'>How to structure the webinar? How to effectively present our message through webinar to the participants? Such questions must be running in your mind about webinar. So lets get the answers to all such questions through this course. In this course, Siddharth Rajsekar, a famous life coach has clearly explained what are the tips and tricks for an effective webinar.</Card.Text>

                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/internet-traffic-blueprint?rf=584658671&course=internet-traffic-blueprint'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/internet-traffic-blueprint?rf=584658671&course=internet-traffic-blueprint'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo23} />
                                <Card.Body>
                                    <Card.Title>Internet Traffic Blueprint
                                    </Card.Title>
                                    <Card.Text className='text-center'>
                                        DIGITAL MARKETING                                    </Card.Text>
                                    <Card.Text className='text-center'>The more traffic you get to your website, funnel, sales page, promotion, or business, the more sales you will make.According to a recent survey of over 4,500 entrepreneurs and business owners, the single biggest challenge they’re having is traffic generation: the ability to generate targeted, quality traffic (visitors) to see what they have to offer or sell. </Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/freedom-inner-circle-vault?rf=584658671&course=freedom-inner-circle-vault'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/freedom-inner-circle-vault?rf=584658671&course=freedom-inner-circle-vault'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo24} />
                                <Card.Body>
                                    <Card.Title>Freedom Inner Circle Vault
                                    </Card.Title>
                                    <Card.Text className='text-center'>    DIGITAL BUSINESS    </Card.Text>
                                    <Card.Text className='text-center'> In this course, you will learn from the Successful life coach and entrepreneur Siddharth Rajshaker how to start with your system with the right attitude. This course contains some of those webinars containing real life examples going on the path of financial freedom who talk and share their experiences and struggles. So if you want to get a more clearer vision of the financial freedom then you are at the right place to go!</Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/hall-of-fame?rf=584658671&course=hall-of-fame'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/hall-of-fame?rf=584658671&course=hall-of-fame'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo25} />
                                <Card.Body>
                                    <Card.Title>Hall Of Fame
                                    </Card.Title>
                                    <Card.Text className='text-center'>
                                        DIGITAL BUSINESS
                                    </Card.Text>
                                    <Card.Text className='text-center'>Here are a few success stories of people who have successfully completed the Freedom Business Model and Freedom Set up Challenge course bundles and have applied it to set up their online business. They have shared their journey from day 1, they started and after 90 days they had an amazing result.  So lets learn from the practical coaches how to make our system more stronger, innovative and interesting! </Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/facebook-traffic-mastery?rf=584658671&course=facebook-traffic-mastery'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/facebook-traffic-mastery?rf=584658671&course=facebook-traffic-mastery'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo26} />
                                <Card.Body>
                                    <Card.Title>Facebook Traffic Mastery
                                    </Card.Title>
                                    <Card.Text className='text-center'>DIGITAL MARKETING
                                    </Card.Text>
                                    <Card.Text className='text-center'>
                                        Facebook is a social media which is used by almost everyone. It is a good source to generate traffic. In this course, we will go through a few number of strategies for generating the traffic into your hub. Skills like making advertisements, creating sizzling videos, how to lazer target on facebook and achieving the lowest CPL (cost per lead) will be discussed in this course.                                     </Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/funnel-building-formula?rf=584658671&course=funnel-building-formula'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/funnel-building-formula?rf=584658671&course=funnel-building-formula'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo27} />
                                <Card.Body>
                                    <Card.Title>Funnel Building Formula

                                    </Card.Title>
                                    <Card.Text className='text-center'>DIGITAL MARKETING
                                    </Card.Text>
                                    <Card.Text className='text-center'>
                                        Through this community, he will walk you through you a proven "Freedom Business Gameplan" that smart digital entrepreneurs are using to create a profitable digital business along with a lifestyle of flexibility.                     </Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/freedom-leadgen-challenge?rf=584658671&course=freedom-leadgen-challenge'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/freedom-leadgen-challenge?rf=584658671&course=freedom-leadgen-challenge'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo28} />
                                <Card.Body>
                                    <Card.Title>Freedom Leadgen Challenge
                                    </Card.Title>
                                    <Card.Text className='text-center'>DIGITAL MARKETING                                    </Card.Text>
                                    <Card.Text className='text-center'>
                                        After going through the ideology of freedom in business through digital marketing in freedom business model course, this course is here to help you with setting up your sales funnel and email marketing system. After this course, you will be able to setup your own funnel and also will learn to setup your email marketing system which can be integrated with your sales funnel. </Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>

                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/freedom-business-blueprint?rf=584658671&course=freedom-business-blueprint'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/freedom-business-blueprint?rf=584658671&course=freedom-business-blueprint'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo29} />
                                <Card.Body>
                                    <Card.Title>Freedom Business Blueprint
                                    </Card.Title>
                                    <Card.Text className='text-center'>DIGITAL MARKETING                                    </Card.Text>
                                    <Card.Text className='text-center'>
                                        Do you want to understand the world of business? Do you want to learn about the freedom of business? Here is a short course with all the details you require to understand the freedom of business.So if you are eager to go on the path of freedom of business, this course is just for you.   </Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/super-brain-booster?rf=584658671&course=super-brain-booster'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/super-brain-booster?rf=584658671&course=super-brain-booster'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo30} />
                                <Card.Body>
                                    <Card.Title>Student's Brain Booster
                                    </Card.Title>
                                    <Card.Text className='text-center'>BRAIN & MEMORY</Card.Text>
                                    <Card.Text className='text-center'>
                                        Our lifestyles are majorly dependent on technologies. We fail to use our capabilities and qualities of our Brain because technology has made most of our work very simple due to which we have reduced the uses of our memorization skills.      It has been said that we have 100billion neurons in our brain. Whenever we learn or memorize something new they communicate with each other and form a new connection between them. </Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <a href='https://getsetgoworld.com/course/effective-counseling-secrets?rf=584658671&course=effective-counseling-secrets'>
                            <Card>
                                <div className='linkcard'>
                                    <span className="copy_text">
                                        <a href='https://getsetgoworld.com/course/effective-counseling-secrets?rf=584658671&course=effective-counseling-secrets'>
                                            <i className="fa fa-link" aria-hidden="true">   </i>
                                        </a>
                                    </span>
                                    {/* <input type="checkbox"  id={"checkbox" + index} />
                                <label for={"checkbox" + index}></label> */}
                                </div>
                                <Card.Img variant="top" src={ImageAssets.Getsetgo.getsetgo18} />
                                <Card.Body>
                                    <Card.Title>Effective Counseling Secrets
                                    </Card.Title>
                                    <Card.Text className='text-center'>COUNSELOR</Card.Text>
                                    <Card.Text className='text-center'>
                                        This course is for the expert who loves to Guide & Transform peoples lives. Who believes that there is a solution for everything, who helps an individual to understand themselves. The one who is having good experience of his / her life & ready to share and guide people for the same. This course is for the person who is a good listener and can counsel people with passion. In this course, there are various secrets to tackle confusion or challenges about career planning, Relationship, Education & Personal Growth, Let's First understand about career. In </Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default GetSetGo