import React, { useState, useEffect } from 'react'
import '../css/pages/Home.css'
import Slider from "react-slick";
import { Modal } from 'react-bootstrap'
import ImageAssets from '../../utils/ImageAssets'
import { UIRoutes } from '../../utils/FrontendRoutes'
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import AOS from "aos";
import "aos/dist/aos.css";


const Home = ({ ...props }) => {
  const [show, setShow] = useState(true);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const handleClose = () => setShow(false);

  useEffect(() => {
    AOS.init({
      duration: 1200
      // once: true
    });
    AOS.refresh();
  }, []);

  const redirectToAllProducts = () => {
    props.history(UIRoutes.PRODUCT_LIST)
  }

  return <>
    <section id="main" className="Home clearfix" role="main">
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton className="justify-content-end border-bottom-0">
          {/* <Modal.Title>
            ADMISSION FOR 2022-23 FOR CBSE/ICSE/ISC/GSEB BOARDS NOW OPEN AT CAREER FOUNDER			</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className='text-center'>
          <Carousel variant="dark" fade>
            <Carousel.Item>
              <div className="app-header-button111">
                <a className="btn" target={'_blank'} href="https://play.google.com/store/apps/details?id=com.careerfounder" title="Google Play">
                  {/* <img className="appbtn" src={ImageAssets.Model.PlayButton} alt=" model img" /> */}
                  <img className="Model1 horizontal-logo" src={ImageAssets.Model.Model5} alt=" model img" />
                </a>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <a className="btn" target={'_blank'} href="https://play.google.com/store/apps/details?id=com.careerfounder" title="Google Play">
                <img className="Model1 horizontal-logo" src={ImageAssets.Model.Model3} alt=" model img" />
              </a>               {/* <Carousel.Caption>
              <h5>First slide label</h5>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption>  */}
            </Carousel.Item>
            <Carousel.Item>
              <a className="btn" target={'_blank'} href="https://play.google.com/store/apps/details?id=com.careerfounder" title="Google Play">
                <img className="Model1 horizontal-logo" src={ImageAssets.Model.Model4} alt=" model img" />
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <a className="btn" target={'_blank'} href="https://play.google.com/store/apps/details?id=com.careerfounder" title="Google Play">
                <img className="Model1 horizontal-logo" src={ImageAssets.Model.Model6} alt=" model img" />
              </a>
            </Carousel.Item>
          </Carousel>
        </Modal.Body>
      </Modal>
      <div className='container-fluid home-slider mb-5' data-aos="zoom-in">
        <Slider {...settings}>
          <div className="home-banner first-slider-home">
            <img className="logo horizontal-logo" src={ImageAssets.banner4.banner4} alt=" logo" />
            <div className="centered">
              <h1 className='text-light'>Career Connect </h1>
              <p className='text-light'>It is India's fastest growing Career Planning as well as Educational products and services provider company </p>
              <div className='d-flex justify-content-around'>
                <button className="btn btn-light">
                  <Link className="nav-link" to={UIRoutes.ABOUTUS}>About Us</Link>
                </button>
                <button className="btn btn-light">
                  <Link className="nav-link" to={UIRoutes.CONTACTUS}>Contact Us</Link>
                </button>
              </div>
            </div>
          </div>
          <div className="home-banner">
            <a to={UIRoutes.HOME}>
              <img className="logo horizontal-logo" src={ImageAssets.banner1.banner1} alt="home slider" />
            </a>
          </div>
          <div className="home-banner">
            <a to={UIRoutes.HOME}>
              <img className="logo horizontal-logo" src={ImageAssets.banner2.banner2} alt="home slider" />
            </a>
          </div>
          {/* <div className="home-banner">
            <a to={UIRoutes.HOME}>
              <img className="logo horizontal-logo" src={ImageAssets.banner3.banner3} alt="home slider" />
            </a>
          </div> */}
          {/* <div className="home-banner">
            <a to={UIRoutes.HOME}>
              <img className="logo horizontal-logo" src={ImageAssets.banner5.banner5} alt="home slider" />
            </a>
          </div> */}
        </Slider>
      </div>
      <section className="about-us text-center">
        <div className="jumbotron jumbotron-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 d-flex flex-column mx-auto justify-content-center" data-aos="fade-right">
                {/* <h3 className='pb-3'>About Our Company</h3> */}
                <div className="contents section-header pb-3">
                  <h2 className="section-title white wow fadeIn animated" data-wow-delay="0.2s"> About Our Company </h2>
                </div>
                <p > <span className='font-weight-bold'>“Career Connect”</span> by its name is self-explanatory, i.e. establishing a connection between an individual likes, aptitude and talent to his/her appropriate career.</p>
                <p >It is India's fastest growing career planning as well as educational products &amp; services Provider Company.</p>
                <p >It is the brainchild of a couple with an intelligent Vision Viz, Mr. Parthiv Desai and Mrs. Sonal Desai.</p>

              </div>
              <div className='col-lg-7' data-aos="fade-left">
                <div className='dots-bg'>
                  {/* <img draggable="false" className="Dots"
                    alt="➡" src={ImageAssets.Arrow.Dots} /> */}
                  <img width="1280" height="855" src={ImageAssets.Aboutus.aboutHome}
                    className='about-us-img' alt="" loading="lazy" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container product-service">

        <div className="row">
          <div className="col-lg-12 ">
            <div className="contents section-header mt-4">
              <h2 className="section-title wow fadeIn animated" data-aos="fade-down-right" >PRODUCTS &amp; SERVICES </h2>
            </div>
            {/* <h3 className="text-center mt-3">PRODUCTS &amp; SERVICES </h3>
            <div className="line mb-5"><span className="fa fa-graduation-cap"></span></div> */}
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5" data-aos="flip-right">
            <div className="card h-100">
              <a className="card-img-top banner" to={UIRoutes.CFAPPLICATION}>
                <img className="logo horizontal-logo" src={ImageAssets.PRODUCTSSERVICES.PRODUCTSSERVICES7} alt="forecastr logo" />
              </a>
              <div className="card-body">
                <h5 className="card-title"><Link className="card-link text-secondary" to={UIRoutes.CFAPPLICATION}>All India Council for Robotics &amp; Automation
                  (AICRA) (Gujarat State Co-ordinator)</Link></h5>
                <p> <Link className="card-link text-secondary" to={UIRoutes.CFAPPLICATION}>Read More</Link></p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5" data-aos="flip-right">
            <div className="card h-100">
              <a className="card-img-top banner" to={UIRoutes.CFAPPLICATION}>
                <img className="logo horizontal-logo" src={ImageAssets.PRODUCTSSERVICES.PRODUCTSSERVICES8} alt="forecastr logo" />
              </a>
              <div className="card-body">
                <h5 className="card-title"> <Link className="card-link text-secondary" to={UIRoutes.CFAPPLICATION}>Career Founder (Academic Partner)
                </Link></h5>
                <p> <Link className="card-link text-secondary" to={UIRoutes.CFAPPLICATION}>Read More</Link></p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5" data-aos="flip-right">
            <div className="card h-100">
              <a className="card-img-top banner" to={UIRoutes.CFAPPLICATION}>
                <img className="logo horizontal-logo" src={ImageAssets.PRODUCTSSERVICES.PRODUCTSSERVICES12} alt="forecastr logo" />
              </a>
              <div className="card-body">
                <h5 className="card-title">
                  <p>
                    <Link className="card-link text-secondary" to={UIRoutes.CFAPPLICATION}>Dheya Career Mentors (I) Pvt.
                      Ltd (Business Partner Gujarat)
                    </Link>
                  </p>
                </h5>
                <p> <Link className="card-link text-secondary" to={UIRoutes.CFAPPLICATION}>Read More</Link></p>
              </div>
            </div>
          </div>
           <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5">
            <div className="card h-100">
              <a className="card-img-top banner" to={UIRoutes.COUNCELINGDMIT}>
                <img className="logo horizontal-logo" src={ImageAssets.PRODUCTSSERVICES.PRODUCTSSERVICES10} alt="forecastr logo" />
              </a>
              <div className="card-body">
                <h5 className="card-title"><a href="/Counceling_Dmit/" target="_blank">Career Counceling with Dmit Test.
                </a></h5>
                <p> <Link className="card-link text-secondary" to={UIRoutes.COUNCELINGDMIT}>Read More</Link></p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5" data-aos="flip-right">
            <div className="card h-100">
              <a className="card-img-top banner" to={UIRoutes.COUNCELINGPSYCOMETRIC}>
                <img className="logo horizontal-logo" src={ImageAssets.PRODUCTSSERVICES.PRODUCTSSERVICES9} alt="forecastr logo" />
              </a>
              <div className="card-body">
                <h5 className="card-title"><a href="/Counceling_Psycometric/" target="_blank">Career Counceling with Psycometric Test.
                </a></h5>
                <p> <Link className="card-link text-secondary" to={UIRoutes.COUNCELINGPSYCOMETRIC}>Read More</Link></p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5" data-aos="flip-right">
            <div className="card h-100">
              <a className="card-img-top banner" to={UIRoutes.WINWHITELABEL}>
                <img className="logo horizontal-logo" src={ImageAssets.PRODUCTSSERVICES.PRODUCTSSERVICES11} alt="forecastr logo" />
              </a>
              <div className="card-body">
                <h5 className="card-title"><a href="/WIN-WIN WHITELABEL/" target="_blank">Win-Win White Label App Solution For School.
                </a></h5>
                <p> <Link className="card-link text-secondary" to={UIRoutes.WINWHITELABEL}>Read More</Link></p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5" data-aos="flip-right">
            <div className="card h-100">
              <Link className="card-img-top banner" to={UIRoutes.CAREERFOUNDER}>
                <img className="logo horizontal-logo" src={ImageAssets.PRODUCTSSERVICES.PRODUCTSSERVICES2} alt="forecastr logo" />
              </Link>
              <div className="card-body">
                <h5 className="card-title"> <Link className="card-link text-secondary" to={UIRoutes.CAREERFOUNDER}>Career Founder (Academic Partner)
                </Link></h5>
                <p> <Link className="card-link text-secondary" to={UIRoutes.CAREERFOUNDER}>Read More</Link></p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5" data-aos="flip-right">
            <div className="card h-100">
              <a className="card-img-top banner" to={UIRoutes.HOME}>
                <img className="logo horizontal-logo" src={ImageAssets.PRODUCTSSERVICES.PRODUCTSSERVICES6} alt="forecastr logo" />
              </a>
              <div className="card-body">
                <h5 className="card-title"><Link className="card-link text-secondary" to={UIRoutes.GETSETGO}>Get Set Go
                </Link></h5>
                <p> <Link className="card-link text-secondary" to={UIRoutes.GETSETGO}>Read More</Link></p>
              </div>
            </div>
          </div>
         
          <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5" data-aos="flip-right">
            <div className="card h-100">
              <a className="card-img-top banner" to={UIRoutes.SMARTSCHOOL}>
                <img className="logo horizontal-logo" src={ImageAssets.PRODUCTSSERVICES.PRODUCTSSERVICES5} alt="forecastr logo" />
              </a>
              <div className="card-body">
                <h5 className="card-title">
                  <p>
                    <Link className="card-link text-secondary" to={UIRoutes.SMARTSCHOOL}>Smart School (Distributor - Gujarat)</Link>
                  </p>
                </h5>
                <p> <Link className="card-link text-secondary" to={UIRoutes.SMARTSCHOOL}>Read More</Link></p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5" data-aos="flip-right">
            <div className="card h-100">
              <Link className="card-img-top banner" to={UIRoutes.DHEYACAREERMENTORS}>
                <img className="logo horizontal-logo" src={ImageAssets.PRODUCTSSERVICES.PRODUCTSSERVICES3} alt="forecastr logo" />
              </Link>
              <div className="card-body">
                <h5 className="card-title">
                  <p>
                    <Link className="card-link text-secondary" to={UIRoutes.DHEYACAREERMENTORS}>Dheya Career Mentors (I) Pvt.
                      Ltd (Business Partner Gujarat)
                    </Link>
                  </p>
                </h5>
                <p> <Link className="card-link text-secondary" to={UIRoutes.DHEYACAREERMENTORS}>Read More</Link></p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5" data-aos="flip-right">
            <div className="card h-100">
              <a className="card-img-top banner" to={UIRoutes.HOME}>
                <img className="logo horizontal-logo" src={ImageAssets.PRODUCTSSERVICES.PRODUCTSSERVICES1} alt="forecastr logo" />
              </a>
              <div className="card-body">
                <h5 className="card-title"><Link className="card-link text-secondary" to={UIRoutes.AICRI}>All India Council for Robotics &amp; Automation
                  (AICRA) (Gujarat State Co-ordinator)</Link></h5>
                <p> <Link className="card-link text-secondary" to={UIRoutes.AICRI}>Read More</Link></p>
              </div>
            </div>
          </div>
          {/* <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5">
            <div className="card h-100">
              <a className="card-img-top banner" to={UIRoutes.HOME}>
                <img className="logo horizontal-logo" src={ImageAssets.PRODUCTSSERVICES.PRODUCTSSERVICES4} alt="forecastr logo" />
              </a>
              <div className="card-body">
                <h5 className="card-title"><a href="/protecon-product/" target="_blank">Protecon BTG Pvt. Ltd (Channel
                  Partner- Gujarat)<p></p>
                </a></h5>
              </div>
            </div>
          </div> */}
          
          {/* <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5">
            <div className="card h-100">
              <a className="card-img-top banner" to={UIRoutes.HOME}>
                <img className="logo horizontal-logo" src={ImageAssets.PRODUCTSSERVICES.PRODUCTSSERVICES6} alt="forecastr logo" />
              </a>
              <div className="card-body">
                <h5 className="card-title"><a href="/tim-ts/" target="_blank">Times Institute Of Management and Technical
                  Studies (Strategic Partner Gujarat)</a></h5>
              </div>
            </div>
          </div> */}
          
          {/* <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5">
            <div className="card h-100">
              <a className="card-img-top banner" to={UIRoutes.HOME}>
                <img className="logo horizontal-logo" src={ImageAssets.PRODUCTSSERVICES.PRODUCTSSERVICES5} alt="forecastr logo" />
              </a>
              <div className="card-body">
                <h5 className="card-title"><a href="/smart-school/" target="_blank">Smart School (Distributor - Gujarat)
                </a></h5>
              </div>
            </div>
          </div> */}

          

        </div>
      </div>




      {/* <div id="app-features" className="section">
          <div className="container">
            <div className="section-header">
              <p className="btn btn-subtitle wow fadeInDown animated" data-wow-delay="0.2s" >features</p>
              <h2 className="section-title wow fadeIn animated" data-wow-delay="0.2s" >Amazing Features</h2>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-12 col-xs-12">
                <div className="content-left text-right">
                  <div className="box-item left">
                    <span className="icon">
                      <i className="lni-leaf"></i>
                    </span>
                    <div className="text">
                      <h4>User Friendly</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing typesting industry text.</p>
                    </div>
                  </div>
                  <div className="box-item left">
                    <span className="icon">
                      <i className="lni-dashboard"></i>
                    </span>
                    <div className="text">
                      <h4>Super Fast Speed</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing typesting industry text.</p>
                    </div>
                  </div>
                  <div className="box-item left">
                    <span className="icon">
                      <i className="lni-headphone-alt"></i>
                    </span>
                    <div className="text">
                      <h4>24/7 Support</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing typesting industry text.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-xs-12">
                <div className="show-box">
                  <img src={ImageAssets.whiteapp.app4} alt=""/>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-xs-12">
                <div className="content-right text-left">
                  <div className="box-item right">
                    <span className="icon">
                      <i className="lni-shield"></i>
                    </span>
                    <div className="text">
                      <h4>Secure</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing typesting industry text.</p>
                    </div>
                  </div>
                  <div className="box-item right">
                    <span className="icon">
                      <i className="lni-star-filled"></i>
                    </span>
                    <div className="text">
                      <h4>Awesome Rating</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing typesting industry text.</p>
                    </div>
                  </div>
                  <div className="box-item right">
                    <span className="icon">
                      <i className="lni-cup"></i>
                    </span>
                    <div className="text">
                      <h4>Award Winning</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing typesting industry text.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      <div className="container product-service">
        <div>
          <div className="row">
            <div className="col-lg-12">
              <div className="contents section-header">
                <h2 className="section-title wow fadeIn animated" data-aos="fade-down-right" >NEWS &amp; EVENTS </h2>
              </div>
              {/* <h3 className="text-center mt-3">NEWS &amp; EVENTS</h3> */}
              {/* <div className="line mb-5"><span className="fa fa-graduation-cap"></span></div> */}
            </div>
          </div>
          <div className="card-deck row">
            <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5" data-aos="flip-left">
              <div className="card h-100">
                <a className="card-img-top banner" to={UIRoutes.HOME}>
                  <img className="logo horizontal-logo" src={ImageAssets.NEWSEVENTS.NEWSEVENTS1} alt="forecastr logo" />
                </a>
                <div className="card-body">
                  <h5 className="card-title"> <Link className="card-link text-secondary" to={UIRoutes.BLOG1}>Power
                    in you - Students &amp; Parents Seminar by Mrs. Nisha Butani</Link></h5>
                  <p className="card-text"><small className="text-muted"> <Link className="card-link text-secondary" to={UIRoutes.BLOG1}>Read More</Link></small></p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5" data-aos="flip-left">
              <div className="card h-100">
                <a className="card-img-top banner" to={UIRoutes.HOME}>
                  <img className="logo horizontal-logo" src={ImageAssets.NEWSEVENTS.NEWSEVENTS2} alt="forecastr logo" />
                </a>
                <div className="card-body">
                  <h5 className="card-title"> <Link className="card-link text-secondary" to={UIRoutes.BLOG1}>Power in You
                    in Valsad &amp; vapi by International Motivational Speaker Ms. Nisha Butani</Link></h5>
                  <p className="card-text"><small className="text-muted"> <Link className="card-link text-secondary" to={UIRoutes.BLOG1}>Read More</Link></small></p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5" data-aos="flip-left">
              <div className="card h-100">
                <a className="card-img-top banner" to={UIRoutes.HOME}>
                  <img className="logo horizontal-logo" src={ImageAssets.NEWSEVENTS.NEWSEVENTS3} alt="forecastr logo" />
                </a>
                <div className="card-body">
                  <h5 className="card-title"><Link className="card-link text-secondary" to={UIRoutes.BLOG4}>CLASSROOM TO
                    CAREER</Link></h5>
                  <p className="card-text"><small className="text-muted"> <Link className="card-link text-secondary" to={UIRoutes.BLOG4}>Read More</Link></small></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* app */}

      <div className="container ">
        <div className="row space-100">
          <div className="col-lg-7 col-md-12 col-xs-12 app-home">
            <div className="contents section-header" data-aos="zoom-in-left">
              {/* <h2 className="section-title wow fadeIn animated" data-wow-delay="0.2s" >Awesome Mobile App </h2> */}
              {/* <h2 className="head-title">Get Your App Landing Page <br/> With Proton Template</h2> */}
              {/* <p className='text-center'> We have Community of Around Best School Syllabus mapped Learning App for KG -12 TH class Students. Career Founder App have Community of around 5,00,000+ Students & 200+ Schools.</p> */}
              <p className='main-app-headig'>Let's Make Learning Fun With <br />Audio-Video Learning App</p>
              <p className='red-text'>CBSE/ICSE/STATE BOARD Official Syllabus Based <span><span className="lightorange-text">CAREER</span> <span className="blue-text">FOUNDER</span></span> Mobile Learning Application</p>
              <p className='blue-stander-text'>For KG-12<sup>th</sup>                 <a className="btn CBSE_approved" target={'_blank'} href="" title="Google Play"><img className="CBSE aApproved" src={ImageAssets.Model.CBSE_approved} alt=" model img" /></a>
</p>
              <p className='black-dw-app-label'><span><span className="lightorange-text">CAREER</span> <span className="blue-text">FOUNDER</span></span> Application<br /> <span className='Free-text'>FREE</span> Download <small><i className="far fa-arrow-alt-circle-down"></i> </small> </p>

              <div className="header-button">
                {/* <a className="btn btn-google" target={'_blank'} href */}
                <a className="btn" target={'_blank'} href="https://play.google.com/store/apps/details?id=com.careerfounder" title="Click Here"><img className="main-appbtn" src={ImageAssets.Model.PlayButton} alt=" model img" /></a>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-12 col-xs-12">
            <div className="intro-img" data-aos="zoom-in-right">
              <img src={ImageAssets.whiteapp.app4} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className='franchise-section'>
        <div className='container p-4 text-center'>
          <div className="row">
            <div className="col-lg-12">
              <div className="clearfix widget-action  d-flex mx-auto justify-content-center text-white" data-aos="zoom-out">
                <div className="img">
                  <img draggable="false" className="emoji"
                    alt="➡" src={ImageAssets.DownloadLeaflet.msg} />

                </div>
                <div className="info">
                  <h3 className="title">
                    <span className='text-white'>Do you have any questions regarding franchise? Fill this form!</span>
                  </h3>
                </div>
              </div>
              <div className="action" data-aos="zoom-out">
                <Link className="btn franchise-btn btn-light" to={UIRoutes.FRANCHISEFROM}> <span>Franchise Form</span> </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonial">
        <div className='container p-5 text-center'>
          <Slider {...settings}>
            <div className="testimonial-meta">
              <div className="info">
                <h3 className="name-client">Nainesh Shah</h3>
                <div className="description"><p>In my search for affordable, reputable, and personalised, career counselings and advice, I discovered that CAREER FOUNDER offered many unique features that were unavailable in the other options I explored that CAREER FOUNDER provided me with convenient face-to-face service, a customised career plan for my daughter that explored her strengths/weaknesses, her likes/dislikes, and her skills/abilities, not only as an employable professional, but also as a human being. They gave me a foundation to build on, and a lucid and realistic career path for my daughter to follow, all in just a few insightful visits.</p>
                  <p>I am quite satisfied with my experience, and with the outcome of leadership of Mr.Prathiv Desai the director of CARRER FOUNDER.</p>
                </div>
              </div>
            </div>
            <div className="testimonial-meta">
              <div className="info">
                <h3 className="name-client">Devang Desai</h3>
                <div className="description"><p>A unique concept which aims at providing all sorts of career orientated guidelines &amp; courses under one roof with an extremely committed, well educated, disciplined &amp; talented staff to cater to the requirements, problem solution, proper career guidance &amp; finally an aim to achieve a 100% success ratio.<br />
                  Besides this the institute is also equipped with all major modern amenities to provide hassle free education with skilful techniques &amp; identify the true calibre of the students thereby providing appropriate &amp; proper guidance to bring out the best in each &amp; every student.<br />
                  As Career Founder is all set for their new upcoming venture, it gives me immense pleasure to congratulate Parthiv bhai &amp; my dear sister Sonal &amp; to wish them &amp; everyone in the staff all the very best. May God bless you all &amp; may you be very successful in your life.<br />
                  NOTHING SUCCEEDS LIKE SUCCESS.</p>
                </div>
              </div>
            </div>
            <div className="testimonial-meta">
              <div className="info">
                <h3 className="name-client">Keyur Dalal</h3>
                <div className="description"><p>'This is, by far, the best 'self-help' course I have ever undertaken.  The way in which we identified our issues, and addressed them was excellent because we were expertly guided to find our own solutions.  This course is brilliant value:  I have spent a lot of money on life coaches and counselling over the years, but this course delivered more benefits to me than anything else I have ever done in self-care for my wellbeing.'  </p>
                </div>
              </div>
            </div>
          </Slider>
        </div>

      </div>
      <section className="download-from text-center">
        <div className="jumbotron-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 d-flex flex-column mx-auto justify-content-center" data-aos="zoom-out">
                <h3>Do you want to download Leaflet?</h3>
                <div className="action">
                  <a className="btn download-btn btn-light mb-4" href="../images/page/home/CareerFounder-Final-A4.pdf" target="_blank"> <span>Download Leaflet</span> </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </>
}

export default Home

