import React from 'react'
import '../css/ContactUs/ContactUs.css'
import '../css/pages/DheyaCareerMentors.css'
import '../css/common/Banner.css'
import Breadcrums from '../common/Banner'
import { UIRoutes } from '../../utils/FrontendRoutes'
import ImageAssets from '../../utils/ImageAssets'

const DheyaCareerMentors = () => {
    return <>
        <section id="main" className="DheyaCareerMentors clearfix" role="main">
            <Breadcrums divClassName="DheyaCareerMentors-banner main-banner"
                breadcrums={JSON.stringify([{ key: "Home ", value: UIRoutes.HOME }, { key: "Dheya Career Mentors", value: UIRoutes.DHEYACAREERMENTORS }])}
            />
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="contents section-header pb-3 mt-3 mb-1 my-sm-4">
                            <h2 className="section-title  wow fadeIn animated" data-wow-delay="0.2s">Dheya Career Mentors&nbsp;(I) Pvt. Ltd</h2>
                            <h3 className="text-center mb-3">(Business Partner Gujarat)</h3>
                        </div>
                        {/* <h3 className="text-center mb-4 mt-4">Dheya Career Mentors&nbsp;(I) Pvt. Ltd</h3> */}

                        <p className="text-center mb-5">Dheya, a Sanskrit word: meaning “The Goal”, is a Career
                            development organization focusing on the Career success of
                            students and professionals. Unique IP in the areas of Career
                            Assessment, Guidance, Planning and Development. Dheya has
                            designed a standardized process for Career Planning and Career
                            Guidance to select suitable occupations and careers. We have
                            served around 1 Lack+ students so far. We are also providing
                            different services to our corporate clients like Volkswagen, Infosys,
                            Welspun, Cognizant, Gitanjali, Café Coffee Day, etc. Also we have
                            done some projects with different State governments &amp; the World
                            Bank.
                        </p>

                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="text-center mb-4">Products Comparison</h3>
                        <ul className="d-flex justify-content-around">
                            <li>DHEYA DISCOVER</li>
                            <li>DHEYA COMPASS</li>
                            <li>DHEYA NAVIGATOR</li>
                        </ul>
                    </div>
                    <div className="col-md-4 mb-5">
                        <div className="card h-100">
                            <h5 className="card-header">DHEYA DISCOVER</h5>
                            <div className="card-body">
                                <p className="card-text">QUICK CAREER CHOICE</p>
                                <ol>
                                    <li>Career Assessments<br />
                                        Personality: 09 Factors<br />
                                        Interest: 16 Factors</li>
                                    <li>20 Pages Assessment Test Report</li>
                                    <li> 1 Personalized Session with Parent – 60 Mins</li>
                                    <li> One to One – Documented</li>
                                    <li>&nbsp;1 Follow-up Session </li>
                                    <li> &nbsp;4 Career Options</li>
                                    <li> Education Streams as per career option</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-5">
                        <div className="card h-100">
                            <h5 className="card-header">DHEYA COMPASS</h5>
                            <div className="card-body">
                                <p className="card-text">BASIC CAREER PLAN</p>
                                <ol>
                                    <li> Career Assessments<br />
                                        Personality: 09 Factors<br />
                                        Ability: 07 Factors<br />
                                        Interest: 16 Factors</li>
                                    <li> 20 Pages Assessment Test Report</li>
                                    <li> 1 Personalized Session with Parent – 150 Mins</li>
                                    <li> One to One – Documented</li>
                                    <li> 2 Follow-Up Session </li>
                                    <li> &nbsp;4 Career Options</li>
                                    <li> Career Research workbook</li>
                                    <li> Finalization of decision- Career Direction</li>
                                    <li> Dheya Alumni Membership</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-5">
                        <div className="card h-100">
                            <h5 className="card-header">DHEYA NAVIGATOR</h5>
                            <div className="card-body">
                                <p className="card-text">COMPREHENSIVE CAREER PLAN</p>
                                <ol>
                                    <li> Career Assessments<br />
                                        Personality: 09 Factors<br />
                                        Ability : 07 Factors<br />
                                        Interest: 16 Factors</li>
                                    <li> 20 Pages Assessment Test Report</li>
                                    <li>3<em>. &nbsp;</em>3 Personalized Session with Parent – 7
                                        Hours</li>
                                    <li> One to One – Documented</li>
                                    <li> &nbsp;&nbsp;Lifelong Support</li>
                                    <li> &nbsp;4 Career Options</li>
                                    <li> Career Research workbook</li>
                                    <li> Asset analysis by parents + student</li>
                                    <li> Creative Visualization</li>
                                    <li> Career Planning &amp; Development</li>
                                    <li> Goal Setting: 10 Year Career Plan</li>
                                    <li> 36 Months Development Plan</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <p className="text-center mx-auto">
                        <img className="Products-Comparison-img aligncenter card-img-top" src={ImageAssets.Dhaycaree.Dhaycaree} alt="Bologna" width="708" height="548" />
                    </p>
                    <div className="row mx-auto mt-5 mb-5">
                        <div className="col-lg-6">
                            <div className="card h-100">
                                <div className=" card-block p-4">
                                    <h3>CUSTOMER SEGMENT</h3>
                                    <ul>
                                        <li>Psychometric Assessment &amp; Assessments Center</li>
                                        <li>Career Guidance &amp; Planning</li>
                                        <li>Career Development Programing</li>
                                        <li>Employability Development</li>
                                        <li>Placement Readiness Programs </li>
                                        <li>Competency Assessment</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="card h-100">
                                <div className=" card-block p-4">
                                    <h3>SUPPORTED</h3>
                                    <ul>
                                        <li>Franchise Helpline</li>
                                        <li>Sales Training</li>
                                        <li>System Training</li>
                                        <li>Knowledge Tools and Training</li>
                                        <li>Business Growth Strategies</li>
                                        <li>Internet Marketing Support</li>
                                        <li>Incentives &amp; Rewards</li>
                                        <li>Bulk Sales Support</li>
                                        <li>Central Marketing Support</li>
                                        <li>Upgrades and Updates</li>
                                        <li>CRM for building Happy Customers</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <h3 className="text-center">DHEYA&nbsp;INITIATIVES</h3>
                        </div>
                        <div className="row">
                            <div className="col-md-4 mb-5">
                                <div className="h-100">
                                    <div className=" card-block p-sm-3 p-1 text-center">
                                        <img width="217" height="78"
                                            src={ImageAssets.Aboutus.dheyacareer1}
                                            className="vc_single_image-img attachment-full" alt="" loading="lazy" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-5">
                                <div className="h-100">
                                    <div className=" card-block p-sm-3 p-1 text-center">
                                        <img width="217" height="70"
                                            src={ImageAssets.Aboutus.dheyacareer2}
                                            className="vc_single_image-img attachment-full" alt="" loading="lazy" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-5">
                                <div className="h-100">
                                    <div className=" card-block p-sm-3 p-1 text-center">
                                        <img width="217" height="64"
                                            src={ImageAssets.Aboutus.dheyacareer3}
                                            className="vc_single_image-img attachment-full" alt="" loading="lazy" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-5">
                                <div className="h-100">
                                    <div className=" card-block p-sm-3 p-1 text-center">
                                        <img width="217" height="86"
                                            src={ImageAssets.Aboutus.dheyacareer4}
                                            className="vc_single_image-img attachment-full" alt="" loading="lazy" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-5">
                                <div className="h-100">
                                    <div className=" card-block p-sm-3 p-1 text-center">
                                        <img width="217" height="118"
                                            src={ImageAssets.Aboutus.dheyacareer5}
                                            className="vc_single_image-img attachment-full" alt="" loading="lazy" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-5">
                                <div className="h-100">
                                    <div className=" card-block p-sm-3 p-1 text-center">
                                        <img width="217" height="89"
                                            src={ImageAssets.Aboutus.dheyacareer6}
                                            className="vc_single_image-img attachment-full" alt="" loading="lazy" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default DheyaCareerMentors