import React from 'react'
import '../css/ContactUs/ContactUs.css'
import '../css/common/Banner.css'
import Breadcrums from '../common/Banner'
import { UIRoutes } from '../../utils/FrontendRoutes'
import ImageAssets from '../../utils/ImageAssets'


const CareerFounder = () => {
    return <>
        <section id="main" className="CareerFounder clearfix" role="main">
            <Breadcrums divClassName="CareerFounder-banner main-banner"
                breadcrums={JSON.stringify([{ key: "Home ", value: UIRoutes.HOME }, { key: "Career Founder", value: UIRoutes.CAREERFOUNDER }])}
            />
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="contents section-header pb-3 mt-3 mb-1 my-sm-4">
                            <h2 className="section-title  wow fadeIn animated" data-wow-delay="0.2s">CAREER FOUNDER </h2>
                        </div>
                        {/* <h3 className='text-center mt-3 mb-4'>CAREER FOUNDER</h3> */}
                    </div>
                    <div className="col-lg-6">
                        <ul>
                            <li>“Career Founder&nbsp;“ is a new venture of “Career Connect&nbsp;” for the
                                students to support their regular Academic requirements for all major subjects
                                ( Mathematics , Science ,English, Social Science , Physics , Chemistry ,
                                Biology) for std. 8 to 12 of all the boards like CBSE,ICSE/STATE BOARD, Engineering (Diploma / Degree).</li>
                            <li>In Today’s scenario, during our relationship with educational &amp; industrial
                                management people, we come to know the certain area of concern where students
                                &amp; parents are facing&nbsp;challenges&nbsp;to cope up with
                                academic&nbsp;requirements&nbsp;to&nbsp;achieve&nbsp;the goal of successful
                                career.</li>
                            <li>&nbsp;First time in Gujarat We have come up with the unique concept
                                of&nbsp;‘Career Oriented Conceptual Studies’&nbsp;by integrating various tools
                                like&nbsp;Smart Tutorials, Career Guidance, &amp;&nbsp;importantly&nbsp;STP
                                Formula, etc.</li>
                        </ul>
                    </div>
                    <div className="col-lg-6">
                        <img className="cfbanner horizontal-logo" src={ImageAssets.banner.cfbanner} alt="forecastr logo" />
                    </div>
                    <div className="col-lg-12">
                        <h3 className="text-center mt-3 mb-5"><u>ACADEMIC</u></h3>
                        <p className="text-center mt-3 mb-4"><u>CBSE / ICSE / ISC / GSEB (English &amp;
                            Gujarati) – BOARD</u></p>
                    </div>
                </div>
                <div className="row">
                <div className="col-md-3 mb-5">
                        <div className="card h-100">
                            <div className=" card-block p-3">
                                <p><u>STD : 5 </u></p>
                                <ul>
                                    <li>Mathematics</li>
                                    <li>Science</li>
                                    <li>English</li>
                                    <li>Social Science</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-5">
                        <div className="card h-100">
                            <div className=" card-block p-3">
                                <p> <u>STD : 6</u></p>
                                <ul>
                                    <li>Mathematics</li>
                                    <li>Science</li>
                                    <li>English</li>
                                    <li>Social Science</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-5">
                        <div className="card h-100">
                            <div className=" card-block p-3">
                                <p> <u>STD : 7</u></p>
                                <ul>
                                    <li>Mathematics</li>
                                    <li>Science</li>
                                    <li>English</li>
                                    <li>Social Science</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-5">
                        <div className="card h-100">
                            <div className=" card-block p-3">
                                <p><u>STD : 8 </u></p>
                                <ul>
                                    <li>Mathematics</li>
                                    <li>Science</li>
                                    <li>English</li>
                                    <li>Social Science</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-5">
                        <div className="card h-100">
                            <div className=" card-block p-3">
                                <p> <u>STD : 9</u></p>
                                <ul>
                                    <li>Mathematics</li>
                                    <li>Science</li>
                                    <li>English</li>
                                    <li>Social Science</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-5">
                        <div className="card h-100">
                            <div className=" card-block p-3">
                                <p> <u>STD : 10</u></p>
                                <ul>
                                    <li>Mathematics</li>
                                    <li>Science</li>
                                    <li>English</li>
                                    <li>Social Science</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-5">
                        <div className="card h-100">
                            <div className=" card-block p-3">
                                <p><u>STD : 11 &amp; 12</u></p>
                                <ul>
                                    <li>Physics</li>
                                    <li>Chemistry</li>
                                    <li>Mathematics</li>
                                    <li>Biology</li>
                                    <li>English</li>
                                    <li>IIT – JEE (Main &amp; Advanced)</li>
                                    <li>NEET (Main &amp; Advanced)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 mb-5">
                        <h3 className="text-center"><u>DEGREE / DIPLOMA ENGINEERING</u></h3>
                    </div>
                </div>
                <div className="row mx-auto justify-content-center">
                    <div className="col-md-4 mb-5">
                        <div className="card h-100">
                            <div className=" card-block p-3">
                                <p>DIPLOMA ENGINEERING</p>
                                <ul>
                                    <li>Fundamentals of Mechanical</li>
                                    <li>Engineering Chemistry</li>
                                    <li>Physical Arithmetic Chemistry</li>
                                    <li>Mathematics</li>
                                    <li>English, etc.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-5">
                        <div className="card h-100">
                            <div className=" card-block p-3">
                                <p>DEGREE ENGINEERING</p>
                                <ul>
                                    <li>Mathematics</li>
                                    <li>MOS</li>
                                    <li>Engineering Graphics</li>
                                    <li>CPU</li>
                                    <li>English, etc.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <ul>
                            <li>CAREER ORIENTED CONCEPTUAL STUDIES</li>
                            <li>CAREER GUIDANCE &amp; PLANNING PROGRAM</li>
                            <li>SKILL ENHANCEMENT WORKSHOP AND SEMINARS</li>
                            <li>AUDIO – VISUAL FACILITIES</li>
                            <li>SMART TUTORIALS</li>
                        </ul>

                    </div>
                    <div className="col-lg-6">
                        <ul>
                            <li>SPECIAL TRAINING FOR WEAK &amp; FAILURE STUDENTS</li>
                            <li>WEEKLY PERFORMANCE EVALUATION</li>
                            <li>SUPPORTED FOR PROJECTS / INDUSTRIAL TRAINING &amp; VISIT</li>
                        </ul>

                    </div>
                </div>
            </div>
        </section>
    </>
}

export default CareerFounder