import React from 'react'

const TextInput = ({ ...props }) => {
  return (
    <div className={props.divClassName} style={props.divStyle}>
      <input type={props.type}
        placeholder={props.placeholder}
        onChange={props.onChange}
        className={props.inputClassName}
        id={props.id}
        aria-describedby={props.describedBy}
        required={props.required}
        value={props.value} />
      {props.error ? <span className='error-font-color'>{props.error}</span> : null}
    </div>
  )
}

export default TextInput