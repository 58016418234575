import React, { useEffect, useState } from "react"
import { connect } from 'react-redux'
import { Modal, Container } from 'react-bootstrap'
import { getUsers, updateRole } from "../../actions/userActions"
import CustomButton from "../common/CustomButton"
import Table from 'react-bootstrap/Table';

const defaultObject = {
  firstName: '',
  lastName: '',
  role: '',
  email: '',
  mobileNumber: ''
}

const Users = ({ ...props }) => {
  const [editObject, setEditObject] = useState(defaultObject)
  const [show, setShow] = useState(false);
  const [newRole, setNewRole] = useState(null);

  useEffect(() => {
    props.getUsers()
  }, [])

  const handleEdit = (user) => {
    setShow(true)
    setEditObject(user)
  }

  const handleChange = (event) => {
    setNewRole(event.target.value)
  }

  const handleSubmit = () => {
    const requestBody = {
      id: editObject._id,
      role: newRole
    }
    props.updateRole(requestBody)
    handleClose()
  }

  const handleClose = () => {
    setEditObject(defaultObject)
    setShow(false)
    setNewRole(null)
  };

  return (
    <section id="main" className="Aboutus clearfix" role="main">
      <div className='shadow-lg p-3 mb-5 bg-body rounded'>
        <h3 className="text-dark main-title-border ">User Details </h3>

        <Table striped bordered hover responsive="md">
          <thead>
            <tr>
              <th >Sr. No.</th>
              <th >Name</th>
              <th >Role</th>
              <th >Action</th>
            </tr>
          </thead>
          <tbody>
            {
              props.list.map((eachUser, index) => {
                return (
                  <tr>
                    <td scope="row">{index + 1}</td>
                    <td><span type="text" className="form-control" id={"formGroupExampleInput" + index}>{eachUser.firstName + ' ' + eachUser.lastName}</span></td>
                    <td><span type="text" className="form-control">{eachUser.role.substr(0, 1) + eachUser.role.substr(1).toLowerCase()}</span></td>
                    <td><a><i className="fa fa-edit" aria-hidden="true" onClick={() => handleEdit(eachUser)}></i></a></td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </div>
      {/* ------------------- */}
      {/* <div className='card'>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Sr. No.</th>
              <th scope="col">Name</th>
              <th scope="col">Role</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {
              props.list.map((eachUser, index) => {
                return (
                  <tr>
                    <td scope="row">{index + 1}</td>
                    <td><span type="text" className="form-control" id={"formGroupExampleInput" + index}>{eachUser.firstName + ' ' + eachUser.lastName}</span></td>
                    <td><span type="text" className="form-control">{eachUser.role.substr(0, 1) + eachUser.role.substr(1).toLowerCase()}</span></td>
                    <td><a><i className="fa fa-edit" aria-hidden="true" onClick={() => handleEdit(eachUser)}></i></a></td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div> */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update User Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <p>User Details</p>
            <p>Name: {editObject.firstName + ' ' + editObject.lastName}</p>
            <p>Email: {editObject.email}</p>
            <p>Mobile Number: {editObject.mobileNumber}</p>
            <p>Current Role: {editObject.role.substr(0, 1) + editObject.role.substr(1).toLowerCase()}</p>
            <p>
              New Role
              <select placeholder="Select New Role" onChange={(e) => handleChange(e)}>
                <option value='USER'>User</option>
                <option value='DISTRIBUTOR'>Distributor</option>
              </select>
            </p>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            divClassName='pb-2 w-25'
            className='btn blue-button w-100 font-weight-bold mt-2'
            onClick={handleSubmit}
            text='Save'
            disabled={!newRole}
          />
          <CustomButton
            divClassName='pb-2 w-25'
            className='btn blue-button w-100 font-weight-bold mt-2'
            onClick={handleClose}
            text='Cancel'
          />
        </Modal.Footer>
      </Modal>
    </section>
  )
}

const mapStateToProps = (state) => ({
  list: state.users.list
})

export default connect(mapStateToProps, { getUsers, updateRole })(Users)