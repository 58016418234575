import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import copy from 'copy-to-clipboard'
import $ from 'jquery'
import { UIRoutes } from '../../utils/FrontendRoutes'
import { getCategory } from '../../actions/categoryActions'
import '../css/dashboard/dashboard.css'

const SideMenu = ({ ...props }) => {
  useEffect(() => {
    $(document).ready(function () {
      $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active');
      });
    });
  }, [])

  useEffect(() => {
    props.getCategory()
  }, [])

  const handleReferralURLClick = () => {
    // navigator.clipboard.writeText('http://www.careerconnectedu.com/' + UIRoutes.SIGNUP + '?referralCode=' + JSON.parse(localStorage.getItem('user'))._id)
    copy('http://www.careerconnectedu.com/' + UIRoutes.SIGNUP + '?referralCode=' + JSON.parse(localStorage.getItem('user'))._id)
    alert('Link copied to your clipboard')
  }

  return <>
    <div className="wrapper dashboard">
      <nav id="sidebar" className='shadow mb-5 bg-light'>
        <ul className="list-unstyled components">
          <li>
            <Link className="p-3" to={UIRoutes.DASHBOARD}>Dashboard</Link>
          </li>
          <li>
            <a href="#productsSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle p-3">Products</a>
            <ul className="collapse list-unstyled" id="productsSubmenu">
              {props.role === 'ADMIN'
                ?
                <Link className="p-3" to={UIRoutes.ADD_PRODUCT}>Add Product</Link>
                :
                null
              }
              <Link className='p-3' to={UIRoutes.PRODUCTS + 'all'}>All</Link>
              {props.categoryList.map((eachCategory) => {
                return (
                  <li>
                    <Link className='p-3' to={UIRoutes.PRODUCTS + eachCategory._id}>{eachCategory.name}</Link>
                  </li>
                )
              })}
            </ul>
          </li>
          {props.role === 'ADMIN'
            ?
            <>
              <li>
                <a href="#couponSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle p-3">Coupons</a>
                <ul className="collapse list-unstyled" id="couponSubmenu">
                  <li>
                    <Link className='p-3' to={UIRoutes.COUPON_LIST}>List</Link>
                  </li>
                  <li>
                    <Link className='p-3' to={UIRoutes.ADD_COUPON}>Add Coupon</Link>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#gallerySubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle p-3">Gallery</a>
                <ul className="collapse list-unstyled" id="gallerySubmenu">
                  <li>
                    <Link className='p-3' to={UIRoutes.GALLERY_LIST}>List</Link>
                  </li>
                  <li>
                    <Link className='p-3' to={UIRoutes.ADD_GALLERY}>Add Gallery</Link>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#awardsSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle p-3">Awards</a>
                <ul className="collapse list-unstyled" id="awardsSubmenu">
                  <li>
                    <Link className='p-3' to={UIRoutes.AWARD_LIST}>List</Link>
                  </li>
                  <li>
                    <Link className='p-3' to={UIRoutes.ADD_AWARD}>Add Award</Link>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#reportsSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle p-3">Reports</a>
                <ul className="collapse list-unstyled" id="reportsSubmenu">
                  <li>
                    <Link className='p-3' to={UIRoutes.USERS_REPORT}>Users</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={UIRoutes.CATEGORIES} className='p-3'>Categories</Link>
              </li>
              <li>
                <Link to={UIRoutes.USERS_LIST} className="p-3">Users</Link>
              </li>
            </>
            :
            null
          }
          <li>
            <Link to={UIRoutes.NETWORK + JSON.parse(localStorage.getItem('user'))._id} className='p-3'>My Network</Link>
          </li>
          <li>
            <Link className="p-3" to={UIRoutes.USERDETAILSTREE}>User Details</Link>
          </li>
          <li>
            <Link className="p-3" to={UIRoutes.COMMISSION}>Commission</Link>
          </li>
          <li>
            <span onClick={handleReferralURLClick} className="mt-5 mb-5 p-3" style={{ color: 'black', fontWeight: 'bold' }}>Copy Referral URL</span>
          </li>
        </ul>
      </nav>
      <div id="content">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <button type="button" id="sidebarCollapse" className="btn btn-info">
              <i className="fas fa-align-left"></i>
            </button>
          </div>
        </nav>
        {props.children}
      </div>
    </div>
  </>
}

const mapStateToProps = (state) => ({
  role: state.auth.role,
  categoryList: state.category.list
})

export default connect(mapStateToProps, { getCategory })(SideMenu)