import { GET_PRODUCTS, DELETE_PRODUCTS, GET_SINGLE_PRODUCT } from "../actions/types"

const initialState = {
  list: [],
  activeProduct: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTS:
      return { ...state, list: action.payload }
    case DELETE_PRODUCTS:
      const tempProducts = [...state.list]
      tempProducts[action.payload.index] = { ...tempProducts[action.payload.index], isActive: !tempProducts[action.payload.index].isActive, isDeleted: !tempProducts[action.payload.index].isDeleted }
      return { ...state, list: tempProducts }
    case GET_SINGLE_PRODUCT:
      return { ...state, activeProduct: action.payload }
    default:
      return state
  }
}