import React from 'react'
import '../css/ContactUs/ContactUs.css'
import '../css/common/Banner.css'
import Breadcrums from '../common/Banner'
import { UIRoutes } from '../../utils/FrontendRoutes'


const ProteconProduct = () => {
    return <>
        <section id="main" className="ProteconProduct clearfix" role="main">
            <Breadcrums divClassName="ProteconProduct-banner main-banner"
                breadcrums={JSON.stringify([{ key: "Home ", value: UIRoutes.HOME }, { key: "PROTECON PRODUCT", value: UIRoutes.PROTECONPRODUCT }])}
            />
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="text-center mb-4 mt-4">Protecon BTG&nbsp;(I) Pvt. Ltd</h3>
                        <h3 className="text-center mb-3">(Channel Partner- Gujarat)</h3>
                        <p className="text-center mb-5">Protecon BTG is a New Generation Expertise based ISO 9001:2008 Certified Company (Promoted & Managed by 21 – 40 years plus rich experienced Team of IITian’s providing consultancy services and Professional Training Programs in the field of Project Management, Cost Optimization, Technology Support, Operation and Maintenance in Energy &amp; Infrastructure sectors in India and Abroad. Project Management Consulting, End to End EPCM Solutions, Professional Training, BTG Training cum Placement Programs (For Engineering Pass-out Candidates).
                        </p>

                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="text-center mb-4">Project Management &amp; Controls</h3>
                        <p>TARGET ROLES &amp; ELIGIBILITY</p>
                    </div>
                    <div className="col-md-4">
                        <div className="card h-100">
                            <div className="card-block p-4">
                                <h4 className="card-title">Target Industry Roles</h4>
                                <h6 className="card-subtitle text-muted">Project Engineer Planning Engineer Scheduling Engineer Invoicing / Billing Engineer</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card h-100">
                            <div className="card-block p-4">
                                <h4 className="card-title">COURSE ELIGIBILITY</h4>
                                <h6 className="card-subtitle text-muted">BE / B Tech. (Civil / Mechanical / Chemical/ Electrical)</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card h-100">
                            <div className="card-block p-4">
                                <h4 className="card-title">PROGRAM DURATION</h4>
                                <h6 className="card-subtitle text-muted">
                                    <ul>
                                        <li>4 month Classroom Training</li>
                                        <li>2 month Practical Assignments and Exams</li>
                                        <li>6 month Internship with stipend @ Rs 15,000/- per month</li>
                                    </ul>
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-auto mt-5 mb-5">
                        <div className=" col-lg-3 mb-4">
                            <div className="card h-100">
                                <div className=" card-block p-4">
                                    <h3>Module-1: Project Management Basics &nbsp; &nbsp;  <em>20 Classes of 3 hrs each</em> </h3>
                                    <p>A01 – Introduction<br />
                                        A02 – Types of Organizations<br />
                                        A03 – Project Life Cycle<br />
                                        A04 – Project Management Processes</p>

                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-3 mb-4">
                            <div className="card h-100">
                                <div className=" card-block p-4">
                                    <h3>Module-2: Inter-disciplinary Relationship&nbsp;&nbsp;<em>12 Classes of 3 hrs each</em></h3>
                                    <p>B01 – Process &amp; Operations<br />
                                        B02 – Civil &amp; Structural<br />
                                        B03 – Plant Piping &amp; Equipment<br />
                                        B04 – Electrical &amp; Instrumentation<br />
                                        B05 – Construction<br />
                                        B06 – Safety &amp; Quality</p>

                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-3 mb-4">
                            <div className="card h-100">
                                <div className=" card-block p-4">
                                    <h3>Module-3: Specialized Discipline &nbsp; &nbsp;  <em>34 Classes of 3 hrs each</em> </h3>
                                    <p>C01 – Scope &amp; WBS<br />
                                        C02 – Planning, Scheduling &amp; Monitoring<br />
                                        C03 – Progress Measurement &amp; Earned Value<br />
                                        C04 – Budgeting &amp; Invoicing<br />
                                        C05 – Document &amp; Material Controls<br />
                                        C06 – Progress Reporting<br />
                                        C07 – Field Planning<br />
                                        C08 – Productivity Norms</p>

                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-3 mb-4">
                            <div className="card h-100">
                                <div className=" card-block p-4">
                                    <h3>Module-4: Software Tools &nbsp;&nbsp;<em> 18 Classes of 3 hrs each</em></h3>
                                    <p>D01 – Primavera 6<br />
                                        D02 – Microsoft Project<br />
                                        D03 – Microsoft Excel<br />
                                        D04 – Microsoft PowerPoint<br />
                                        D05 – Microsoft Word</p>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="text-center mb-4">Pipeline Engineering and city Gas Distribution
                        </h3>
                        <p>TARGET ROLES & ELIGIBILITY</p>
                    </div>
                    <div className="col-md-4">
                        <div className="card h-100">
                            <div className="card-block p-4">
                                <h4 className="card-title">Target Industry Roles</h4>
                                <h6 className="card-subtitle text-muted">Pipeline Design Engineer Pipeline Project Engineer Pipeline Construction Engineer Pipeline Maintenance Engineer</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card h-100">
                            <div className="card-block p-4">
                                <h4 className="card-title">COURSE ELIGIBILITY</h4>
                                <h6 className="card-subtitle text-muted">BE / B Tech. (Civil / Mechanical / Chemical/ Electrical)
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card h-100">
                            <div className="card-block p-4">
                                <h4 className="card-title">PROGRAM DURATION</h4>
                                <h6 className="card-subtitle text-muted">
                                    <ul>
                                        <li>4 month Classroom Training</li>
                                        <li>2 month Practical Assignments and Exams</li>
                                        <li>6 month Internship with stipend @ Rs 15,000/- per month</li>
                                    </ul>
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-auto mt-5 mb-5">
                        <div className=" col-lg-3 mb-4">
                            <div className="card h-100">
                                <div className=" card-block p-4">
                                    <h3>Module-1: Engineering  &nbsp; &nbsp;  <em>34 Classes of 3 hrs each</em> </h3>
                                    <p>A01 – Introduction to Cross Country Pipelines and City Gas Distribution system<br />
                                        A02 – Fundamentals of fluid flow<br />
                                        A03 – Designing of Pipeline and City Gas Network system<br />
                                        A04 – Pipeline coating and cathodic protection system<br />
                                        A05 – Pumping stations/compressor stations/Delivery stations/scraper stations/ Valve Stations/Metering Stations/City Gate<br />
                                        A06 – Selection of Pumps/Compressors/Valves<br />
                                        A07 – Surge and surge protection</p>

                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-3 mb-4">
                            <div className="card h-100">
                                <div className=" card-block p-4">
                                    <h3>Module-2: Construction/Operation/Optimization &nbsp;&nbsp;<em>18 Classes of 3 hrs each</em></h3>
                                    <p>B01 – Pipeline Construction<br />
                                        B02 – Pipeline Operation<br />
                                        B03 – Pipeline Optimization<br />
                                        B04 – Performance Evaluation and monitoring</p>

                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-3 mb-4">
                            <div className="card h-100">
                                <div className=" card-block p-4">
                                    <h3>Module-3: Maintenance/HSE/Project Life Cycle   &nbsp; &nbsp;  <em>12 Classes of 3 hrs each</em> </h3>
                                    <p>C01 – Pipeline Maintenance<br />
                                        C02 – Safety, Health and Environment<br />
                                        C03 – Project Life Cycle Cost Estimating<br />
                                        C04 – Project Planning &amp; Scheduling</p>

                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-3 mb-4">
                            <div className="card h-100">
                                <div className=" card-block p-4">
                                    <h3>Module-4: Software Tools &nbsp;&nbsp;<em> 20 Classes of 3 hrs each</em></h3>
                                    <p>D01 – MS Project<br />
                                        D02 – MS Excel<br />
                                        D03 – MS Power Point<br />
                                        D04 – MS Word<br />
                                        D05 – Pipeline Studio</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="text-center mb-4">Cost Engineering & Management

                        </h3>
                        <p>TARGET ROLES & ELIGIBILITY

                        </p>
                    </div>
                    <div className="col-md-4">
                        <div className="card h-100">
                            <div className="card-block p-4">
                                <h4 className="card-title">Target Industry Roles</h4>
                                <h6 className="card-subtitle text-muted">Pipeline Design Engineer Pipeline Project Engineer Pipeline Construction Engineer Pipeline Maintenance Engineer
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card h-100">
                            <div className="card-block p-4">
                                <h4 className="card-title">COURSE ELIGIBILITY</h4>
                                <h6 className="card-subtitle text-muted">BE / B Tech. (Civil / Mechanical / Chemical/ Electrical)

                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card h-100">
                            <div className="card-block p-4">
                                <h4 className="card-title">PROGRAM DURATION</h4>
                                <h6 className="card-subtitle text-muted">
                                    <ul>
                                        <li>4 month Classroom Training</li>
                                        <li>2 month Practical Assignments and Exams</li>
                                        <li>6 month Internship with stipend @ Rs 15,000/- per month</li>
                                    </ul>
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-auto mt-5 mb-5">
                        <div className=" col-lg-3 mb-4">
                            <div className="card h-100">
                                <div className=" card-block p-4">
                                    <h3>Module-1: Project Management Basics   &nbsp; &nbsp;  <em>20 Classes of 3 hrs each</em> </h3>
                                    <p>A01 – Introduction<br />
                                        A02 – Types of Organizations<br />
                                        A03 – Project Life Cycle<br />
                                        A04 – Project Management Processes</p>

                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-3 mb-4">
                            <div className="card h-100">
                                <div className=" card-block p-4">
                                    <h3>Module-2: Inter-disciplinary Relationship &nbsp;&nbsp;<em>12 Classes of 3 hrs each</em></h3>
                                    <p>B01 – Process &amp; Operations<br />
                                        B02 – Civil &amp; Structural<br />
                                        B03 – Plant Piping &amp; Equipment<br />
                                        B04 – Electrical &amp; Instrumentation<br />
                                        B05 – Construction<br />
                                        B06 – Safety &amp; Quality</p>

                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-3 mb-4">
                            <div className="card h-100">
                                <div className=" card-block p-4">
                                    <h3>Module-3: Specialized Discipline  &nbsp; &nbsp;  <em>40 Classes of 3 hrs each</em> </h3>
                                    <p>C01 – Cost engineering basics and terminology<br />
                                        C02 – Project life cycle and cost management Processes<br />
                                        C03 – Cost estimation techniques<br />
                                        C04 – Owner’s cost estimates<br />
                                        C05 – EPC tender estimates<br />
                                        C06 – Construction tender estimates<br />
                                        C07 – Taxes and duties in a project<br />
                                        C08 – Cost monitoring and Control<br />
                                        C09 – Cost Data &amp; Parametric Modeling</p>

                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-3 mb-4">
                            <div className="card h-100">
                                <div className=" card-block p-4">
                                    <h3>Module-4: Software Tools &nbsp;&nbsp;<em> 12 Classes of 3 hrs each</em></h3>
                                    <p>D01 – Microsoft Excel<br />
                                        D02 – Microsoft PowerPoint<br />
                                        D03 – Microsoft Word</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="text-center mb-4">Procurement & Contract Management</h3>
                        <p>TARGET ROLES & ELIGIBILITY </p>
                    </div>
                    <div className="col-md-4">
                        <div className="card h-100">
                            <div className="card-block p-4">
                                <h4 className="card-title">Target Industry Roles</h4>
                                <h6 className="card-subtitle text-muted">Procurement Engineer Contract Engineer Commercial Engineer Buyer Expediter</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card h-100">
                            <div className="card-block p-4">
                                <h4 className="card-title">COURSE ELIGIBILITY</h4>
                                <h6 className="card-subtitle text-muted">BE / B Tech. (Civil / Mechanical / Chemical/ Electrical)</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card h-100">
                            <div className="card-block p-4">
                                <h4 className="card-title">PROGRAM DURATION</h4>
                                <h6 className="card-subtitle text-muted">
                                    <ul>
                                        <li>4 month Classroom Training</li>
                                        <li>2 month Practical Assignments and Exams</li>
                                        <li>6 month Internship with stipend @ Rs 15,000/- per month</li>
                                    </ul>
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-auto mt-5 mb-5">
                        <div className=" col-lg-3 mb-4">
                            <div className="card h-100">
                                <div className=" card-block p-4">
                                    <h3>Module-1: Project Management Basics   &nbsp; &nbsp;  <em>20 Classes of 3 hrs each</em> </h3>
                                    <p>A01 – Introduction<br />
                                        A02 – Types of Organizations<br />
                                        A03 – Project Life Cycle<br />
                                        A04 – Project Management Processes</p>

                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-3 mb-4">
                            <div className="card h-100">
                                <div className=" card-block p-4">
                                    <h3>Module-2: Inter-disciplinary Relationship &nbsp;&nbsp;<em>12 Classes of 3 hrs each</em></h3>
                                    <p>B01 – Process &amp; Operations<br />
                                        B02 – Civil &amp; Structural<br />
                                        B03 – Plant Piping &amp; Equipment<br />
                                        B04 – Electrical &amp; Instrumentation<br />
                                        B05 – Construction<br />
                                        B06 – Safety &amp; Quality</p>

                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-3 mb-4">
                            <div className="card h-100">
                                <div className=" card-block p-4">
                                    <h3>Module-3: Specialized Discipline  &nbsp; &nbsp;  <em>40 Classes of 3 hrs each</em> </h3>
                                    <p>C01 – Cost engineering basics and terminology<br />
                                        C02 – Project life cycle and cost management Processes<br />
                                        C03 – Cost estimation techniques<br />
                                        C04 – Owner’s cost estimates<br />
                                        C05 – EPC tender estimates<br />
                                        C06 – Construction tender estimates<br />
                                        C07 – Taxes and duties in a project<br />
                                        C08 – Cost monitoring and Control<br />
                                        C09 – Cost Data &amp; Parametric Modeling</p>

                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-3 mb-4">
                            <div className="card h-100">
                                <div className=" card-block p-4">
                                    <h3>Module-4: Software Tools &nbsp;&nbsp;<em> 12 Classes of 3 hrs each</em></h3>
                                    <p>D01 – Microsoft Excel<br />
                                        D02 – Microsoft PowerPoint<br />
                                        D03 – Microsoft Word</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="text-center mb-4">Procurement & Contract Management</h3>
                        <p>TARGET ROLES & ELIGIBILITY </p>
                    </div>
                    <div className="col-md-4">
                        <div className="card h-100">
                            <div className="card-block p-4">
                                <h4 className="card-title">Target Industry Roles</h4>
                                <h6 className="card-subtitle text-muted">Procurement Engineer Contract Engineer Commercial Engineer Buyer Expediter</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card h-100">
                            <div className="card-block p-4">
                                <h4 className="card-title">COURSE ELIGIBILITY</h4>
                                <h6 className="card-subtitle text-muted">BE / B Tech. (Civil / Mechanical / Chemical/ Electrical)</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card h-100">
                            <div className="card-block p-4">
                                <h4 className="card-title">PROGRAM DURATION</h4>
                                <h6 className="card-subtitle text-muted">
                                    <ul>
                                        <li>4 month Classroom Training</li>
                                        <li>2 month Practical Assignments and Exams</li>
                                        <li>6 month Internship with stipend @ Rs 15,000/- per month</li>
                                    </ul>
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-auto mt-5 mb-5">
                        <div className=" col-lg-3 mb-4">
                            <div className="card h-100">
                                <div className=" card-block p-4">
                                    <h3>Module-1: Project Management Basics   &nbsp; &nbsp;  <em>20 Classes of 3 hrs each</em> </h3>
                                    <p>A01 – Introduction<br />
                                        A02 – Types of Organizations<br />
                                        A03 – Project Life Cycle<br />
                                        A04 – Project Management Processes</p>

                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-3 mb-4">
                            <div className="card h-100">
                                <div className=" card-block p-4">
                                    <h3>Module-2: Inter-disciplinary Relationship &nbsp;&nbsp;<em>12 Classes of 3 hrs each</em></h3>
                                    <p>B01 – Process &amp; Operations<br />
                                        B02 – Civil &amp; Structural<br />
                                        B03 – Plant Piping &amp; Equipment<br />
                                        B04 – Electrical &amp; Instrumentation<br />
                                        B05 – Construction<br />
                                        B06 – Safety &amp; Quality</p>

                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-3 mb-4">
                            <div className="card h-100">
                                <div className=" card-block p-4">
                                    <h3>Module-3: Specialized Discipline  &nbsp; &nbsp;  <em>40 Classes of 3 hrs each</em> </h3>
                                    <p>C01 – Procurement Basics &amp; Terminology<br />
                                        C02 – Project Life Cycle and Procurement Processes<br />
                                        C03 – Material Procurement<br />
                                        C04 – Services Procurement<br />
                                        C05 – Contract Terms &amp; Conditions<br />
                                        C06 – Incoterms, Taxes &amp; Duties<br />
                                        C07 – Change &amp; Claims Management<br />
                                        C08 – Negotiations Techniques<br />
                                        C09 – Reverse Auction</p>

                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-3 mb-4">
                            <div className="card h-100">
                                <div className=" card-block p-4">
                                    <h3>Module-4: Software Tools &nbsp;&nbsp;<em> 12 Classes of 3 hrs each</em></h3>
                                    <p>D01 – Microsoft Excel<br />
                                        D02 – Microsoft PowerPoint<br />
                                        D03 – Microsoft Word</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="text-center mb-4">TECHNICIAN CUM OPERATOR PROGRAM FOR DIPLOMA ENGINEERS (MECHANICAL / ELECTRICAL)</h3>
                        <p>TARGET ROLES & ELIGIBILITY </p>
                    </div>
                    <div className="col-md-4">
                        <div className="card h-100">
                            <div className="card-block p-4">
                                <h4 className="card-title">Target Industry Roles</h4>
                                <h6 className="card-subtitle text-muted">Process Plant Operator Maintenance Technicians Pipeline Technicians</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card h-100">
                            <div className="card-block p-4">
                                <h4 className="card-title">COURSE ELIGIBILITY</h4>
                                <h6 className="card-subtitle text-muted">Diploma (Mechanical / Electrical)</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card h-100">
                            <div className="card-block p-4">
                                <h4 className="card-title">PROGRAM DURATION</h4>
                                <h6 className="card-subtitle text-muted">
                                    <ul>
                                        <li>4 month Classroom Training</li>
                                        <li>2 month Practical Assignments and Exams</li>
                                        <li>6 month Internship with stipend @ Rs 10,000/- per month</li>
                                    </ul>
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-auto mt-5 mb-5">
                        <div className=" col-lg-6 mb-4">
                            <div className="card h-100">
                                <div className=" card-block p-4">
                                    <h3>MODULE 1: FUNDAMENTALS     &nbsp; &nbsp;  <em>18 Classes</em> </h3>
                                    <p className='mb-2'>BASIC PRINCIPLES OF OPERATION</p>
                                    <ol>Operation Fundamentals
                                        <li>Process Safety</li>
                                        <li>Process Dynamics and Measurement</li>
                                        <li>Basic Operator responsibility</li>
                                    </ol>
                                    <p className='mb-2'>BASIC PRINCIPLES OF MAINTENANCE</p>
                                    <ol>
                                        <li> Maintenance Concepts</li>
                                        <li> Types of Maintenance</li>
                                        <li>Plant maintenance</li>
                                        <li>Equipment Maintenance</li>
                                        <li>Pipeline Maintenance</li>
                                        <li>Maintenance Performance</li>
                                    </ol>
                                    <p className='mb-2'>UNIT OF MEASUREMENT & CONVERSION <br />DRAWINGS & DIAGRAMS</p>
                                    <ol>
                                        <li> Basic Diagrams And Symbols</li>
                                        <li> Flow And Electrical Diagrams</li>
                                    </ol>
                                    <p className='mb-2'>HEALTH, SAFETY & ENVIRONMENT</p>
                                    <ol>
                                        <li> Chemical Safety</li>
                                        <li>Electrical Safety</li>
                                        <li>Fire Safety</li>
                                        <li> Hazardous Waste Operation</li>
                                        <li> Personal Protective Equipment</li>
                                        <li> Material Handling & Storage</li>
                                        <li> Workplace Safety</li>
                                    </ol>

                                    <p className='mb-2'>TOOLS</p>
                                    <ol>
                                        <li> Hand Tools</li>
                                        <li> Measuring Instruments</li>
                                        <li> Power Tools</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-6 mb-4">
                            <div className="card h-100">
                                <div className=" card-block p-4">
                                    <h3>MODULE 2: FUNCTIONAL (MECHANICAL)  &nbsp;&nbsp;<em>20 Classes</em></h3>
                                    <p className='mb-2'>BASIC AND HEAVY LIFTING <br />
                                        LUBRICATION & BEARINGS </p>
                                    <ol>
                                        <li> Type of Bearings</li>
                                        <li> Type of Lubrication</li>
                                        <li> Performance monitoring</li>
                                    </ol>
                                    <p className='mb-2'>GEARS, EQUIPMENT DRIVE COMPONENTS & SHAFT ALIGNMENT
                                        PUMPS </p>
                                    <ol>
                                        <li>  Types of Pumps</li>
                                        <li> Pump operation</li>
                                        <li> Performance monitoring</li>
                                        <li> Seals – Gasket & Packing</li>
                                        <li> Seals – Mechanical</li>
                                        <li> Inspection, Maintenance, Trouble shooting</li>
                                    </ol>
                                    <p className='mb-2'>HEAT EXCHANGERS, VALVES, FILTERS & STRAINERS</p>
                                    <ol>
                                        <li> Heat Exchanger – Maintenance / Trouble Shooting</li>
                                        <li> Valves – Maintenance / Trouble Shooting</li>
                                        <li> Gaskets, Bolting & Torqueing</li>
                                        <li> Steam Traps</li>
                                        <li> Strainers</li>
                                    </ol>

                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-6 mb-4">
                            <div className="card h-100">
                                <div className=" card-block p-4">
                                    <h3>MODULE 3: FUNCTIONAL (ELECTRICAL) &nbsp; &nbsp;  <em>16 Classes</em> </h3>
                                    <p className='mb-2'>FUNDAMENTALS </p>
                                    <ol>
                                        <li> Electrical Safety</li>
                                        <li> Electrical Theory</li>
                                        <li> Electrical Circuits</li>
                                        <li> Electrical Storage (Battery) system</li>
                                        <li> Electrical Wiring</li>
                                        <li> Measurement Devices</li>
                                    </ol>
                                    <p className='mb-2'>ELECTRICAL OPERATION & MAINTENANCE</p>
                                    <ol>
                                        <li> Actuator, Valves & Motor Controllers</li>
                                        <li> Electrical Components (SCRs & TRIALs)</li>
                                        <li>HV Switchgears</li>
                                        <li>Motors & VFDs</li>
                                        <li> Transformers</li>
                                        <li> Breakers</li>
                                        <li>Relays</li>
                                        <li> Ground Fault</li>
                                        <li>Interrupters</li>
                                        <li>Working Principle & Operation</li>
                                        <li> Maintenance</li>
                                    </ol>
                                    <p className='mb-2'>VALVES, ACTUATORS & MOTOR CONTROLLERS</p>
                                    <ol>
                                        <li> Basic Types & Operation of Valves</li>
                                        <li> Introduction to Actuators</li>
                                        <li>Electric & Hydraulic Actuators</li>
                                        <li>Motor Controllers</li>
                                        <li> Smart Controllers</li>
                                    </ol>
                                    <p className='mb-2'>CATHODIC PROTECTION</p>
                                    <ol>
                                        <li> Fundamentals of Cathodic Protection</li>
                                        <li> Monitoring & Performance Measurement</li>
                                        <li>Maintenance & Trouble Shooting</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-6 mb-4">
                            <div className="card h-100">
                                <div className=" card-block p-4">
                                    <h3>MODULE 4: FUNCTIONAL (INSTRUMENTATION)  &nbsp;&nbsp;<em> 16 Classes</em></h3>
                                    <p className='mb-2'>MEASUREMENT DEVICES</p>
                                    <ol>
                                        <li> Field Devices: Using Field Communicator</li>
                                        <li> Field Devices: Analyzers</li>
                                        <li>Field Devices: Level and Flow</li>
                                        <li>Field Devices: Pressure, Temp. and weight</li>
                                        <li> Measurement of Concentration</li>
                                        <li> Measurement of Density Clarity & Moisture</li>
                                        <li>Measurement of level and flow</li>
                                        <li> Measurement of Pressure and Temp.</li>
                                        <li>Measurement of Vibration</li>
                                    </ol>
                                    <p className='mb-2'>CALIBRATION </p>
                                    <ol>
                                        <li> Principles of Calibration</li>
                                        <li> Field Devices: Analog Configuration</li>
                                        <li>Field Devices: Configuring with a laptop PC</li>
                                        <li>Field Devices : Digital Configuration with a DCS</li>
                                    </ol>
                                    <p className='mb-2'>MHI & PLANT PROTECTION</p>
                                    <ol>
                                        <li> Human-Machine Interface & Trouble Shooting</li>
                                        <li> Plant Safety Devices</li>
                                    </ol>
                                    <p className='mb-2'>PROGRAMMABLE LOGIC CONTROLLERS</p>
                                    <ol>
                                        <li> Installing and Maintaining PLCs</li>
                                        <li> Architecture, Types and Networks</li>
                                        <li>I/O Communication</li>
                                        <li>Troubleshooting Hardware</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default ProteconProduct