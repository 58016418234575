import React, { useEffect } from 'react'
import '../css/ContactUs/ContactUs.css'
import '../css/common/Banner.css'
import Breadcrums from '../common/Banner'
// import { Card, Button } from 'react-bootstrap'
import { UIRoutes } from '../../utils/FrontendRoutes'
import '../css/pages/Aboutus.css'
import { ImageAssets } from '../../utils/ImageAssets'
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from 'react-router-dom';
import CustomButton from '../common/CustomButton'



const ApplicationLicense = ({ ...props }) => {
    useEffect(() => {
        AOS.init({
            duration: 1200
        });
        AOS.refresh();
    }, []);
    const redirectToAllProducts = () => {
        props.history(UIRoutes.PRODUCT_LIST)
      }
    return <>
        <section id="main" className="Smart-School winwhitelable clearfix" role="main">
            <Breadcrums divClassName="licancebanner main-banner"
                breadcrums={JSON.stringify([{ key: "Home ", value: UIRoutes.HOME }, { key: "Career founder mobile learning application license", value: UIRoutes.WINWHITELABEL }])}
            />
            <div className="container">
            <div className="row">
                    <div className="col-lg-12">
                        <div className="contents section-header pb-3 mt-3 mb-1 my-sm-4" data-aos="zoom-in">
                            <h2 className="section-title  wow fadeIn animated" data-wow-delay="0.2s">Career founder mobile learning application license</h2>
                        </div>
                        {/* <h3 className="text-center mb-4 mt-4">Win-Win Whitelabel App Solution</h3> */}
                    </div>
                </div>

                {/* <div className="row">
                    <div className="col-lg-6" data-aos="zoom-out-left">
                        <img width="100%" height="155px" src={ImageAssets.whiteapp.app4}
                            className='about-us-img' alt="" loading="lazy" />
                    </div>
                    <div className="col-lg-5" data-aos="zoom-out-right">
                        <h4 className="text-center mb-4 mt-4">App Benefits</h4>
                        <ul>
                            <li>Map With Official Syllabus Of All Boards CBSE, ICSE & STATE BOARD</li>
                            <li>Built-in Fully Solved NCERT Book</li>
                            <li>Test, Worksheets, Key Points, Notes</li>
                            <li>Parents Can Track the Usage and Study Performance of their Child</li>
                            <li>Area Of Improvement can be Identified</li>
                        </ul>
                    </div>
                </div> */}
                <div className="row mb-5">
                    <div className="col-lg-5" data-aos="zoom-out-left">
                        <h4 className="text-center mb-4 mt-4">Advantages of CAREER FOUNDER App</h4>
                        <ul>
                            <li>CBSE Board Approved Content</li>
                            <li>All Major Subjects – for Kg-12th</li>
                            <li>English & Hindi Bilingual</li>
                            <li>Can Use In Online & Offline Both Mode</li>
                            <li>Real Life Videos & Many More</li>
                            <li>MOST AFFORDABLE COST</li>
                        </ul>
                    </div>
                    <div className="col-lg-2 cbse-img" data-aos="zoom-out-right">
                        <img width="1280" height="855" src={ImageAssets.Model.CBSE_approved}
                            className='about-us-img' alt="" loading="lazy" />
                        {/* <div className="intro-img" data-aos="zoom-in-right">
                            <img src={ImageAssets.whiteapp.app4} alt="" />
                        </div> */}
                    </div>
                    <div className="col-lg-5" data-aos="zoom-out-right">
                        <h4 className="text-center mb-4 mt-4">App Benefits</h4>
                        <ul>
                            <li>Map With Official Syllabus Of All Boards CBSE, ICSE & STATE BOARD</li>
                            <li>Built-in Fully Solved NCERT Book</li>
                            <li>Test, Worksheets, Key Points, Notes</li>
                            <li>Parents Can Track the Usage and Study Performance of their Child</li>
                            <li>Area Of Improvement can be Identified</li>
                        </ul>
                    </div>

                </div>

                <div className="row">

                    {/* <div className="col-12 col-sm-8 col-md-6 col-lg-7 mb-2 mb-sm-5" data-aos="flip-right">
                        <div className="card mb-3">
                            <div className="row g-0">
                                <div className="col-md-4">
                                    <img className="horizontal-logo" src={ImageAssets.PRODUCTSSERVICES.PRODUCTSSERVICES7} alt="forecastr logo" />
                                </div>
                                <div className="col-md-8">
                                    <div className="card-body">
                                        <h4 className="card-title">Career founder mobile learning application kg-12th for 1 year license
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-3">
                            <div className="row g-0">
                                <div className="col-md-8">
                                    <div className="card-body">
                                        <h4 className="card-title">Career founder mobile learning application kg-12th for 1 year license
                                        </h4>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <img className="horizontal-logo" src={ImageAssets.PRODUCTSSERVICES.PRODUCTSSERVICES8} alt="forecastr logo" />
                                </div>

                            </div>
                        </div>
                        <div className="card mb-3">
                        <div className="card h-100">
                                <a className="card-img-top" to={UIRoutes.CFAPPLICATION}>
                                    <img className=" horizontal-logo" src={ImageAssets.PRODUCTSSERVICES.PRODUCTSSERVICES7} alt="forecastr logo" />
                                </a>
                                <div className="card-body">
                                    <h4 className="card-title"><a href="/smart-school/" target="_blank">Career founder mobile learning application KG-12<sup>th</sup> for 1 year license
                                        <p>
                                        </p>
                                    </a></h4>


                                </div>
                            </div>
                        </div>
                       
                    </div> */}

                    {/* -------------- */}
                    {/* <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5" data-aos="flip-right">
                        <div className="card h-100">
                            <a className="card-img-top banner" to={UIRoutes.CFAPPLICATION}>
                                <img className="logo horizontal-logo" src={ImageAssets.PRODUCTSSERVICES.PRODUCTSSERVICES7} alt="forecastr logo" />
                            </a>
                            <div className="card-body">
                                <h4 className="card-title text-center"><a href="/smart-school/" target="_blank"> KG-12<sup>th</sup> for 1 year license<p>
                                </p>
                                </a></h4>


                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5" data-aos="flip-right">
                        <div className="card h-100">
                            <a className="card-img-top banner" to={UIRoutes.CFAPPLICATION}>
                                <img className="logo horizontal-logo" src={ImageAssets.PRODUCTSSERVICES.PRODUCTSSERVICES8} alt="forecastr logo" />
                            </a>
                            <div className="card-body">
                                <h4 className="card-title text-center"><a href="/smart-school/" target="_blank"> KG-12<sup>th</sup> for 2 year license<p>
                                </p>
                                </a></h4>


                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5" data-aos="flip-right">
                        <div className="card h-100">
                            <a className="card-img-top banner" to={UIRoutes.CFAPPLICATION}>
                                <img className="logo horizontal-logo" src={ImageAssets.PRODUCTSSERVICES.PRODUCTSSERVICES12} alt="forecastr logo" />
                            </a>
                            <div className="card-body">
                                <h4 className="card-title text-center"><a href="/smart-school/" target="_blank"> KG-12<sup>th</sup> for 3 year license<p>
                                </p>
                                </a></h4>


                            </div>
                        </div>
                    </div> */}
<div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5" data-aos="flip-right">
            <div className="card h-100">
              <a className="card-img-top banner" to={UIRoutes.CFAPPLICATION}>
                <img className="logo horizontal-logo" src={ImageAssets.PRODUCTSSERVICES.PRODUCTSSERVICES7} alt="forecastr logo" />
              </a>
              <div className="card-body">
                <h5 className="card-title">
                  <a href="/Career_founder_mobile_learning_application/" target="_blank">Career founder mobile learning application KG-12<sup>th</sup> for 1 year license</a>
                </h5>
                <div className='buynow-home-product'>
                  <p className='text-danger'><del><small>23000/- <i className="fas fa-rupee-sign"></i></small></del>&nbsp;&nbsp;<strong className='pl-5'>15000/- <i className="fas fa-rupee-sign"></i></strong></p>
                  <CustomButton
                    divClassName=''
                    className='btn blue-button w-100 font-weight-bold mt-2'
                    onClick={redirectToAllProducts}
                    text='Buy Now'
                  />
                </div>
                {/* <p> <Link className="card-link text-secondary" to={UIRoutes.CFAPPLICATION}>Read More</Link></p> */}

              </div>
            </div>
          </div>
          <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5" data-aos="flip-right">
            <div className="card h-100">
              <a className="card-img-top banner" to={UIRoutes.CFAPPLICATION}>
                <img className="logo horizontal-logo" src={ImageAssets.PRODUCTSSERVICES.PRODUCTSSERVICES8} alt="forecastr logo" />
              </a>
              <div className="card-body">
                <h5 className="card-title"><a href="/Career_founder_mobile_learning_application/" target="_blank">Career founder mobile learning application KG-12<sup>th</sup> for 2 year license
                </a></h5>
                <div className='buynow-home-product'>
                <p className='text-black font-weight-bold font-italic mt-4'>Coming Soon…</p>
                  {/* <CustomButton
                    divClassName=''
                    className='btn blue-button w-100 font-weight-bold mt-2'
                    onClick={redirectToAllProducts}
                    text='Buy Now'
                  /> */}
                </div>
                {/* <p> <Link className="card-link text-secondary" to={UIRoutes.CFAPPLICATION}>Read More</Link></p> */}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-8 col-md-6 col-lg-4 mb-2 mb-sm-5" data-aos="flip-right">
            <div className="card h-100">
              <a className="card-img-top banner" to={UIRoutes.CFAPPLICATION}>
                <img className="logo horizontal-logo" src={ImageAssets.PRODUCTSSERVICES.PRODUCTSSERVICES12} alt="forecastr logo" />
              </a>
              <div className="card-body">
                <h5 className="card-title"><a href="/Career_founder_mobile_learning_application/" target="_blank">Career Founder mobile learning application KG-12<sup>th</sup> for 3 year license</a></h5>
                <div className='buynow-home-product'>
                  <p className='text-black font-weight-bold font-italic mt-4'>Coming Soon…</p>
                  {/* <CustomButton
                    divClassName=''
                    className='btn blue-button w-100 font-weight-bold mt-2'
                    onClick={redirectToAllProducts}
                    text='Buy Now'
                  /> */}
                </div>
                {/* <p> <Link className="card-link text-secondary" to={UIRoutes.CFAPPLICATION}>Read More</Link></p> */}
              </div>
            </div>
          </div>
{/* --------- */}
                </div>


            </div>
        </section>
    </>
}

export default ApplicationLicense