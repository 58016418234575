import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getUserReports } from '../../actions/reportActions'

const UsersReport = ({ ...props }) => {

  useEffect(() => {
    props.getUserReports()
  }, [])

  return <>
    <div className="container">
      <div className="row justify-content-center text-center">
        <div className="col-lg-10 shadow-lg p-3 mb-5 bg-body rounded">
          <h3 className="text-dark main-title-border mb-2">Total User Count</h3>
          <span> {props.users.totalUserCount}</span>
        </div>

        <div className="col-lg-10 shadow-lg p-3 mb-5 bg-body rounded">
          <h3 className="text-dark main-title-border mb-2">Users Registred in Current Month</h3>
          <span> {props.users.currentMonthUserCount}</span>
        </div>
      </div>
    </div>
  </>
}

const mapStateToProps = (state) => ({
  users: state.reports.users
})

export default connect(mapStateToProps, { getUserReports })(UsersReport)