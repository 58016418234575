import axios from "axios"
import { toast } from "react-toastify"
import { APIRoutes } from "../utils/BackendRoutes"
import { getErrorMessage } from "../utils/GetErrorMessage"
import setLoading from "../utils/LoadingManager"
import { ADD_GALLERY, GET_GALLERY, DELETE_GALLERY } from "./types"

export const addGallery = (gallery) => (dispatch) => {
  setLoading(true)
  axios.post(APIRoutes.ADD_GALLERY, gallery)
    .then(res => {
      dispatch({
        type: ADD_GALLERY,
        payload: gallery
      })
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const getGallery = () => (dispatch) => {
  setLoading(true)
  axios.get(APIRoutes.GET_GALLERY)
    .then(res => {
      dispatch({
        type: GET_GALLERY,
        payload: res.data.data
      })
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const deleteGallery = (id, index) => (dispatch) => {
  setLoading(true)
  axios.delete(APIRoutes.DELETE_GALLERY + id)
    .then(res => {
      dispatch({
        type: DELETE_GALLERY,
        payload: { id, index }
      })
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}