import React from 'react'
import '../css/pages/BlogSingle.css'
import '../css/common/Banner.css'
import Breadcrums from '../common/Banner'
import { UIRoutes } from '../../utils/FrontendRoutes'
import ImageAssets from '../../utils/ImageAssets'
import { Link } from 'react-router-dom'


const BlogSidebar = () => {
  return <>
    <div className="col-lg-4">

      <div className="sidebar text-left">

        <h3 className="sidebar-title">Social Links</h3>
        <div className="social-link">
          <a href="https://www.facebook.com/pages/category/Education/Career-Connect-1228862200548321/" target="_blank" rel="noopener noreferrer" className="me-4 text-reset">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="https://twitter.com/CAREERCONNECT9" target="_blank" rel="noopener noreferrer" className="me-4 text-reset">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="http://www.careerconnectedu.com/" target="_blank" rel="noopener noreferrer" className="me-4 text-reset">
            <i className="fab fa-google"></i>
          </a>
          <a href="https://www.instagram.com/careerconnect29/" target="_blank" rel="noopener noreferrer" className="me-4 text-reset">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://www.linkedin.com/in/career-connect-496566179/" target="_blank" rel="noopener noreferrer" className="me-4 text-reset">
            <i className="fab fa-linkedin"></i>
          </a>
          <a href="https://www.youtube.com/channel/UCfvZOnlJh_vg2amKJay8-eg?guided_help_flow=3" target="_blank" rel="noopener noreferrer" className="me-4 text-reset">
            <i className="fab fa-youtube"></i>
          </a>
        </div>

        <h3 className="sidebar-title mt-3">Recent Post</h3>
        <p className='widget widget_recent_entries'>
          <ul>
            <li>
            <Link className="card-link text-secondary" to={UIRoutes.BLOG1} >Power in You in Valsad &amp; vapi by International Motivational Speaker Ms. Nisha Butani</Link>
            </li>
            <li>
            <Link className="card-link text-secondary" to={UIRoutes.BLOG4} >CLASSROOM TO CAREER</Link>
            </li>
            {/* <li>
              <a href="http://www.careerconnectedu.com/uncategorized/admission-for-2020-21-for-icse-isc-now-open/">ADMISSION FOR 2020-21 FOR ICSE/ISC NOW OPEN</a>
            </li> */}
            <li>
            <Link className="card-link text-secondary" to={UIRoutes.BLOG1} >Power in you – Students &amp; Parents Seminar</Link>
            </li>
          </ul></p>

        {/* <div className="sidebar-item search-form">
                <form action="">
                  <input type="text" />
                  <button type="submit"><i className="icofont-search"></i></button>
                </form>

              </div> End sidebar search formn*/}

        {/* <h3 className="sidebar-title">Categories</h3>
              <div className="sidebar-item categories">
                <ul>
                  <li><a href="#">General <span>(25)</span></a></li>
                  <li><a href="#">Lifestyle <span>(12)</span></a></li>
                  <li><a href="#">Travel <span>(5)</span></a></li>
                  <li><a href="#">Design <span>(22)</span></a></li>
                  <li><a href="#">Creative <span>(8)</span></a></li>
                  <li><a href="#">Educaion <span>(14)</span></a></li>
                </ul>

              </div> */}
        {/* End sidebar categories*/}

        <h3 className="sidebar-title">Recent Course</h3>
        <div className="sidebar-item recent-posts">
          <div className="post-item clearfix">
            <img className="card-img-top" src={ImageAssets.Dhaycaree.Dhaycaree} alt="Bologna" />
            <h4><a href="#">CAREER REASERCH GUIDANCE & PLANNING PROGRAM</a></h4>
            {/* <time datetime="2020-01-01">Jan 1, 2020</time> */}
          </div>

          <div className="post-item clearfix">
            <img className="card-img-top" src={ImageAssets.blog.blog3} alt="Bologna" />
            <h4><a href="#">CAREER FOUNDER [NOW AT TITHAL ROAD VALSAD</a></h4>
            {/* <time datetime="2020-01-01">Jan 1, 2020</time> */}
          </div>
        </div>{/* End sidebar recent posts*/}
      </div>{/* End sidebar */}

    </div>
  </>
}

export default BlogSidebar