import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import validator from 'validator'
import '../css/pages/Aboutus.css'
import '../css/common/Banner.css'
import TextInput from '../common/TextInput'
import CustomButton from '../common/CustomButton'
import { addCategory, deleteCategory, getCategory, updateCategory } from '../../actions/categoryActions'
import Table from 'react-bootstrap/Table';


const CategoryListing = ({ ...props }) => {
  const [categoryName, setCategoryName] = useState({ value: '', error: '' })
  const [editId, setEditId] = useState('')

  useEffect(() => {
    props.getCategory()
  }, [])

  const handleChange = (event, calledFor) => {
    let tempValue = { value: event.target.value, error: '' }
    if (!tempValue.value) {
      tempValue = { value: event.target.value, error: calledFor + ' is required' }
    }
    switch (calledFor) {
      case 'Name':
        if (!tempValue.error && validator.isEmpty(tempValue.value)) {
          tempValue.error = 'Category name is not valid'
        }
        setCategoryName(tempValue)
        break
    }
  }

  const handleSubmit = () => {
    const requestBody = {
      name: categoryName.value
    }
    if (editId) {
      props.updateCategory(requestBody, editId)
      setEditId('')
    } else {
      props.addCategory(requestBody)
    }
  }

  const handleEdit = (id, name) => {
    setCategoryName({ value: name, error: '' })
    setEditId(id)
  }

  const handleDelete = (id) => {
    props.deleteCategory(id)
  }

  return <>
    <section id="main" className="categorylisting clearfix" role="main">
      <div className='container productlisting-details shadow-lg p-3 mb-5 bg-body rounded'>
        <div className='row'>
        <h3 className="text-dark main-title-border">Categorys </h3>
          <div className='col-lg-12'>
            <TextInput
              divClassName="form-group pb-3 w-50"
              divStyle={{ float: 'left' }}
              type="text"
              placeholder="Category Name"
              onChange={(e) => handleChange(e, 'Name')}
              inputClassName="form-control"
              id="name"
              required="required"
              value={categoryName.value}
              error={categoryName.error}
            />
          </div>
          <div className='col-lg-12'>
            <CustomButton
              divClassName='pb-2 w-25'
              className='btn blue-button w-100 font-weight-bold mt-2'
              onClick={handleSubmit}
              text='Save'
              disabled={!categoryName.value || categoryName.error}
            />
          </div>
          <div className='col-lg-12'>
            <Table striped bordered hover responsive="md">
              <thead>
                <tr>
                  <th >Sr. No.</th>
                  <th >Name</th>
                  <th >Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  props.list.map((eachCategory, index) => {
                    return (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <span type="text" className="form-control" id={"formGroupExampleInput" + index}>{eachCategory.name}</span>
                        <td><a><i className="fa fa-edit" aria-hidden="true" onClick={() => handleEdit(eachCategory._id, eachCategory.name)}></i></a></td>
                        <td><a><i className="fa fa-trash" aria-hidden="true" onClick={() => handleDelete(eachCategory._id)}></i></a></td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
            {/* -------------------------------------------------- */}
            {/* <div className='border'>
              <table className="table table-striped responsive">
                <thead>
                  <tr>
                    <th scope="col">Sr. No.</th>
                    <th scope="col">Name</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    props.list.map((eachCategory, index) => {
                      return (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <span type="text" className="form-control" id={"formGroupExampleInput" + index}>{eachCategory.name}</span>
                          <td><a><i className="fa fa-edit" aria-hidden="true" onClick={() => handleEdit(eachCategory._id, eachCategory.name)}></i></a></td>
                          <td><a><i className="fa fa-trash" aria-hidden="true" onClick={() => handleDelete(eachCategory._id)}></i></a></td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  </>
}

const mapStateToProps = state => ({
  list: state.category.list
})

export default connect(mapStateToProps, { addCategory, getCategory, updateCategory, deleteCategory })(CategoryListing)